import React, {useEffect, useState} from "react";
import {Button} from "src/components/button";
import fetcher from "src/utils/apiCalls/fetcher";
import {ApiHost} from "src/utils/defaults/apiHost";
import {Company} from "src/utils/types/structures/company";
import useSWR from "swr";
import {getStripeDashboardLink} from "../../../../../utils/apiCalls/getStripeDashboardLink";
import {isInstanceOf} from "../../../../../utils/errorHandlers/isInstanceOf";

export default function PaymentsAndPayouts() {
    const [dashboardLink, setDashboardLink] = useState<string | null>(null)

    const {data: company} = useSWR<Company>(ApiHost + encodeURI(`api/v1/company`), fetcher);

    useEffect(() => {
        if (!dashboardLink ?? company?.status === 'ACTIVE') {
            getStripeDashboardLink().then(res => {
                if (isInstanceOf<{ link: string }>(res, 'link')) setDashboardLink(res.link)
            })
        }
    }, [dashboardLink, company]);

    return <div className={`w-full flex flex-col gap-0`}>

        <h1 className={`text-[#222222] text-[24px] leading-[32px] font-medium`}>Payments & Payouts</h1>

        <h2 className={'pt-4 text-[#222222] text-[20px] leading-[28px] font-medium'}>How you’ll get paid</h2>

        <p className={'pt-3 text-[#717171] text-[14px] leading-[20px] font-normal'}>
            Set up your Stripe account as a payout method so we can send your payments. You can track all transactions
            on the Stripe dashboard.
        </p>

        {dashboardLink && <Button size={'lg'}
                                  onClick={() => window.open(dashboardLink, "_blank")}
                                  className={'mt-6 w-fit text-center text-white h-[48px] px-5 py-[7px] text-[16px] leading-[20px] font-medium'}>Manage
            your payments</Button>}
    </div>
}