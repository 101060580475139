import {APIProvider} from "@vis.gl/react-google-maps";
import React from 'react';
import ReactDOM from 'react-dom/client';
import {Toaster} from "src/components/toaster";
import {API_KEY} from "src/utils/defaults/googleMapSettings";
import App from './App';
import './globals.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

      <APIProvider apiKey={API_KEY}>
    <App />
      <Toaster/>
      </APIProvider>

);
reportWebVitals();
