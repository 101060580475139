import {ReactElement} from "react";
import {MobileFleetIcon} from "src/media/icons/custom/mobileNavIcons/mobileFleetIcon";
import {MobileLoadsIcon} from "src/media/icons/custom/mobileNavIcons/mobileLoadsIcon";
import {MobileProfileIcon} from "src/media/icons/custom/mobileNavIcons/mobileProfileIcon";
import {MobileSearchIcon} from "src/media/icons/custom/mobileNavIcons/mobileSearchIcon";
import {SearchIcon} from "src/media/icons/custom/searchIcon";
import routes from "src/utils/defaults/routes";
import {Role} from "src/utils/enums/role";
import {MobileInboxIcon} from "../../media/icons/custom/mobileNavIcons/mobileInboxIcon";

export enum BarSide {
    left = 'LEFT',
    right = 'RIGHT'
}


export type MenuBar = {
    title: string
    route: string
    desktop: boolean
    mobile: boolean
    side: BarSide
    permission: Role[]


    isActive: boolean
    icon: ReactElement
}

const menuBar: MenuBar[] = [
    {title: 'Search', route: routes.search, desktop: true, mobile: true, side: BarSide.left, isActive: false, permission: [Role.owner, Role.manager], icon: <MobileSearchIcon size={24}/>},
    {title: 'Loads', route: '/loads/booked', desktop: true, mobile: true, side: BarSide.left, isActive: false, permission: [Role.owner, Role.manager, Role.driver], icon: <MobileLoadsIcon size={24} />},
    {title: 'Inbox', route: routes.inbox, desktop: true, mobile: true, side: BarSide.left, isActive: false, permission: [Role.owner, Role.manager, Role.driver], icon: <MobileInboxIcon size={24} />},
    {title: 'Team', route: routes.fleetManagement, desktop: true, mobile: true, side: BarSide.left, isActive: false, permission: [Role.owner, Role.manager], icon: <MobileFleetIcon size={24} />},
    {title: 'Company', route: routes.companyDetails, desktop: true, mobile: false, side: BarSide.right, isActive: false, permission: [Role.owner, Role.manager], icon: <SearchIcon size={24} />},
    {title: 'Menu', route: routes.mobileAccountSettings, desktop: false, mobile: true, side: BarSide.left, isActive: false, permission: [Role.owner, Role.manager, Role.driver], icon: <MobileProfileIcon size={24}/>},
]

export default menuBar