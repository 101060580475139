import {isValidPhoneNumber} from "react-phone-number-input";
import * as z from "zod";

const personalDetails = z.object({
    firstName: z.string().min(1, {message: 'Required'}),
    lastName: z.string().min(1, {message: 'Required'}),
    email: z.string().email(),
    currentPassword: z.string(),
    newPassword: z.string(),
    repeatPassword: z.string(),
    phoneNumber: z
        .string()
        .refine(isValidPhoneNumber, {message: "Invalid phone number"}),
})

export default personalDetails