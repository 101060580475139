import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
    size: number
    stroke?: string
}

const ArrowRight = ({...props}: Props) => {

    return <svg {...props} width={props.size} height={props.size} viewBox="0 0 17 16" fill="none">
        <path d="M3.83301 8H13.1663" stroke={props.stroke ? props.stroke : '#222222'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.5 3.33398L13.1667 8.00065L8.5 12.6673" stroke={props.stroke ? props.stroke : '#222222'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
}

export {ArrowRight}