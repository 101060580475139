import React, {useState} from "react";
import {Button} from "src/components/button";
import {toast} from "src/components/useToast";
import useAlert from "src/pages/dashboarSection/layout/popUps/alert/useAlert";
import {deleteMember} from "src/utils/apiCalls/deleteMember";
import getString, {ElementTag} from "src/utils/stringHandlers/getString";
import {Member} from "src/utils/types/structures/member";
import {SpinnerIcon} from "../../../../../../media/icons/custom/spinnerIcon";

interface Props {
    member: Member
}

export default function DeleteMember({member}: Props) {
    const {close, firstAction, secondAction} = useAlert()
    const [isLoading, setLoading] = useState<boolean>(false);
    async function onDeleteMember(member: Member) {
        setLoading(true)
        const res = await deleteMember(member.id);
        if (res !== 200) {
            toast({
                variant: "destructive",
                description: `${res.message}`,
            })
        }

        if (firstAction) {
            firstAction();
        }
        setLoading(false)
        close();
    }

    return <div className={'flex flex-col gap-4'}>
        <p>{`Are you sure you want to delete ${getString([
            {element: member.firstName, tag: ElementTag.name},
            {element: member.lastName, tag: ElementTag.name},
        ])} from fleet?`}</p>

        <div className={'flex justify-between'}>
            <Button disabled={isLoading} variant={'outline'} onClick={close}>Cancel</Button>
            <Button disabled={isLoading} variant={'destructive'} className={'relative'} onClick={() => onDeleteMember(member)}>
                <SpinnerIcon className={`${isLoading ? 'visible' : 'invisible'} fill-marcoWhite absolute`} size={20}/>
                <p className={`${isLoading ? 'invisible' : 'visible'} px-5 text-center text-white text-[16px] leading-[20px] font-medium`}>Continue</p>
            </Button>
        </div>
    </div>
}