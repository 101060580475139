import * as React from "react";
import {cn} from "../cn/cnHelper";

export interface InputProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    isempty: string;
    isinvalid: string;
    label: string;
}

const CustomInput = React.forwardRef<HTMLInputElement, InputProps>(
    ({type, className, ...props}, ref) => {
        return (
            <div className="flex flex-col gap-2">

                <span
                    className={`select-none text-[#222222] text-[14px] leading-[20px] font-medium`}>{props.label}</span>

                <input
                    className={cn(
                        `pl-4 ${
                            props.isinvalid === "true"
                                ? "outline outline-2 outline-[#C6241D] text-[#C6241D] placeholder:text-[#C6241D]"
                                : "outline outline-1 outline-[#dddddd] text-marco_default_foreground placeholder:text-[#717171]"
                        } text-[16px] leading-[24px] font-normal flex h-[48px] w-full rounded-md bg-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-[#222222] disabled:cursor-not-allowed disabled:opacity-50`,
                        className,
                    )}
                    placeholder={props.label}
                    ref={ref}
                    type={type}
                    {...props}
                />
            </div>
        );
    },
);
CustomInput.displayName = "Input";

export {CustomInput};
