import type {UseFormReturn} from "react-hook-form";
import type {ClearSuggestions, SetValue, Suggestions,} from "use-places-autocomplete";
import React, {useEffect, useState} from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import {AddressDirection} from "../addressDirection";
import {FormControl, FormField, FormItem, FormMessage} from "../../../components/form";
import {CustomInput} from "../CustomInput";

interface Props {
  clearSuggestions: ClearSuggestions;

  direction: AddressDirection;

  form: UseFormReturn<{
    access: string;
    floorNumber: string;
    googleMapAddress: string;
    manualApt?: string;
    manualCity: string;
    manualCountry: string;
    manualState: string;
    manualStreetAddress: string;
    manualZip: string;

    numberStories: string;
    propertySize: string;
    propertyType: string;
    squareFootage: string;
    unitNumber: string;
    unitSize: string;
  }>;

  isManualAddressFormActive: boolean;
  label: string;
  setManualAddressFormActive: React.Dispatch<React.SetStateAction<boolean>>;

  setValue: SetValue;
  suggestions: Suggestions;
  value: string;
}

export default function GoogleAutoComplete({
  clearSuggestions,
  direction,
  form,
  label,
  setManualAddressFormActive,
  setValue,
  suggestions,
  value,
}: Props) {
  const [isHidden, setHidden] = useState(true);
  const clearOrigin = useOnclickOutside(() => {
    clearSuggestions();
    setHidden(true);
  });

  const renderOriginSuggestions = () =>
    suggestions.data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          className="cursor-pointer text-marco_default_foreground text-[16px] leading-[24px] font-normal hover:bg-[#f7f7f7] rounded-[6px] py-3 px-3"
          key={place_id}
          onClick={async () => {
            clearSuggestions();
            setHidden(true);
            const addr = main_text + ", " + secondary_text;
            setValue(addr.replace(", USA", ""), false);
            form.setValue("googleMapAddress", addr.replace(", USA", ""));
          }}
          ref={clearOrigin}
        >
          {main_text}, {secondary_text?.replace(", USA", "")}
        </li>
      );
    });

  useEffect(() => {
    if (value === "") {
      setHidden(true);
    }
  }, [value]);

  return (
    <div className="relative">
      <FormField
        control={form.control}
        name="googleMapAddress"
        render={({ field }) => (
          <FormItem className="flex-1">
            <FormControl>
              <CustomInput
                {...field}
                autoComplete="off"
                isempty={
                  form.getValues("googleMapAddress") === "" ? "true" : "false"
                }
                isinvalid={
                  form.getFieldState("googleMapAddress").invalid
                    ? "true"
                    : "false"
                }
                label={label}
                onChange={(e) => {
                  setValue(e.target.value);
                  form.clearErrors("googleMapAddress");
                  form.setValue("googleMapAddress", "");
                }}
                onInput={() => {
                  if (value.length !== 0) {
                    setHidden(false);
                  } else setHidden(true);
                }}
                value={value}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      {!isHidden && (
        <ul className="text-sm mt-10 p-2 border rounded-[6px] w-full text-foreground box-shadow-50 absolute flex flex-col top-12 bg-white z-50">
          {suggestions.status === "OK" && renderOriginSuggestions()}

          <li
            className="cursor-pointer hover:bg-[#f7f7f7] rounded-[6px] text-marco_default_foreground text-[14px] leading-[20px] font-medium underline py-4 px-3"
            key="address-not-showing"
            onClick={() => {
              clearSuggestions();
              setHidden(true);
              setManualAddressFormActive(true);
            }}
            ref={clearOrigin}
          >
            Address is not showing
          </li>
        </ul>
      )}
    </div>
  );
}
