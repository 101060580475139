export const getFloorNumber = (floorNumber: string) => {
   switch (floorNumber) {
       case 'BASEMENT': {
           return 'Basement'
       }

       case 'GROUND_FLOOR': {
           return 'Ground Floor'
       }

       case 'FIRST_FLOOR': {
           return '1st Floor'
       }

       case 'SECOND_FLOOR': {
           return '2nd Floor'
       }

       case 'THIRD_FLOOR': {
           return '3rd Floor'
       }

       case 'FOURTH_FLOOR': {
           return '4th Floor'
       }

       case 'FIFTH_FLOOR': {
           return '5th Floor'
       }

       case 'SIXTH_FLOOR': {
           return '6th Floor'
       }

       case 'SEVENTH_FLOOR': {
           return '7th Floor'
       }

       case 'EIGHTH_FLOOR': {
           return '8th Floor'
       }

       case 'NINTH_FLOOR': {
           return '9th Floor'
       }

       case 'TENTH_FLOOR': {
           return '10th Floor'
       }

       case 'ELEVENTH_FLOOR': {
           return '11th Floor'
       }

       case 'TWELVE_FLOOR': {
           return '12th Floor'
       }

       case 'THIRTEENTH_FLOOR': {
           return '13th Floor'
       }

       case 'FOURTEENTH_FLOOR': {
           return '14th Floor'
       }

       case 'FIFTEENTH_FLOOR': {
           return '15th Floor'
       }

       case 'SIXTEENTH_FLOOR': {
           return '16th Floor'
       }

       case 'SEVENTEENTH_FLOOR': {
           return '17th Floor'
       }

       case 'EIGHTEENTH_FLOOR': {
           return '18th Floor'
       }

       case 'NINETEENTH_FLOOR': {
           return '19th Floor'
       }

       case 'TWENTIETH_FLOOR': {
           return '20th Floor'
       }

       case 'TWENTY_FIRST_FLOOR': {
           return '21st Floor'
       }

       case 'TWENTY_SECOND_FLOOR': {
           return '22nd Floor'
       }

       case 'TWENTY_THIRD_FLOOR': {
           return '23rd Floor'
       }

       case 'TWENTY_FOURTH_FLOOR': {
           return '24th Floor'
       }

       case 'TWENTY_FIFTH_FLOOR': {
           return '25th Floor'
       }

       case 'TWENTY_SIXTH_FLOOR': {
           return '26th Floor'
       }

       case 'TWENTY_SEVENTH_FLOOR': {
           return '27th Floor'
       }

       case 'TWENTY_EIGHTH_FLOOR': {
           return '28th Floor'
       }

       case 'TWENTY_NINTH_FLOOR': {
           return '29th Floor'
       }

       case 'TWENTY_THIRTY_FLOOR': {
           return '30th Floor'
       }

       case 'THIRTY_FIRST_FLOOR': {
           return '31st Floor'
       }

       case 'THIRTY_SECOND_FLOOR': {
           return '32nd Floor'
       }
       case 'THIRTY_THIRD_FLOOR': {
           return '33rd Floor'
       }

       case 'THIRTY_FOURTH_FLOOR': {
           return '34th Floor'
       }

       case 'THIRTY_FIFTH_FLOOR': {
           return '35th Floor'
       }

       case 'THIRTY_SIXTH_FLOOR': {
           return '36th Floor'
       }

       case 'THIRTY_SEVENTH_FLOOR': {
           return '37th Floor'
       }

       case 'THIRTY_EIGHTH_FLOOR': {
           return '38th Floor'
       }

       case 'THIRTY_NINTH_FLOOR': {
           return '39th Floor'
       }
       case 'FORTY_FLOOR': {
           return '40th Floor'
       }
       case 'FORTY_FIRST_FLOOR': {
           return '41st Floor'
       }

       case 'FORTY_SECOND_FLOOR': {
           return '42nd Floor'
       }

       case 'FORTY_THIRD_FLOOR': {
           return '43rd Floor'
       }

       case 'FORTY_FOURTH_FLOOR': {
           return '44th Floor'
       }

       case 'FORTY_FIFTH_FLOOR': {
           return '45th Floor'
       }

       case 'FORTY_SIXTH_FLOOR': {
           return '46th Floor'
       }

       case 'FORTY_SEVENTH_FLOOR': {
           return '47th Floor'
       }

       case 'FORTY_EIGHTH_FLOOR': {
           return '48th Floor'
       }

       case 'FORTY_NINTH_FLOOR': {
           return '49th Floor'
       }

       case 'FIFTY_FLOOR': {
           return '50th Floor'
       }

       case 'FIFTY_FIRST_FLOOR': {
           return '51st Floor'
       }

       case 'FIFTY_SECOND_FLOOR': {
           return '52nd Floor'
       }

       case 'FIFTY_THIRD_FLOOR': {
           return '53rd Floor'
       }

       case 'FIFTY_FOURTH_FLOOR': {
           return '54th Floor'
       }
       case 'FIFTY_FIFTH_FLOOR': {
           return '55th Floor'
       }
       case 'FIFTY_SIXTH_FLOOR': {
           return '56th Floor'
       }

       case 'FIFTY_SEVENTH_FLOOR': {
           return '57th Floor'
       }

       case 'FIFTY_EIGHTH_FLOOR': {
           return '58th Floor'
       }

       case 'FIFTY_NINTH_FLOOR': {
           return '59th Floor'
       }

       case 'SIXTY_FLOOR': {
           return '60th Floor'
       }

       default: {
           return floorNumber
       }
   }

}