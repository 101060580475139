import {ApiHost} from "src/utils/defaults/apiHost";
import getDefinedError from "src/utils/errorHandlers/getDefinedError";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";
import {Company} from "../../types/structures/company";

export type CompanyData = {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    country: string;
    addressPhone: string;
    addressFirstName: string;
    addressLastName: string;
    zipcode: string;
}

export async function updateCompanyData(companyData: CompanyData) {
    const res = await fetch(ApiHost + encodeURI(`api/v1/company`), {
        method: 'PUT',
        body: JSON.stringify(companyData),
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${useTokenStore.getState().token}`
        }
    })

    if (res.ok) {
        const data: Company = await res.json();
        return {data: data, error: null}

    } else {
        const err = await getDefinedError(res)
        return {data: null, error: err}
    }
}

export async function getCompany() {
    const res = await fetch(ApiHost + encodeURI(`api/v1/company`), {
        method: 'GET',
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${useTokenStore.getState().token}`
        }
    })

    if (res.ok) {
        const data: Company = await res.json();
        return {data: data, error: null}

    } else {
        const err = await getDefinedError(res)
        return {data: null, error: err}
    }
}