import * as z from "zod";

const googleAutocompleteSchema = z
    .object({
        access: z.string(),

        floorNumber: z.string(),
        googleMapAddress: z.string(),
        manualApt: z.string().optional(),
        manualCity: z.string(),
        manualCountry: z.string(),
        manualState: z.string(),
        manualStreetAddress: z.string(),
        manualZip: z.string(),

        numberStories: z.string(),
        propertySize: z.string(),
        propertyType: z.string(),
        squareFootage: z.string(),
        unitNumber: z.string(),
        unitSize: z.string(),
    })
    .superRefine(
        async (
            {
                access,
                floorNumber,
                googleMapAddress,
                numberStories,
                propertySize,
                propertyType,
                squareFootage,
                unitSize,
            },
            ctx,
        ) => {
            if (googleMapAddress === "") {
                ctx.addIssue({
                    code: "custom",
                    message: "Select options from dropdown menu",
                    path: ["googleMapAddress"],
                });
            }

            if (propertyType === "") {
                ctx.addIssue({
                    code: "custom",
                    message: "Required",
                    path: ["propertyType"],
                });
            }

            if (access === "") {
                ctx.addIssue({
                    code: "custom",
                    message: "Required",
                    path: ["access"],
                });
            }

            switch (propertyType) {
                case "APARTMENT": {
                    if (propertySize === "") {
                        ctx.addIssue({
                            code: "custom",
                            message: "Required",
                            path: ["propertySize"],
                        });
                    }

                    if (floorNumber === "") {
                        ctx.addIssue({
                            code: "custom",
                            message: "Required",
                            path: ["floorNumber"],
                        });
                    }
                    break;
                }

                case "HOUSE": {
                    if (propertySize === "") {
                        ctx.addIssue({
                            code: "custom",
                            message: "Required",
                            path: ["propertySize"],
                        });
                    }

                    if (numberStories === "") {
                        ctx.addIssue({
                            code: "custom",
                            message: "Required",
                            path: ["numberStories"],
                        });
                    }
                    break;
                }

                case "STORAGE": {
                    if (unitSize === "") {
                        ctx.addIssue({
                            code: "custom",
                            message: "Required",
                            path: ["unitSize"],
                        });
                    }

                    if (floorNumber === "") {
                        ctx.addIssue({
                            code: "custom",
                            message: "Required",
                            path: ["floorNumber"],
                        });
                    }
                    break;
                }

                case "COMMERCIAL": {
                    if (squareFootage === "") {
                        ctx.addIssue({
                            code: "custom",
                            message: "Required",
                            path: ["squareFootage"],
                        });
                    }

                    if (floorNumber === "") {
                        ctx.addIssue({
                            code: "custom",
                            message: "Required",
                            path: ["floorNumber"],
                        });
                    }
                    break;
                }

                default:
                    if (propertySize === "") {
                        ctx.addIssue({
                            code: "custom",
                            message: "Required",
                            path: ["propertySize"],
                        });
                    }

                    if (floorNumber === "") {
                        ctx.addIssue({
                            code: "custom",
                            message: "Required",
                            path: ["floorNumber"],
                        });
                    }
            }
        },
    );

export default googleAutocompleteSchema;
