import {useEffect} from "react";
import useHeaderNavStore from "src/utils/zustandStores/primary/useHeaderNavStore";
import useViewport from "src/utils/zustandStores/primary/useViewport";
import background from "../../../media/carrier-side-login.webp";
import {ScrollArea} from "../../../components/scrollArea";
import {Button} from "../../../components/button";
import {SignInCarousel} from "../../../components/signInCarousel";

interface Props {
    children: React.ReactNode,
}

export default function LayoutAuth({children}: Props) {
    const {setDimensions} = useViewport();
    const {initTabName} = useHeaderNavStore();

    useEffect(() => {
        window.addEventListener("resize", setDimensions);
        return () => window.removeEventListener("resize", setDimensions);
    }, []);

    useEffect(() => {
        initTabName(window.location.pathname)
    }, [children]);

    return <div className={"h-[100dvh] flex flex-row w-dvw font-inter overflow-x-clip"}>

        <section className={'fixed top-0 h-[68px] w-full z-10 flex flex-row justify-between items-center place-content-center px-5 lg:px-[48px]'}>
            <a href={'https://hellomarco.com/'} className={'h-fit w-fit cursor-pointer'}>
                <svg width="102" height="18" viewBox="0 0 102 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className={'fill-[#17332D] lg:fill-[#FFFFFF]'}
                          d="M10.3971 7.40794V17.5126H14.621V8.18773C14.621 5.42599 15.6932 3.86643 17.8701 3.86643C19.7545 3.86643 20.7943 5.0361 20.7943 7.37545V17.5126H25.0181V6.69314C25.0181 2.37184 22.7762 0 19.0397 0C17.2202 0 16.0181 0.747293 15.4332 1.36462L14.5235 5.06859C14.0036 1.91697 11.8592 0 8.87004 0C6.8556 0 5.75091 0.877257 5.23105 1.36462L4.22383 5.26354V0.617329H0V17.5126H4.22383V8.25271C4.3213 5.58845 5.39351 3.86643 7.53791 3.86643C9.64982 3.86643 10.3971 5.62094 10.3971 7.40794ZM46.3834 0.617329H42.1596V2.37184C40.86 0.942239 39.008 0 36.5712 0C31.5351 0 28.0585 3.89892 28.0585 9.06498C28.0585 14.1661 31.5675 18 36.6036 18C39.2029 18 40.9899 17.0578 42.2246 15.6282L42.517 11.3718L44.499 17.5126H48.9502L46.3834 9.94224V0.617329ZM32.4448 9.06498C32.4448 5.94585 34.5567 3.83394 37.3834 3.83394C40.0802 3.83394 42.3221 5.88087 42.3221 9.06498C42.3221 12.1841 40.0802 14.1661 37.3834 14.1661C34.5567 14.1661 32.4448 12.1191 32.4448 9.06498ZM51.6873 0.617329V17.5126H55.9111V9.3574C55.9111 6.04332 57.5032 4.25632 59.9075 4.25632C60.7198 4.25632 61.3046 4.35379 62.0844 4.58123V0.454875C61.4996 0.227438 61.0447 0.194945 60.3949 0.194945C58.6728 0.194945 57.4382 1.10469 56.8533 1.75451L55.9111 6.17329V0.617329H51.6873ZM72.6612 0C67.3652 0 63.5637 3.70397 63.5637 9C63.5637 14.3285 67.2352 18 72.6612 18C76.4302 18 79.3869 15.9206 80.524 13.2238L76.885 11.1119C76.3977 12.8014 74.6757 14.1336 72.6612 14.1336C69.867 14.1336 67.95 12.2491 67.95 9C67.95 5.7509 69.8995 3.86643 72.6612 3.86643C74.7406 3.86643 76.4952 5.06859 76.885 6.98556L80.6215 4.80867C79.5168 2.07942 76.3977 0 72.6612 0ZM92.051 0C86.755 0 82.8236 3.73646 82.8236 9C82.8236 14.2635 86.755 18 92.051 18C97.347 18 101.278 14.2635 101.278 9C101.278 3.73646 97.347 0 92.051 0ZM87.2099 9C87.2099 5.84838 89.2568 3.86643 92.051 3.86643C94.8128 3.86643 96.8922 5.84838 96.8922 9C96.8922 12.1516 94.8128 14.1336 92.051 14.1336C89.2893 14.1336 87.2099 12.1516 87.2099 9Z"
                          fill="#17332D"/>
                </svg>
            </a>

            <a href={'https://hellomarco.com/sign-in'}>
                <Button variant={'outline'} className={'font-inter !text-[#222222] text-[14px] leading-[20px] rounded-[6px] font-medium'}>Sign
                    in as a Customer</Button>
            </a>

        </section>

        <section className={`flex-none max-w-[45%] 2xl:max-w-[716px] hidden lg:block relative`}>
            <img src= {background} className={"object-cover h-full w-full"}/>
            <SignInCarousel/>
        </section>

        <ScrollArea className={'h-[calc(100dvh-68px)] w-full mt-[68px]'}>
            <section className={'h-[calc(100dvh-68px)] flex flex-col items-center'}>
                <div className={'flex-1 w-full px-5 max-w-[440px] content-center'}>
                    {children}
                </div>

                <p className={'flex-none h-[64px] px-5 text-center w-full content-center font-inter text-[#717171] text-[12px] leading-[16px]'}>
                    {'By continuing, I agree to Marco’s Privacy Policy and Terms of Use.'}
                </p>
            </section>
        </ScrollArea>
    </div>
}