import type {UseFormReturn} from "react-hook-form";
import React from "react";
import {FormControl, FormField, FormItem} from "src/components/form";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "src/components/select";

interface Props {
  form: UseFormReturn<{
    access: string;
    floorNumber: string;
    googleMapAddress: string;
    manualApt?: string;
    manualCity: string;
    manualCountry: string;
    manualState: string;
    manualStreetAddress: string;
    manualZip: string;

    numberStories: string;
    propertySize: string;
    propertyType: string;
    squareFootage: string;
    unitNumber: string;
    unitSize: string;
  }>;
}

export default function PropertySize({ form }: Props) {
  return (
    <section className={'flex flex-col gap-2'}>

        <p className={`text-[14px] leading-[16px] font-medium ${
          form.getFieldState("propertySize").invalid
              ? "text-[#C6241D] outline-[#C6241D] outline-2"
              : ""
      }`}>Property size</p>

      <FormField
          control={form.control}
          name="propertySize"
          render={({ field }) => (
              <FormItem className="flex-1">
                <Select
                    onValueChange={(e) => {
                      form.setValue("propertySize", e);
                      form.clearErrors("propertySize");
                    }}
                    value={field.value}
                >
                  <FormControl>
                    <SelectTrigger
                        className={`${
                            form.getFieldState("propertySize").invalid
                                ? "text-[#C6241D] outline-[#C6241D] outline-2"
                                : ""
                        }`}
                    >
                      <div
                          className={`${
                              form.getValues("propertySize") === ""
                                  ? "text-[#717171] pl-1" : "text-marco_default_foreground"
                          }
                  ${form.getFieldState("propertySize").invalid ? "!text-[#C6241D]" : ""}
                  text-[16px] leading-[24px] font-normal`}
                      >
                        <SelectValue placeholder="Property size" />
                      </div>
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem key={"STUDIO"} value={"STUDIO"}>
                      Studio
                    </SelectItem>

                    <SelectItem key={"ONE_BEDROOM"} value={"ONE_BEDROOM"}>
                      1 Bedroom
                    </SelectItem>

                    <SelectItem key={"TWO_BEDROOMS"} value={"TWO_BEDROOMS"}>
                      2 Bedrooms
                    </SelectItem>

                    <SelectItem key={"THREE_BEDROOMS"} value={"THREE_BEDROOMS"}>
                      2 Bedrooms
                    </SelectItem>

                    <SelectItem key={"FOUR_BEDROOMS"} value={"FOUR_BEDROOMS"}>
                      4+ Bedrooms
                    </SelectItem>
                  </SelectContent>
                </Select>
              </FormItem>
          )}
      />
    </section>

  );
}
