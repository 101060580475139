import {Carousel, CarouselApi, CarouselContent, CarouselItem} from "./carousel"
import {useEffect, useState} from "react";
import Charlie from '../images/charlie.webp'
import Dennis from '../images/dennis.webp'
import Danniel from '../images/danniel.webp'
import Alex from '../images/alex.webp'

export const SignInCarousel = () => {

    const [currentCarouselIndex, setCurrentCarouselIndex] = useState(0);
    const [api, setApi] = useState<CarouselApi>()

    useEffect(() => {
        if (!api) {
            return
        }

        api.on("select", (e) => {
setCurrentCarouselIndex(e.selectedScrollSnap())
        })
    }, [api, currentCarouselIndex])

    return <div className={'w-full absolute bottom-[68px] z-20 px-[35px] flex flex-col items-center gap-4'}>

        <section className={'w-full max-w-[430px]'}>
            <Carousel defaultValue={currentCarouselIndex} setApi={setApi}>
                <CarouselContent>
                    <CarouselItem className={' w-full px-1'}>
                        <section className={'bg-white w-full rounded-[12px] p-6 flex flex-col gap-0'}>
                            <p className={'flex-none h-[120px] w-full font-inter text-[16px] leading-[24px] font-normal text-[#222222]'}>
                                {'"Marco saved me countless hours and helped me deliver exceptional service to my customers, faster and more professionally than ever before."'}
                            </p>

                            <span className={'h-[48px] flex flex-row gap-4 items-center'}>
                                <img src={Charlie} alt={'Charlie'} className={'flex-none h-[48px] w-[48px] rounded-full overflow-clip'}/>
                                <div className={'flex-1 flex flex-col gap-0'}>
                                    <p className={'font-inter text-[#222222] text-[16px] leading-[20px] font-medium'}>Charlie</p>
                                    <p className={'font-iter text-[#717171] text-[14px] leading-[20px] font-normal'}>Express Moving & Storage</p>
                                </div>
                            </span>
                            </section>
                    </CarouselItem>
                    <CarouselItem className={' w-full px-1'}>
                        <section className={'bg-white w-full rounded-[12px] p-6 flex flex-col gap-0'}>
                            <p className={'flex-none h-[120px] w-full font-inter text-[16px] leading-[24px] font-normal text-[#222222]'}>
                                {'“Teaming up with Marco has been a breath of fresh air! We’re now able to serve our customers with a smile, saving time and enhancing our professionalism every step of the way.”'}
                            </p>

                            <span className={'h-[48px] flex flex-row gap-4 items-center'}>
                                <img src={Dennis} alt={'Charlie'}
                                     className={'flex-none h-[48px] w-[48px] rounded-full overflow-clip'}/>
                                <div className={'flex-1 flex flex-col gap-0'}>
                                    <p className={'font-inter text-[#222222] text-[16px] leading-[20px] font-medium'}>Dennis</p>
                                    <p className={'font-iter text-[#717171] text-[14px] leading-[20px] font-normal'}>Impel Relocations</p>
                                </div>
                            </span>
                        </section>
                    </CarouselItem>
                    <CarouselItem className={' w-full px-1'}>
                        <section className={'bg-white w-full rounded-[12px] p-6 flex flex-col gap-0'}>
                            <p className={'flex-none h-[120px] w-full font-inter text-[16px] leading-[24px] font-normal text-[#222222]'}>
                                {'“Working with Marco has been fantastic! We\'ve cut down on hours spent on logistics, allowing us to focus on what we love—providing great service to our customers.”'}
                            </p>

                            <span className={'h-[48px] flex flex-row gap-4 items-center'}>
                                <img src={Danniel} alt={'Charlie'}
                                     className={'flex-none h-[48px] w-[48px] rounded-full overflow-clip'}/>
                                <div className={'flex-1 flex flex-col gap-0'}>
                                    <p className={'font-inter text-[#222222] text-[16px] leading-[20px] font-medium'}>Danniel</p>
                                    <p className={'font-iter text-[#717171] text-[14px] leading-[20px] font-normal'}>Terra Van Lines</p>
                                </div>
                            </span>
                        </section>
                    </CarouselItem>
                    <CarouselItem className={' w-full px-1'}>
                        <section className={'bg-white w-full rounded-[12px] p-6 flex flex-col gap-0'}>
                            <p className={'flex-none h-[120px] w-full font-inter text-[16px] leading-[24px] font-normal text-[#222222]'}>
                                {'“Joining forces with Marco has made our lives so much easier! We’re now delivering top-notch service to our customers faster than ever, and it feels great!”'}
                            </p>

                            <span className={'h-[48px] flex flex-row gap-4 items-center'}>
                                <img src={Alex} alt={'Charlie'}
                                     className={'flex-none h-[48px] w-[48px] rounded-full overflow-clip'}/>
                                <div className={'flex-1 flex flex-col gap-0'}>
                                    <p className={'font-inter text-[#222222] text-[16px] leading-[20px] font-medium'}>Alex</p>
                                    <p className={'font-iter text-[#717171] text-[14px] leading-[20px] font-normal'}>Atlas Moving Systems</p>
                                </div>
                            </span>
                        </section>
                    </CarouselItem>
                </CarouselContent>
            </Carousel>
        </section>


        <section className={'w-full h-fit flex flex-row gap-2 items-center place-content-center'}>
            <div
                className={`rounded-full h-2 w-2 border border-1 border-white ${currentCarouselIndex === 0 && 'bg-white'}`}/>
            <div
                className={`rounded-full h-2 w-2 border border-1 border-white ${currentCarouselIndex === 1 && 'bg-white'}`}/>
            <div
                className={`rounded-full h-2 w-2 border border-1 border-white ${currentCarouselIndex === 2 && 'bg-white'}`}/>
            <div
                className={`rounded-full h-2 w-2 border border-1 border-white ${currentCarouselIndex === 3 && 'bg-white'}`}/>
        </section>
    </div>
}