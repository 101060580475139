import jwt from "jwt-decode";
import * as React from "react";
import {ReactNode, useEffect, useState} from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import {toast} from "src/components/useToast";
import useAlert, {AlertElementType} from "src/pages/dashboarSection/layout/popUps/alert/useAlert";
import useSheet, {ElementType} from "src/pages/dashboarSection/layout/popUps/sheet/useSheet";
import {resetPassword} from "src/utils/apiCalls/resetPassword";
import {Role} from "src/utils/enums/role";
import {Member} from "src/utils/types/structures/member";
import {Token} from "src/utils/types/token";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";
import {Separator} from "../../../components/separator";

interface Props {
    member: Member
    mutateMembers: () => void
    isOpen: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function PopoverMembersContent({member, mutateMembers, setOpen, isOpen}: Props) {
    const [memberDeleteOption, setMemberDeleteOption] = useState<ReactNode | null>(null);
    const [memberEditOption, setMemberEditOption] = useState<ReactNode | null>(null);
    const [memberResendInviteOption, setMemberResendInviteOption] = useState<ReactNode | null>(null);


    const {token} = useTokenStore();

    const isOutside = useOnclickOutside(() => {
        setOpen(false)
    });

    const {open: openDialog} = useSheet()
    const {open: openAlert} = useAlert()

    async function onResendInvite(member: Member) {
        setOpen(false);
        const res = await resetPassword(member.email);
        if (res !== 200) {
            toast({
                variant: "destructive",
                description: `${res.message}`,
            })
        }
    }

    useEffect(() => {
        if (token) {
            const user: Token = jwt(token);

            switch (member.role) {
                case Role.owner: {
                    setMemberDeleteOption(null)

                    if (!member.active) {
                        setMemberResendInviteOption(
                            <li className={'text-sm font-medium font-inter hover:bg-gray-600 hover:bg-opacity-5 px-2 py-2 rounded-lg cursor-pointer'}
                                onClick={() => onResendInvite(member)}>Resend invite
                            </li>
                        )
                    }

                    if (user.email === member.email) {
                        setMemberEditOption(
                            <li className={'text-sm text-foreground font-medium font-inter hover:bg-marcoOnHover px-2 py-2 rounded-lg cursor-pointer'}
                                onClick={() => {
                                    setOpen(false)
                                    openDialog(ElementType.fleetManagementEditMember, member, 'Edit member', mutateMembers)
                                }}>Edit member
                            </li>
                        )
                    }

                    break
                }

                case Role.manager: {
                    if (member.email === user.email) {
                        setMemberDeleteOption(null)
                    } else setMemberDeleteOption
                    (
                        <li
                            className={'text-sm font-medium text-destructive font-inter hover:bg-marcoOnHover px-2 py-2 rounded-lg cursor-pointer'}
                            onClick={() => openAlert(AlertElementType.member, member, 'Delete member', mutateMembers)}>
                            Remove
                        </li>
                    )

                    setMemberEditOption(
                        <li className={'text-sm text-foreground font-medium font-inter hover:bg-marcoOnHover px-2 py-2 rounded-lg cursor-pointer'}
                            onClick={() => {
                                setOpen(false)
                                openDialog(ElementType.fleetManagementEditMember, member, 'Edit member', mutateMembers)
                            }}>Edit member
                        </li>
                    )

                    if (!member.active) {
                        setMemberResendInviteOption(
                            <li className={'text-sm font-medium font-inter hover:bg-gray-600 hover:bg-opacity-5 px-2 py-2 rounded-lg cursor-pointer'}
                                onClick={() => onResendInvite(member)}>Resend invite
                            </li>
                        )
                    }
                    break
                }

                case Role.driver: {
                    setMemberDeleteOption
                    (
                        <li
                            className={'text-sm font-medium text-destructive font-inter hover:bg-marcoOnHover px-2 py-2 rounded-lg cursor-pointer'}
                            onClick={() => openAlert(AlertElementType.member, member, 'Delete member', mutateMembers)}>
                            Remove
                        </li>
                    )

                    setMemberEditOption(
                        <li className={'text-sm text-foreground font-medium font-inter hover:bg-marcoOnHover px-2 py-2 rounded-lg cursor-pointer'}
                            onClick={() => {
                                setOpen(false)
                                openDialog(ElementType.fleetManagementEditMember, member, 'Edit member', mutateMembers)
                            }}>Edit
                        </li>
                    )

                    if (!member.active) {
                        setMemberResendInviteOption(
                            <li className={'text-sm font-medium font-inter hover:bg-gray-600 hover:bg-opacity-5 px-2 py-2 rounded-lg cursor-pointer'}
                                onClick={() => onResendInvite(member)}>Resend invite
                            </li>
                        )
                    }
                    break
                }
            }
        }
    }, [member]);

    return <ul ref={isOutside} className={'flex flex-col w-full h-full'}>
        <section className={'p-[5px] flex flex-col gap-[5px]'}>
            {memberResendInviteOption}
            {memberEditOption}
        </section>


        {memberDeleteOption && <>
            <Separator/>
            <section className={'p-[5px]'}>{memberDeleteOption}</section>
        </>
        }
    </ul>
}