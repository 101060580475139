import {ApiHost} from "src/utils/defaults/apiHost";
import getDefinedError from "src/utils/errorHandlers/getDefinedError";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";
import {Address} from "../types/structures/Address";
import {NumberStories} from "../enums/addressEnum/numberStories";
import {PropertyType} from "../enums/addressEnum/propertyType";
import {Access} from "../enums/addressEnum/access";
import {SquareFootage} from "../enums/addressEnum/squareFootage";
import {PropertySize} from "../enums/addressEnum/propertySize";
import {UnitSize} from "../enums/addressEnum/unitSize";

export type OrderAddressToEdit = {
    address: string;
    orderId: string;
    direction: string;
    unitNumber: string;
    floorNumber: string;
    propertyType: PropertyType;

    access: Access;

    numberStories: NumberStories| null;
    squareFootage: SquareFootage | null;
    unitSize: UnitSize | null;
    propertySize: PropertySize | null
}

export async function updateOrderAddress(data: OrderAddressToEdit) {
    const res = await fetch(ApiHost + encodeURI(`api/v1/order/address`), {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${useTokenStore.getState().token}`
        }
    })

    if (res.ok) {
        const data: Address = await res.json();
        return {data: data, error: null}
    } else {
        const err = await getDefinedError(res)
        return {data: null, error: err}
    }
}