import moment from "moment";
import {Button} from "src/components/button";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "src/components/table";
import {ShevronRight} from "src/media/icons/custom/shevron/shevronRight";
import useSheet, {ElementType} from "src/pages/dashboarSection/layout/popUps/sheet/useSheet";
import OrderCard from "src/pages/dashboarSection/search/orderList/OrderCard";
import {Colors} from "src/utils/defaults/colors";
import {defaultDimensions} from "src/utils/defaults/defaultDimensions";
import getPropertyType from "src/utils/stringHandlers/enumToStringHandlers/getPropertyType";
import {getAddressIndexOff} from "src/utils/stringHandlers/getAddressIndexOff";
import getFixedToZeroDecimalNumber from "src/utils/stringHandlers/getFixedToZeroDecimalNumber";
import {OrderSearch} from "src/utils/types/structures/orderSearch";
import useViewport from "src/utils/zustandStores/primary/useViewport";

interface Props {
    orderList: OrderSearch[]
    date: Date
}

export default function GridCard({date, orderList}: Props) {
    const {width} = useViewport();
    const {open} = useSheet()

    return <div className={'px-padding'}>
        <h2 className={'py-6 text-[#222222] text-[22px] leading-[26px] font-semibold'}>
            {moment(date).format('ddd, MMM D')}
        </h2>

        {width < defaultDimensions.md
            ? <section className={'grid xs:grid-cols-1 sm:grid-cols-2 gap-4'}>
                {orderList.map(
                    el => <OrderCard key={el.id} order={el}/>
                )}
            </section>

            : <Table>
                <TableHeader>
                    <TableRow>
                        <TableHead className={'px-3 text-sm font-semibold w-[20rem]'}>Rate</TableHead>
                        <TableHead className={'text-sm font-semibold w-[20rem]'}>Pickup</TableHead>
                        <TableHead className={'text-sm font-semibold w-[20rem]'}>Delivery</TableHead>
                        <TableHead className={'px-3 text-sm font-semibold w-[20rem]'}>Property</TableHead>
                        <TableHead className={'px-3 text-sm font-semibold w-[10rem]'}>Distance</TableHead>
                        <TableHead className={'px-3 text-sm font-semibold w-[10rem]'}>Volume</TableHead>
                        <TableHead className={'w-12 min-w-12'}></TableHead>

                    </TableRow>
                </TableHeader>
                <TableBody>

                    {orderList.map(
                        order => <TableRow
                            key={order.id}
                            className={'cursor-pointer'}
                            onClick={() => open(ElementType.order, order, 'Load details')}>

                            <TableCell
                                className={'px-3 text-foreground text-sm font-normal'}>${getFixedToZeroDecimalNumber(order.rate, 2)}</TableCell>

                            <TableCell>
                                <div className={'flex flex-col'}>
                                    <p className={'text-foreground text-sm font-normal'}>{getAddressIndexOff(order.originAddress)}</p>
                                    <p
                                        className={'text-muted-foreground text-xs font-normal'}>{moment(order.pickupDate).format('MMMM D')}</p>
                                </div>
                            </TableCell>

                            <TableCell>
                                <div className={'flex flex-col'}>
                                    <p className={'text-foreground text-sm font-normal'}>{getAddressIndexOff(order.destinationAddress)}</p>
                                    <p className={'text-muted-foreground text-xs font-normal'}>{order.deliveryDate}</p>
                                </div>
                            </TableCell>

                            <TableCell className={'px-3 text-foreground text-sm font-normal'}>{getPropertyType(order.property)}</TableCell>

                            <TableCell className={'px-3 text-foreground text-sm font-normal'}>{getFixedToZeroDecimalNumber(order.distance)} mi</TableCell>

                            <TableCell className={'px-3 text-foreground text-sm font-normal'}>{getFixedToZeroDecimalNumber(order.volume)} cu.ft</TableCell>

                            <TableCell className={'p-0 w-12 min-w-12'}>
                                <div className={'w-full grid'}>
                                    <Button variant={'ghost'} size={'iconFit'} className={'place-self-center p-[0.375rem]'}><ShevronRight size={20} stroke={Colors.foreground}/></Button>
                                </div>
                            </TableCell>

                        </TableRow>
                    )}
                </TableBody>
            </Table>
        }
    </div>
}