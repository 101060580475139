import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
    size: number
}


const DownLoadIcon = ({...props}: Props) => {

    return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5"
            stroke="#DEDEDE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5.8335 8.33301L10.0002 12.4997L14.1668 8.33301" stroke="#DEDEDE" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10 12.5V2.5" stroke="#DEDEDE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

}

export {DownLoadIcon}