import type {UseFormReturn} from "react-hook-form";
import React from "react";
import {FormControl, FormField, FormItem} from "src/components/form";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "src/components/select";

interface Props {
  form: UseFormReturn<{
    access: string;
    floorNumber: string;
    googleMapAddress: string;
    manualApt?: string;
    manualCity: string;
    manualCountry: string;
    manualState: string;
    manualStreetAddress: string;
    manualZip: string;

    numberStories: string;
    propertySize: string;
    propertyType: string;
    squareFootage: string;
    unitNumber: string;
    unitSize: string;
  }>;
}

export default function UnitSize({ form }: Props) {
  return (
      <section className={'flex flex-col gap-2'}>

        <p className={`text-[14px] leading-[16px] font-medium ${
            form.getFieldState("unitSize").invalid
                ? "text-[#C6241D] outline-[#C6241D] outline-2"
                : ""
        }`}>Unit size</p>

        <FormField
            control={form.control}
            name="unitSize"
            render={({ field }) => (
                <FormItem className="flex-1">
                  <Select
                      onValueChange={(e) => {
                        form.setValue("unitSize", e);
                        form.clearErrors("unitSize");
                      }}
                      value={field.value}
                  >
                    <FormControl>
                      <SelectTrigger
                          className={`${
                              form.getFieldState("unitSize").invalid
                                  ? "text-[#C6241D] outline-[#C6241D] outline-2"
                                  : ""
                          }`}
                      >
                        <div
                            className={`${
                                form.getValues("unitSize") === ""
                                    ? "text-[#717171] pl-1"
                                    : "pl-1 text-marco_default_foreground"
                            }
                  ${form.getFieldState("unitSize").invalid ? "!text-[#C6241D]" : ""}
                  text-[16px] leading-[24px] font-normal`}
                        >
                          <SelectValue placeholder="Unit size" />
                        </div>
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem key={"UNIT_5x10"} value={"UNIT_5x10"}>
                        5x10
                      </SelectItem>

                      <SelectItem key={"UNIT_10x10"} value={"UNIT_10x10"}>
                        10x10
                      </SelectItem>

                      <SelectItem key={"UNIT_10x15"} value={"UNIT_10x15"}>
                        10x15
                      </SelectItem>

                      <SelectItem key={"UNIT_10x20"} value={"UNIT_10x20"}>
                        10x20
                      </SelectItem>

                      <SelectItem key={"UNIT_10x25"} value={"UNIT_10x25"}>
                        10x25
                      </SelectItem>

                      <SelectItem key={"UNIT_10x30"} value={"UNIT_10x30"}>
                        10x30
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </FormItem>
            )}
        />
      </section>


  );
}
