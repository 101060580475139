import {zodResolver} from "@hookform/resolvers/zod";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {Button} from "src/components/button";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "src/components/form";
import {Input} from "src/components/input";
import LoadingStatus from "src/components/loadingStatus";
import {Textarea} from "src/components/textArea";
import useSheet from "src/pages/dashboarSection/layout/popUps/sheet/useSheet";
import {CustomItem} from "src/utils/types/structures/customItem";
import customItemSchema from "src/utils/zodSchemas/customItemSchema";
import useOrderInventory from "src/utils/zustandStores/orderDetailsInventory/orderInventory";
import * as z from "zod";
import {InentorySection} from "../updateItems";

interface Props {
    setCurrentActiveTab: React.Dispatch<React.SetStateAction<InentorySection>>
}

export default function AddCustomItem({setCurrentActiveTab}: Props) {
    const [isLoading] = useState<boolean>(false);
    const {close} = useSheet();
    const {onExtendedItemAdd} = useOrderInventory()

    const form = useForm<z.infer<typeof customItemSchema>>({
        resolver: zodResolver(customItemSchema),
        defaultValues: {
            count: 1,
            name: '',
        }
    });

    async function onSubmit(values: z.infer<typeof customItemSchema>) {
        const newCustomItem: CustomItem = {
            weight: values.weight ?? null,
            id: null,
            name: values.name,
            count: values.count,
            volume: values.volume,
            width: values.width ? Number((values.width * 0.083).toFixed(1)) : null,
            height: values.height ? Number((values.height * 0.083).toFixed(1)) : null,
            length: values.length ? Number((values.length * 0.083).toFixed(1)) : null,
            description: values.description ?? null,
        }
        onExtendedItemAdd(newCustomItem)
        setCurrentActiveTab(InentorySection.searchInventory)
    }

    function getButtonName() {
        if (isLoading) {
            return <LoadingStatus/>
        } else {
            return 'Add item'
        }
    }

    return <Form {...form}>
        <form className={'h-[calc(100dvh-8.7rem)] flex flex-col gap-6'} onSubmit={form.handleSubmit(onSubmit)}>

            <section className={"grow px-6 pt-5 flex flex-col"}>

                <div className={'flex flex-row gap-4 items-center pb-[14px]'}>
                    <FormField
                        control={form.control}
                        name="name"
                        render={({field}) => (
                            <FormItem className={'flex-1'}>

                                <FormLabel className={'flex justify-between'}>
                                    <p className={'text-[#222222] text-[14px] leading-[16px] font-medium'}>Item name</p>
                                    {form.getFieldState('name').invalid &&
                                        <FormMessage className={'text-marcoFormErrorTextColor text-sm h-3 pb-5'}/>}
                                </FormLabel>

                                <FormControl>
                                    <Input
                                        {...field}
                                        placeholder={"Item name"}
                                        className={`${form.getFieldState('name').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'} text-base w-full`}
                                        autoComplete={'off'}/>
                                </FormControl>
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="count"
                        render={({field}) => (
                            <FormItem>

                                <FormLabel className={'flex justify-between'}>
                                    <p className={'text-sm font-medium'}>Quantity</p>
                                </FormLabel>

                                <FormControl>

                                    <div className={`${form.getFieldState('count').invalid ? 'bg-marcoFormBackgroundColor' : ''} rounded-[6px] w-[64px]`}>
                                        <Input className={`text-[#222222] text-[16px] leading-[24px] font-normal w-full text-center`}
                                               {...field}
                                               placeholder={"Qt"}
                                               type={'number'}
                                               step={1}
                                               autoComplete={'off'}
                                               onChange={(e) => form.setValue('count', Number(e.target.value))}
                                        />
                                    </div>

                                </FormControl>
                            </FormItem>
                        )}
                    />
                </div>

                <div className={'flex flex-col gap-2'}>
                    <div className={'flex flex-col gap-1'}>
                        <p className={'text-[#222222] text-[14px] leading-[20px] font-medium'}>Description</p>
                        <p className={'text-[#717171] text-[14px] leading-[20px] font-normal'}>A description each space will help us determine scope and necessary expertise.</p>
                    </div>

                    <FormField
                        control={form.control}
                        name="description"
                        render={({field}) => (
                            <FormItem>

                                {form.getFieldState('description').invalid && <FormMessage
                                    className={'text-marcoFormErrorTextColor text-sm h-3 pb-5 grid place-items-end'}/>}

                                <FormControl>
                                    <Textarea
                                        {...field}
                                        placeholder={'Leave comments here...'}
                                        className={`${form.getFieldState('description').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'} text-sm w-full min-h-[80px]`}
                                    />
                                </FormControl>
                            </FormItem>
                        )}
                    />
                </div>

                <div className={'flex flex-col gap-4 pt-6'}>
                    <div className={'flex flex-col gap-1'}>
                        <p className={'text-sm font-medium text-foreground'}>Dimensions</p>
                        <p className={'text-sm text-muted-foreground'}>A description each space will help us determine
                            scope and necessary expertise.</p>
                    </div>

                    <div className={'flex gap-4'}>
                        <FormField
                            control={form.control}
                            name="volume"
                            render={({field}) => (
                                <FormItem className={'flex-1'}>
                                    <FormLabel className={'flex justify-between'}>
                                        <p className={'text-sm text-foreground'}>Volume (cuft.)</p>
                                        {form.getFieldState('volume').invalid &&
                                            <FormMessage className={'text-marcoFormErrorTextColor text-sm h-3 pb-5'}/>}
                                    </FormLabel>

                                    <FormControl>
                                        <Input
                                            {...field}
                                            placeholder={"Volume"}
                                            type={'number'}
                                            step={1}
                                            className={`${form.getFieldState('volume').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'} text-base w-full`}
                                            autoComplete={'off'}
                                            onChange={e => {
                                                form.setValue('volume', Number(e.target.value))
                                                form.setValue('weight', Number(e.target.value) * 6)
                                            }}/>
                                    </FormControl>
                                </FormItem>
                            )}
                        />

                        <div className={'flex-1 flex flex-col gap-2'}>
                            <span className={'text-foreground text-sm font-medium leading-5'}>Approx. Value ($)</span>
                            <Input placeholder={'Insurance value'} disabled/>
                        </div>

                    </div>

                    <div className={'flex gap-4'}>
                        <FormField
                            control={form.control}
                            name="width"
                            render={({field}) => (
                                <FormItem className={'flex-1'}>

                                    <FormLabel className={'flex justify-between'}>
                                        <p className={'text-sm text-foreground'}>Width (in.)</p>
                                        {form.getFieldState('width').invalid &&
                                            <FormMessage className={'text-marcoFormErrorTextColor text-sm h-3 pb-5'}/>}
                                    </FormLabel>

                                    <FormControl>
                                        <Input
                                            {...field}
                                            placeholder={"Width"}
                                            type={'number'}
                                            value={form.getValues('width') ?? ''}
                                            min={1}
                                            step={1}
                                            className={`${form.getFieldState('width').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'} text-base w-full`}
                                            autoComplete={'off'}
                                            onChange={e => {
                                                form.setValue('width', Number(e.target.value))
                                                form.setValue("volume", Number(((((form.getValues('height') ?? 0) * (form.getValues('length') ?? 0) * Number(e.target.value))) / 1728).toFixed(0)))
                                                form.setValue("weight", Number(((((form.getValues('height') ?? 0) * (form.getValues('length') ?? 0) * Number(e.target.value)) / 1728) * 6).toFixed(0)))
                                            }}
                                        />
                                    </FormControl>
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="length"
                            render={({field}) => (
                                <FormItem className={'flex-1'}>

                                    <FormLabel className={'flex justify-between'}>
                                        <p className={'text-sm text-foreground'}>Depth (in.)</p>
                                        {form.getFieldState('length').invalid &&
                                            <FormMessage className={'text-marcoFormErrorTextColor text-sm h-3 pb-5'}/>}
                                    </FormLabel>

                                    <FormControl>
                                        <Input
                                            {...field}
                                            value={form.getValues('length') ?? ''}
                                            placeholder={"Depth"}
                                            type={'number'}
                                            min={1}
                                            step={1}
                                            className={`${form.getFieldState('length').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'} text-base w-full`}
                                            autoComplete={'off'}
                                            onChange={e => {
                                                form.setValue('length', Number(e.target.value))
                                                form.setValue("volume", Number(((((form.getValues('height') ?? 0) * (form.getValues('width') ?? 0) * Number(e.target.value))) / 1728).toFixed(0)))
                                                form.setValue('weight', Number(((((form.getValues('height') ?? 0) * (form.getValues('width') ?? 0) * Number(e.target.value)) / 1728) * 6).toFixed(0)))
                                            }}
                                        />

                                    </FormControl>
                                </FormItem>
                            )}
                        />
                    </div>

                    <div className={'flex gap-4'}>
                        <FormField
                            control={form.control}
                            name="height"
                            render={({field}) => (
                                <FormItem className={'flex-1'}>

                                    <FormLabel className={'flex justify-between'}>
                                        <p className={'text-sm text-foreground'}>Height (in.)</p>
                                        {form.getFieldState('height').invalid &&
                                            <FormMessage className={'text-marcoFormErrorTextColor text-sm h-3 pb-5'}/>}
                                    </FormLabel>

                                    <FormControl>
                                        <Input
                                            {...field}
                                            value={form.getValues('height') ?? ''}
                                            placeholder={"Height"}
                                            type={'number'}
                                            min={1}
                                            step={1}
                                            className={`${form.getFieldState('height').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'} text-base w-full`}
                                            onChange={e => {
                                                form.setValue('height', Number(e.target.value))
                                                form.setValue("volume", Number(((((form.getValues('length') ?? 0) * (form.getValues('width') ?? 0) * Number(e.target.value))) / 1728).toFixed(0)))
                                                form.setValue('weight', Number(((((form.getValues('length') ?? 0) * (form.getValues('width') ?? 0) * Number(e.target.value)) / 1728) * 6).toFixed(0)))
                                            }}
                                            autoComplete={'off'}/>
                                    </FormControl>
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="weight"
                            render={({field}) => (
                                <FormItem className={'flex-1'}>

                                    <FormLabel className={'flex justify-between'}>
                                        <p className={'text-sm text-foreground'}>Weight (lb.)</p>
                                        {form.getFieldState('weight').invalid &&
                                            <FormMessage className={'text-marcoFormErrorTextColor text-sm h-3 pb-5'}/>}
                                    </FormLabel>

                                    <FormControl>
                                        <Input
                                            {...field}
                                            type={'number'}
                                            placeholder={"Weight"}
                                            step={1}
                                            onChange={event => form.setValue('weight', Number(event.target.value))}
                                            className={`${form.getFieldState('weight').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'} text-base w-full`}
                                            autoComplete={'off'}/>
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                    </div>
                </div>
            </section>


            <section
                className={'flex-none sticky bottom-0 bg-white border-t z-40 flex justify-between py-4 px-6 gap-4'}>
                <Button size={'lg'}
                        variant={'outline'}
                        className={'w-full text-[#222222] text-[16px] leading-[20px] font-medium'}
                        onClick={close}>Cancel</Button>

                <Button size={'lg'}
                        variant={'default'}
                        type={'submit'}
                        className={'w-full text-white text-[16px] leading-[20px] font-medium'}
                >{getButtonName()}</Button>
            </section>
        </form>
    </Form>
}