import React, {useEffect, useState} from "react";
import {Button} from "src/components/button";
import Overlay from "src/components/overlay";
import {ScrollArea, ScrollBar} from "src/components/scrollArea";
import {Separator} from "src/components/separator";
import {ShevronDown} from "src/media/icons/custom/shevron/shevronDown";
import ResetFilter from "src/pages/dashboarSection/search/searchLayout/filters/resetFilter";
import MobileFilterContent from "src/pages/dashboarSection/search/searchLayout/mobileSearch/mobileFilterContent";
import {Colors} from "src/utils/defaults/colors";
import {defaultDimensions} from "src/utils/defaults/defaultDimensions";
import {defaultFilterValues} from "src/utils/defaults/defaultFilterValues";
import getDatetoD from "src/utils/stringHandlers/calendarFilter/getDatetoD";
import getDatetoMD from "src/utils/stringHandlers/calendarFilter/getDatetoMD";
import getDatetoMDY from "src/utils/stringHandlers/calendarFilter/getDatetoMDY";
import useViewport from "src/utils/zustandStores/primary/useViewport";
import useOverlay from "src/utils/zustandStores/useOverlay";
import useSearch from "src/utils/zustandStores/useSearch";

export enum FilterType {
    date = 'DATE',
    radius = 'RADIUS',
    volume = 'VOLUME',
    distance = 'DISTANCE',
    rate = 'RATE',
    sort = 'SORT'
}

export default function MobileFilter() {
    const {width} = useViewport();
    const {isOpen, setOpen} = useOverlay();
    const {response} = useSearch();
    const [filterType, setFilterType] = useState<FilterType | null>(null);

    useEffect(() => {
        if (width > defaultDimensions.md) {
            setOpen(false)
        }
    }, [width]);

    return <div className={'relative w-full'}>
        <Overlay/>
        <ScrollArea className={"w-[95vw] sm:w-full"}>
            <div className={'relative'}>
                <div className={'flex w-max gap-2 pt-[15px] pb-[18px] items-center'}>
                    <Button
                        onClick={() => {
                            setOpen(true);
                            setFilterType(FilterType.sort)
                        }}
                        key='sort'
                        type={'button'}
                        variant={"rounded"}
                        size={"tab"}
                        className={`rounded-full flex gap-2`}
                    >
                        <span
                            className={'text-foreground text-[14px] leading-[20px] font-medium'}>Sort: {response.body.sortFilterValue[0] + response.body.sortFilterValue.slice(1).toLowerCase()}</span>
                        <ShevronDown width={10} height={6} stroke={Colors.foreground}/>
                    </Button>
                    <Separator orientation="vertical" className={'h-5'}/>

                    <Button onClick={() => {
                        setOpen(true);
                        setFilterType(FilterType.date)
                    }}
                            key='calendar'
                            type={'button'}
                            variant={"rounded"}
                            size={"tab"}
                            className={`
                        ${response.body.date !== undefined && 'outline outline-foreground outline-2 outline-offset-[-2px]'}
                        rounded-full flex gap-2 text-[14px] leading-[20px] font-medium`}
                    >
                        {response.body.date?.from
                            ? (
                                response.body.date.to
                                    ? (
                                        response.body.date.to.getFullYear() === response.body.date.from.getFullYear()

                                            ? (response.body.date.to.getMonth() === response.body.date.from.getMonth()

                                                    ? <p className={'text-sm'}>
                                                        {getDatetoMD(response.body.date.from)} -{" "}
                                                        {getDatetoD(response.body.date.to)}
                                                    </p>

                                                    : <p className={'text-sm'}>
                                                        {getDatetoMD(response.body.date.from)} -{" "}
                                                        {getDatetoMD(response.body.date.to)}
                                                    </p>
                                            )

                                            : <p className={'text-sm'}>
                                                {getDatetoMDY(response.body.date.from)} -{" "}
                                                {getDatetoMDY(response.body.date.to)}
                                            </p>
                                    )

                                    : (
                                        <p className={'text-[14px] leading-[20px] font-medium'}>{getDatetoMD(response.body.date.from)}</p>
                                    )
                            )
                            : (
                                <p className={'text-[14px] leading-[20px] font-medium'}>Date</p>
                            )}
                        <ShevronDown width={10} height={6} stroke={Colors.foreground}/>
                    </Button>

                    <Button
                        onClick={() => {
                            setOpen(true);
                            setFilterType(FilterType.radius)
                        }}
                        key='radius'
                        type={'button'}
                        variant={"rounded"}
                        size={"tab"}
                        className={`
                        ${response.body.originRadius !== defaultFilterValues.originRadius && 'outline outline-foreground outline-2 outline-offset-[-2px]'} 
                        ${response.body.destinationRadius !== defaultFilterValues.destinationRadius && 'outline outline-foreground outline-2 outline-offset-[-2px]'}
                        rounded-full flex gap-2`}
                    >
                        <span className={`text-foreground text-[14px] leading-[20px] font-medium`}>Radius</span>
                        <ShevronDown width={10} height={6} stroke={Colors.foreground}/>
                    </Button>

                    <Button
                        onClick={() => {
                            setOpen(true);
                            setFilterType(FilterType.volume)
                        }}
                        key='volume'
                        variant={"rounded"}
                        type={'button'}
                        size={"tab"}
                        className={`
                        ${response.body.startVolume !== defaultFilterValues.startVolume && 'outline outline-foreground outline-2 outline-offset-[-2px]'} 
                        ${response.body.endVolume !== defaultFilterValues.endVolume && 'outline outline-foreground outline-2 outline-offset-[-2px]'}
                        rounded-full flex gap-2`}
                    >
                        <span className={'text-foreground text-[14px] leading-[20px] font-medium'}>Volume</span>
                        <ShevronDown width={10} height={6} stroke={Colors.foreground}/>
                    </Button>

                    <Button
                        onClick={() => {
                            setOpen(true);
                            setFilterType(FilterType.distance)
                        }}
                        key='distance'
                        variant={"rounded"}
                        type={'button'}
                        size={"tab"}
                        className={`
                        ${response.body.startDistance !== defaultFilterValues.startDistance && 'outline outline-foreground outline-2 outline-offset-[-2px]'} 
                        ${response.body.endDistance !== defaultFilterValues.endDistance && 'outline outline-foreground outline-2 outline-offset-[-2px]'}
                        rounded-full flex gap-2 `}
                    >
                        <span className={'text-foreground text-[14px] leading-[20px] font-medium'}>Distance</span>
                        <ShevronDown width={10} height={6} stroke={Colors.foreground}/>
                    </Button>

                    <Button
                        onClick={() => {
                            setOpen(true);
                            setFilterType(FilterType.rate)
                        }}
                        key='rates'
                        variant={"rounded"}
                        type={'button'}
                        size={"tab"}
                        className={`
                        ${response.body.startRate !== defaultFilterValues.startRate && 'outline outline-foreground outline-2 outline-offset-[-2px]'} 
                        ${response.body.endRate !== defaultFilterValues.endRate && 'outline outline-foreground outline-2 outline-offset-[-2px]'}
                        rounded-full flex gap-2`}
                    >
                        <span className={'text-foreground text-[14px] leading-[20px] font-medium'}>Rates</span>
                        <ShevronDown width={10} height={6} stroke={Colors.foreground}/>
                    </Button>

                    <ResetFilter/>
                    
                </div>
            </div>
            <ScrollBar orientation="horizontal" className={'hidden'}/>
        </ScrollArea>

        {isOpen && <MobileFilterContent filterType={filterType}/>}
    </div>
}