export enum Access {
    no_stairs = "NO_STAIRS",
    one_flight_of_stairs = "ONE_FLIGHT_OF_STAIRS",
    two_flight_of_stairs = "TWO_FLIGHT_OF_STAIRS",
    three_flight_of_stairs = "THREE_FLIGHT_OF_STAIRS",
    four_flight_of_stairs = "FOUR_FLIGHT_OF_STAIRS",
    five_flight_of_stairs = "FIVE_FLIGHT_OF_STAIRS",
    six_flight_of_stairs = "SIX_FLIGHT_OF_STAIRS",
    elevator = "ELEVATOR",
    drive_up = "DRIVE_UP"
}