import * as z from "zod";

const companyDetailsSchema = z.object({
    firstName: z.string().min(1, {message: 'Required'}),
    lastName: z.string().min(1, {message: 'Required'}),
    email: z.string().email(),
    phoneNumber: z.string(),
    companyName: z.string().min(1, {message: 'Required'}),
    MC: z.string().min(1, {message: 'Required'}),
    DOT: z.string().min(1, {message: 'Required'}),
    steetAddress: z.string().min(1, {message: 'Required'}),
    aptSuiteEtc: z.string().optional(),
    city: z.string().min(1, {message: 'Required'}),
    state: z.string().min(1, {message: 'Required'}),
    ZIP: z.string().min(1, {message: 'Required'}),
    country: z.string().min(1, {message: 'Required'}),
})

export default companyDetailsSchema