import {AdvancedMarker, Map, useMap} from "@vis.gl/react-google-maps";
import React, {useEffect, useState} from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import {useNavigate} from "react-router-dom";
import {Button} from "src/components/button";
import {Separator} from "src/components/separator";
import {DotIcon} from "src/media/icons/custom/dotIcon";
import {SquareIcon} from "src/media/icons/custom/squareIcon";
import useSheet from "src/pages/dashboarSection/layout/popUps/sheet/useSheet";
import {acceptOrder} from "src/utils/apiCalls/orderFulfillmentFlow/acceptOrder";
import {mapId} from "src/utils/defaults/googleMapSettings";
import routes from "src/utils/defaults/routes";
import {handleResponseError} from "src/utils/errorHandlers/handleResponseError";
import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import getDirections from "src/utils/getDirections";
import getPacking from "src/utils/stringHandlers/enumToStringHandlers/getPacking";
import getPropertyType from "src/utils/stringHandlers/enumToStringHandlers/getPropertyType";
import {getAddressIndexOff} from "src/utils/stringHandlers/getAddressIndexOff";
import getFixedToZeroDecimalNumber from "src/utils/stringHandlers/getFixedToZeroDecimalNumber";
import stringToFormatDate from "src/utils/stringHandlers/stringToFormatDate";
import {ErrorMessage} from "src/utils/types/errorMessage";
import {OrderSearch} from "src/utils/types/structures/orderSearch";
import useMapRoutes from "src/utils/zustandStores/mapMarkers";
import {SpinnerIcon} from "../../../../../../../media/icons/custom/spinnerIcon";

export type mapMarkers = {
    originMarker: React.ReactNode | null
    destinationMarker: React.ReactNode | null
}

interface Props {
    order: OrderSearch
}

export default function SearchOrderContent({order}: Props) {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [mapMarkers, setMapMarkers] = useState<mapMarkers | null>(null);
    const navigate = useNavigate();
    const map = useMap('order-search');
    const {setRoute} = useMapRoutes()
    const {close} = useSheet()

    const isOutside = useOnclickOutside(() => {
        close()
    });

    useEffect(() => {
        if (order && map) {
            if (order.originAddress && order.destinationAddress) {
                getDirections(order.originAddress, order.destinationAddress, map)
                    .then(value => {
                    if (value) {
                        setRoute(value.originCoordinates, value.destinationCoordinates, value.directionsResult, order.id)
                        setMapMarkers({
                            originMarker: <AdvancedMarker position={value.originCoordinates}>
                                <DotIcon size={10} className={'fill-marcoDarkGreen'}/>
                            </AdvancedMarker>,
                            destinationMarker: <AdvancedMarker position={value.destinationCoordinates}>
                                <SquareIcon size={10} className={'fill-marcoDarkGreen'}/>
                            </AdvancedMarker>,
                        })
                    }
                })
            }
        }
    }, [map, order]);

    if (!order) {
        return null
    }

    return <section ref={isOutside} className={'h-[calc(100dvh-4.25rem)] flex flex-col'}>
            <div className={'grow pt-5 px-6'}>
                <Map
                    className={'w-full h-52 rounded-xl'}
                    id={'order-search'}
                    mapId={mapId}
                    defaultCenter={{lat: 39.67096338359407, lng: -101.5601656163939}}
                    defaultZoom={4}
                    zoomControl={false}
                    gestureHandling={'cooperative'}
                    disableDefaultUI={true}>

                    {mapMarkers?.originMarker}
                    {mapMarkers?.destinationMarker}
                </Map>

                <section className={'flex flex-col gap-2 w-full pt-8 pb-4 border-b'}>
                    <div className={'flex flex-row items-center justify-between'}>
                        <h2 className={'text-[#717171] text-[14px] leading-[20px] font-medium'}>Estimate</h2>
                        <h2 className={'text-[#222222] text-[14px] leading-[20px] font-medium'}>{`Ref #${order.sid}`}</h2>
                    </div>

                    <p className={'text-[#222222] text-[26px] leading-[30px] font-semibold'}>${getFixedToZeroDecimalNumber(order.rate, 2)}</p>
                </section>

                <section key={'stops-origin'} className={'flex flex-col pt-4 gap-2'}>
                    <h2 className={'pb-2 text-[#222222] text-[18px] leading-[24px] font-semibold'}>Stops</h2>

                    <div className={'flex gap-2 items-center'}>
                        <div className={'flex-none place-self-center p-2'}>
                            <DotIcon size={8} className={'fill-foreground'}/>
                        </div>
                        <h3 className={'text-[#717171] text-[14px] leading-[20px] font-medium'}>Origin</h3>
                    </div>

                    <div className={'flex gap-2'}>
                        <div className={'w-[24px] grid'}>
                            <div className={'bg-foreground place-self-center h-full border-l border-foreground'}></div>
                        </div>

                        <div className={'flex flex-col gap-2'}>
                            <p className={'text-[#222222] text-[16px] leading-[24px] font-normal'}>{getAddressIndexOff(order.originAddress)}</p>
                            <p className={'text-[#222222] text-[16px] leading-[24px] font-normal'}>{getPropertyType(order.property)}</p>
                        </div>
                    </div>
                </section>

                <section key={'stops-destination'} className={'flex flex-col pt-4 gap-2'}>
                    <div className={'flex gap-2 items-center'}>
                        <div className={'flex-none place-self-center fill-foreground p-2'}>
                            <SquareIcon size={8} className={'fill-foreground'}/>
                        </div>

                        <h3 className={'text-[#717171] text-[14px] leading-[20px] font-normal'}>Destination</h3>
                    </div>

                    <div className={'flex gap-2'}>
                        <div className={'w-[24px] grid'}>

                        </div>

                        <div className={'flex flex-col gap-2'}>
                            <p className={'text-[#222222] text-[16px] leading-[24px] font-normal'}>{getAddressIndexOff(order.destinationAddress)}</p>
                            <p className={'text-[#222222] text-[16px] leading-[24px] font-normal'}>TBD</p>
                        </div>
                    </div>

                </section>

                <Separator className={'mt-8'}/>

                <div className={'py-[16px] flex flex-col gap-4'}>
                    <h2 className={'text-[#222222] text-[18px] leading-[24px] font-semibold'}>Details</h2>

                    <div className={'flex justify-between gap-4'}>
                        <div className={'flex-1 flex flex-col gap-1'}>
                            <p className={'text-[#717171] text-[14px] leading-[20px] font-medium'}>Pickup date</p>
                            <p className={'text-[#222222] text-[16px] leading-[24px] font-normal'}>{stringToFormatDate(order.pickupDate)}</p>
                        </div>

                        <div className={'flex-1 flex flex-col gap-1'}>
                            <p className={'text-[#717171] text-[14px] leading-[20px] font-medium'}>Service package</p>
                            <p className={'text-[#222222] text-[16px] leading-[24px] font-normal'}>{getPacking(order.packing)}</p>
                        </div>
                    </div>


                    <div className={'flex justify-between gap-4'}>
                        <div className={'flex-1 flex flex-col gap-1'}>
                            <p className={'text-[#717171] text-[14px] leading-[20px] font-medium'}>Volume</p>
                            <p className={'text-[#222222] text-[16px] leading-[24px] font-normal'}>{getFixedToZeroDecimalNumber(order.volume)} cu.ft</p>
                        </div>

                        <div className={'flex-1 flex flex-col gap-1'}>
                            <p className={'text-[#717171] text-[14px] leading-[20px] font-medium'}>Distance</p>
                            <p className={'text-[#222222] text-[16px] leading-[24px] font-normal'}>{getFixedToZeroDecimalNumber(order.distance)} mi</p>
                        </div>
                    </div>

                    <div className={'flex justify-between gap-4'}>
                        <div className={'flex-1 flex flex-col gap-1'}>
                            <p className={'text-[#717171] text-[14px] leading-[20px] font-medium'}>Est. toll costs</p>
                            <p className={'text-[#222222] text-[16px] leading-[24px] font-normal'}>${getFixedToZeroDecimalNumber(order.tolls, 2)}</p>
                        </div>

                        <div className={'flex-1 flex flex-col gap-1'}>
                            <p className={'text-[#717171] text-[14px] leading-[20px] font-medium'}>Est. fuel costs</p>
                            <p className={'text-[#222222] text-[16px] leading-[24px] font-normal'}>${getFixedToZeroDecimalNumber(order.fuel, 2)}</p>
                        </div>
                    </div>
                </div>
            </div>

        <section
            className={'flex-none sticky bottom-0 bg-white border-t z-40 flex justify-between py-4 px-6 gap-4'}>
            <Button size={'lg'}
                    variant={'outline'}
                    className={'w-full text-[#222222] text-[16px] leading-[20px] font-medium'}
                    onClick={close}>Close</Button>

            <Button size={'lg'}
                    disabled={isLoading}
                    className={'w-full text-white text-[16px] leading-[20px] font-medium relative'}
                    onClick={() => {
                        if (order) {
                            setLoading(true);
                            acceptOrder(order.id).then(value => {
                                if (isInstanceOf<ErrorMessage>(value, 'message')) {
                                    handleResponseError(value, () => navigate(routes.login));
                                } else {
                                    setLoading(false);
                                    close();
                                    navigate(`${routes.loads}/${order.id}/details`);
                                }
                            })
                        }
                    }}>
                <SpinnerIcon className={`${isLoading ? 'visible' : 'invisible'} fill-marcoWhite absolute`} size={20}/>
                <p className={`${isLoading ? 'invisible' : 'visible'} px-5 text-center text-white text-[16px] leading-[20px] font-medium`}>Book</p>
            </Button>
        </section>
    </section>
}