import React, {useState} from "react";
import {Tabs, TabsContent, TabsList} from "src/components/tabs";
import PaymentsTrigger
    from "src/pages/dashboarSection/layout/popUps/sheet/content/orderDetails/payments/paymentsTrigger";
import useSheet from "src/pages/dashboarSection/layout/popUps/sheet/useSheet";
import {OrderDetails} from "src/utils/types/structures/orderDetails";

export enum ActivitySection {
    totalEstPrice = 'TOTAL_EST_PRICE',
    proceedings = 'PROCEEDINGS',
}

interface Props {
    order: OrderDetails
}

export default function PaymentsContent({order}: Props) {
    const {close} = useSheet();
    const [currentActiveTab, setCurrentActiveTab] = useState<ActivitySection>(ActivitySection.totalEstPrice);

    return <section className={'h-[calc(100dvh-4.25rem)] flex flex-col'}>

            <Tabs value={currentActiveTab} className="grow w-full py-5 px-6">
                <TabsList
                    className={'flex gap-2 text-base font-medium rounded-none bg-opacity-0 justify-start px-0 pt-[1rem] pb-[1.125rem]'}>
                    <PaymentsTrigger value={ActivitySection.totalEstPrice} currentActive={currentActiveTab}
                                     setCurrentActive={setCurrentActiveTab} title={'Total est. price'}/>

                    <PaymentsTrigger value={ActivitySection.proceedings} currentActive={currentActiveTab}
                                     setCurrentActive={setCurrentActiveTab} title={'Proceedings'}/>
                </TabsList>

                <TabsContent value={ActivitySection.totalEstPrice}>Total estimate price content</TabsContent>
                <TabsContent value={ActivitySection.proceedings}>Proceedings content</TabsContent>
            </Tabs>

        <section key={'close-section'}
                 className={'sticky bottom-0 bg-background z-30 flex-none grid h-20 place-items-center border-t'}>
            <span onClick={close} className={'text-base font-medium underline leading-normal cursor-pointer'}>Close</span>
        </section>
    </section>
}