export enum ConfirmationLevel {
  confirmed = "CONFIRMED",
  plausible = "UNCONFIRMED_BUT_PLAUSIBLE",
}

export type AddressToValidateResponse200 = {
  result: {
    address: {
      addressComponents: [
        {
          // address lines
          componentType?: "route";
          confirmationLevel: ConfirmationLevel;
        },

        {
          componentType?: "locality";
          confirmationLevel: ConfirmationLevel;
        },

        {
          // state
          componentType?: "administrative_area_level_1";
          confirmationLevel: ConfirmationLevel;
        },

        {
          componentType?: "postal_code";
          confirmationLevel: ConfirmationLevel;
        },

        {
          componentType?: "country";
          confirmationLevel: ConfirmationLevel;
        },
      ];
      formattedAddress: string;
    };

    geocode: {
      location: {
        latitude: number;
        longitude: number;
      };
    };

    verdict: {
      hasUnconfirmedComponents: boolean;
    };
  };
};
