const routes = {
    main: '/',

    login: '/sign-in',
    signUp: '/sign-up',
    forgotPassword: '/forgot-password',
    registrationToken: '/registration',
    invite: '/invite',
    checkEmailRoute: '/check-email',
    thankYouScreen: '/thank-you-screen',

    companyDetails: '/company/company-details',
    companyPaymentsAndPayouts: '/company/payments',

    userPersonalDetails: '/account/details',

    search: '/search',
    loads: '/loads',
    fleetManagement: '/fleet',
    mobileAccountSettings: '/settings',

    onboardingSuccess: '/onboarding/success',

    // TBD
    calendar: '/calendar',
    inbox: '/inbox',
    CheckEmail: undefined
}

export default routes