import * as React from "react";
import {AccordionContent, AccordionItem, AccordionTrigger} from "src/components/accordion";
import {MinusCheckBox} from "src/components/minusCheckBox";
import {ShevronDown} from "src/media/icons/custom/shevron/shevronDown";
import DropImageCover from "src/pages/dashboarSection/orderDetails/sections/attachments/components/dropImageCover";
import EditableImageCover
    from "src/pages/dashboarSection/orderDetails/sections/attachments/components/editableImageCover";
import ImageSkeletonCover
    from "src/pages/dashboarSection/orderDetails/sections/attachments/components/imageSkeletonCover";
import {Colors} from "src/utils/defaults/colors";
import {defaultDimensions} from "src/utils/defaults/defaultDimensions";
import useOrderAttachments from "src/utils/zustandStores/orderDetailsAttachments/orderAttachments";
import useViewport from "src/utils/zustandStores/primary/useViewport";

export default function CarrierImages() {
    const {width} = useViewport()
    const {carrierImages, isAnySelected, setAllCarrierImagesUncheked, skeletons} = useOrderAttachments()

    return <AccordionItem className={'pb-4'} value="carrier">
        <AccordionTrigger className={'py-3 flex justify-between w-full'}>
            <div className={'flex flex-none gap-4 place-items-center'}>
                <h3 className={'text-lg text-foreground font-medium'}>Carrier</h3>
                {width >= defaultDimensions.md && <p className={'text-sm text-muted-foreground'}>.png, .jpg, .pdf, .mp4, .mov, etc</p>}
            </div>

            <div className={'flex gap-2 items-center'}>
                <div className={'text-sm text-foreground font-medium'}>
                    {isAnySelected
                        ? <div className={'flex gap-2 items-center'}>
                            <MinusCheckBox checked onClick={e => {
                                e.stopPropagation()
                                setAllCarrierImagesUncheked()
                            }}/>
                            {carrierImages.filter(el => el.isSelected).length + ' selected'}
                        </div>
                        : carrierImages.length + ' Uploaded'}</div>
                <ShevronDown width={10} height={6} stroke={Colors.foreground}/>
            </div>
        </AccordionTrigger>

        <AccordionContent className={'grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 gap-2 sm:gap-4'}>
            {carrierImages.map(el => <EditableImageCover key={el.id} orderImage={el}/>)}
            {skeletons.map(el => <ImageSkeletonCover key={el}/>)}
            <DropImageCover/>
        </AccordionContent>
    </AccordionItem>
}