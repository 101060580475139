import * as z from "zod";

const registrationSchema = z.object({
    firstName: z.string(),
    lastName: z.string(),
    companyName: z.string(),
    email: z.string(),
    phoneNumber: z.string(),
    companySize: z.string(),
    currentService: z.string(),
    serviceArea: z.string(),
    DOT: z.string(),
    MC: z.string(),
})

export default registrationSchema