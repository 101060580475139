import {AdvancedMarker, Map, useMap} from "@vis.gl/react-google-maps";
import React, {useEffect, useState} from "react";
import {DotIcon} from "src/media/icons/custom/dotIcon";
import {SquareIcon} from "src/media/icons/custom/squareIcon";
import {mapMarkers} from "src/pages/dashboarSection/layout/popUps/sheet/content/orderSearch/searchOrderContent";
import getDirections from "src/utils/getDirections";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import useMapRoutes from "src/utils/zustandStores/mapMarkers";

export interface AdvancedMarkerBody {
    lat: number
    lng: number
}

interface Props {
    order: OrderDetails
}

export default function MapSection({order}: Props) {

    const [id, setId] = useState<string>('order-details');
    const map = useMap(id);
    const {route} = useMapRoutes()
    const [mapMarkers, setMapMarkers] = useState<mapMarkers | null>(null)

    useEffect(() => {
        setId(prevId => prevId + 1)
    }, [order]);

    useEffect(() => {
        if (route?.orderId === order.id) {
            const directionsRenderer = new google.maps.DirectionsRenderer({suppressMarkers: true});
            directionsRenderer.setMap(map);
            directionsRenderer.setDirections(route.directionsResult);
            directionsRenderer.setOptions({
                polylineOptions:
                    {
                        strokeColor: '#00442E',
                        strokeOpacity: 0.8,
                        strokeWeight: 2
                    }
            })
            setMapMarkers({
                originMarker: <AdvancedMarker position={route.originCoordinates}>
                    <DotIcon size={10} className={'fill-marcoDarkGreen'}/>
                </AdvancedMarker>,
                destinationMarker: <AdvancedMarker position={route.destinationCoordinates}>
                    <SquareIcon size={10} className={'fill-marcoDarkGreen'}/>
                </AdvancedMarker>,
            })
        } else {
            if (order.originAddress?.address && order.destinationAddress?.address) {
                getDirections(order.originAddress.address, order.destinationAddress.address, map)
                    .then(value => {
                        if (value) {
                            setMapMarkers({
                                originMarker: <AdvancedMarker position={value.originCoordinates}>
                                    <DotIcon size={10} className={'fill-marcoDarkGreen'}/>
                                </AdvancedMarker>,
                                destinationMarker: <AdvancedMarker position={value.destinationCoordinates}>
                                    <SquareIcon size={10} className={'fill-marcoDarkGreen'}/>
                                </AdvancedMarker>,
                            })
                        }
                    })
            }
        }
    }, [map, order]);


    return <section key={'map'} className={'lg:h-[calc(100vh-242px)] lg:sticky lg:top-6 lg:pb-6'}>
        <Map
            className={'w-full rounded-md h-[280px] md:h-[400px] lg:h-full'}
            id={id}
            mapId={'3999fcb295fa34f1'}
            zoomControl={false}
            defaultCenter={{lat: 39.67096338359407, lng: -101.5601656163939}}
            defaultZoom={4}
            gestureHandling={'cooperative'}
            disableDefaultUI={true}
        >
            {mapMarkers?.originMarker}

            {mapMarkers?.destinationMarker
            }
        </Map>
    </section>
}