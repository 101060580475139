import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "src/components/table";
import {defaultDimensions} from "src/utils/defaults/defaultDimensions";
import getSimpleString from "src/utils/stringHandlers/getSimpleString";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import useOrderInventory, {ExtendedOrderItem} from "src/utils/zustandStores/orderDetailsInventory/orderInventory";
import getExtendedItemDescription from "src/utils/zustandStores/orderDetailsInventory/utils/getExtendedItemDescription";
import getExtendedItemVolumeAndWeight
    from "src/utils/zustandStores/orderDetailsInventory/utils/getExtendedItemVolumeAndWeight";
import useViewport from "src/utils/zustandStores/primary/useViewport";
import {Popover, PopoverContent, PopoverTrigger} from "../../../../../../components/popOver";
import {PopoverClose} from "@radix-ui/react-popover";
import React from "react";
import {Button} from "../../../../../../components/button";
import {ThreeDotsVertical} from "../../../../../../media/icons/custom/threeDotsVertical";
import {Colors} from "../../../../../../utils/defaults/colors";
import {isInstanceOf} from "../../../../../../utils/errorHandlers/isInstanceOf";
import {ErrorMessage} from "../../../../../../utils/types/errorMessage";
import {handleResponseError} from "../../../../../../utils/errorHandlers/handleResponseError";
import {deleteOrderCustomItems} from "../../../../../../utils/apiCalls/orderDetails/customItems/deleteOrderCustomItems";
import {OrderItem} from "../../../../../../utils/types/structures/orderItem";
import {CustomItem} from "../../../../../../utils/types/structures/customItem";
import {getOrderItems} from "../../../../../../utils/apiCalls/orderDetails/orderItems/getOrderItems";
import {getOrderCustomItems} from "../../../../../../utils/apiCalls/orderDetails/customItems/getOrderCustomItems";
import {deleteOrderItems} from "../../../../../../utils/apiCalls/orderDetails/orderItems/deleteOrderItems";
import {getOrder} from "../../../../../../utils/apiCalls/orderDetails/getOrder";

interface Props {
    order: OrderDetails
    setOrder: React.Dispatch<React.SetStateAction<OrderDetails | undefined>>
}

export default function InvetoryTableLongRow({setOrder, order}: Props) {
    const {items, previousExtendedItems, initExtendedItems} = useOrderInventory();
    const {width} = useViewport()

    const onDeleteHandle = async (extendedItem: ExtendedOrderItem) => {
        if (isInstanceOf<CustomItem>(extendedItem.item, 'description')) await deleteOrderCustomItems(order.id, [extendedItem.item.id])
        else await deleteOrderItems(order.id, [extendedItem.item.id])

        let currentOrderItems: OrderItem[] = []
        let currentCustomItems: CustomItem[] = []

        const orderItems = await getOrderItems(order.id);
        if (isInstanceOf<ErrorMessage>(orderItems, 'message')) {
            handleResponseError(orderItems)
        } else currentOrderItems = orderItems

        const customItems = await getOrderCustomItems(order.id);
        if (isInstanceOf<ErrorMessage>(customItems, 'message')) {
            handleResponseError(customItems)
        } else currentCustomItems = customItems

        initExtendedItems(currentOrderItems, currentCustomItems)
        const res = await getOrder(order.id)
        if (isInstanceOf<ErrorMessage>(res, 'messsage')) {
            handleResponseError(res)
        } else setOrder(res)
    }

    return <Table>
        <TableHeader>
            <TableRow>
                <TableHead>Item name</TableHead>
                <TableHead>Qty</TableHead>
                <TableHead className={'hidden sm:grid items-center'}>Notes</TableHead>
                <TableHead>Volume</TableHead>
                {width >= defaultDimensions.sm && <TableHead>Weight</TableHead>}
                <TableHead className={'w-12'}></TableHead>
            </TableRow>
        </TableHeader>
        <TableBody>
            {previousExtendedItems.map(el => <TableRow key={el.name}>
                <TableCell>{el.name}</TableCell>
                <TableCell>{getSimpleString(el.item.count)}</TableCell>
                <TableCell className={'hidden sm:block break-all text-sm text-foreground'}>
                    <div className={'break-all text-sm text-foreground h-full flex items-center'}>
                        {getExtendedItemDescription(el.item, items)}
                    </div>
                </TableCell>
                <TableCell>{getExtendedItemVolumeAndWeight(el.item, items).volume} cu.ft</TableCell>
                {width >= defaultDimensions.sm &&
                    <TableCell>{getExtendedItemVolumeAndWeight(el.item, items).weight} lbs</TableCell>}
                <TableCell className={'p-0 w-12 min-w-12 items-center'}>
                    <Popover>
                        <PopoverTrigger asChild>
                            <Button variant={'ghost'} size={'iconFit'} className={'place-self-center p-[0.375rem]'}>
                                <ThreeDotsVertical size={20} stroke={Colors.foreground}/>
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent align={'end'} className="w-[124px] h-fit mt-1 p-[5px]">
                            <PopoverClose className={'w-full text-start'}>
                                <div onClick={async () => await onDeleteHandle(el)}
                                     className={'p-2 rounded-[6px] hover:bg-[#F5F5F5] w-full text-[#222222] text-[14px] leading-[20px] font-medium'}>Delete
                                </div>
                            </PopoverClose>
                        </PopoverContent>
                    </Popover>
                </TableCell>
            </TableRow>)}
        </TableBody>
    </Table>
}