import React, {useEffect, useState} from "react";
import {Checkbox} from "src/components/checkBox";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "src/components/table";
import getRole from "src/utils/stringHandlers/enumToStringHandlers/getRole";
import getString, {ElementTag} from "src/utils/stringHandlers/getString";
import getMemberStatus from "src/utils/stringHandlers/orderDetailsStrings/getMemberStatus";
import {Member} from "src/utils/types/structures/member";

interface Props {
    currentDriverId: string | null | undefined
    setCurrentDriverId: React.Dispatch<React.SetStateAction<string | null | undefined>>
    companyMemberData: Member[] | undefined
}

type ExtendedMember = {
    isCheked: boolean,
    member: Member
}

export default function CrewContent({companyMemberData, currentDriverId, setCurrentDriverId}: Props) {
    const [extendedMembers, setExtendedMembers] = useState<ExtendedMember[]>([])

    useEffect(() => {
        if (companyMemberData) {
            if (currentDriverId) {
                const extendedMembers = companyMemberData?.map(el => {
                    if (el.id === currentDriverId) {
                        const extendedMember: ExtendedMember = {
                            member: el,
                            isCheked: true
                        }
                        return extendedMember
                    } else {
                        const extendedMember: ExtendedMember = {
                            member: el,
                            isCheked: false
                        }
                        return extendedMember
                    }
                })
                setExtendedMembers(extendedMembers)

            } else {
                const extendedMembers = companyMemberData?.map(el => {
                    const extendedMember: ExtendedMember = {
                        member: el,
                        isCheked: false
                    }
                    return extendedMember
                })
                setExtendedMembers(extendedMembers)
            }
        }
    }, [companyMemberData, currentDriverId]);

    function checkMember(member: ExtendedMember) {
        const updatedMembers = extendedMembers.map(el => {
            if (el.member.id === member.member.id) {
                const updatedMember: ExtendedMember = {
                    ...member,
                    isCheked: !member.isCheked
                }

                if (el.isCheked) {
                    setCurrentDriverId(null)

                } else {
                    setCurrentDriverId(el.member.id)
                }
                return updatedMember
            } else {
                const member: ExtendedMember = {
                    ...el,
                    isCheked: false
                }
                return member
            }
        })

        setExtendedMembers(updatedMembers)
    }


    return <Table>
            <TableHeader>
                <TableRow>
                    <TableHead></TableHead>
                    <TableHead>Name</TableHead>
                    <TableHead>Role</TableHead>
                    <TableHead>Status</TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {extendedMembers.map(el => <TableRow key={el.member.id}>
                    <TableCell className={'grid items-center place-content-center'}>
                        <Checkbox checked={el.isCheked} onCheckedChange={() => checkMember(el)}/>
                    </TableCell>
                    <TableCell>{getString([
                        {element: el.member.firstName, tag: ElementTag.name},
                        {element: el.member.lastName, tag: ElementTag.name},
                    ])}</TableCell>
                    <TableCell>{getRole(el.member.role)}</TableCell>
                    <TableCell>{getMemberStatus(el.member.active)}</TableCell>
                </TableRow>)}
            </TableBody>
        </Table>
}