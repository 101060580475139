import React from "react";
import {Button} from "../../../components/button";

export default function ThankYouScreen() {

    return <div className={'flex flex-col gap-0'}>
        <h1 className={'font-inter text-[#222222] text-[24px] leading-[32px] font-medium pb-2'}>
            Thank you for your interest in partnering with Marco.
        </h1>

        <p className={'font-inter text-[#717171] text-[14px] leading-[20px] font-normal pb-8'}>We look forward to
            exploring potential collaboration. Your submission has been received, and we’ll be in touch soon.</p>

        <a href={'https://hellomarco.com/our-mission'}>
            <Button
                variant={'lightGreen'}
                className={'h-[48px] text-[16px] leading-[20px] w-full'}>Learn about our mission</Button>
        </a>


    </div>
}