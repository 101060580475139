import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {CircleStencil, FixedCropper, FixedCropperRef, ImageRestriction} from "react-advanced-cropper";
import 'react-advanced-cropper/dist/style.css';
import {useNavigate} from "react-router-dom";
import {Button} from "src/components/button";
import useDialog from "src/pages/dashboarSection/layout/popUps/dialog/useDialog";
import {postAvatar} from "src/utils/apiCalls/postAvatar";
import routes from "src/utils/defaults/routes";
import {deleteAvatar} from "src/utils/deleteAvatar";
import {handleResponseError} from "src/utils/errorHandlers/handleResponseError";
import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import getString, {ElementTag} from "src/utils/stringHandlers/getString";
import {ErrorMessage} from "src/utils/types/errorMessage";
import {Member} from "src/utils/types/structures/member";
import useUserHeader from "src/utils/zustandStores/userHeader/useUserHeader";
import {SpinnerIcon} from "../../../../../../media/icons/custom/spinnerIcon";

interface Image {
    type?: string;
    src: string;
}

interface Props {
    user: Member
}

export default function ShowAvatar({user}: Props) {
    const navigate = useNavigate()
    const [inputKey, setInputKey] = useState<string>(Math.random().toString(36));
    const {isOpen, close, mutateFirstSource} = useDialog();
    const {setUser} = useUserHeader();
    const [isLoading, setLoading] = useState<boolean>(false)
    const [image, setImage] = useState<Image | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const cropperRef = useRef<FixedCropperRef>(null);

    const onChangePhotoHandle = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    }

    const onLoadImage = (event: ChangeEvent<HTMLInputElement>) => {
        const {files} = event.target;
        if (files && files[0]) {
            const blob = URL.createObjectURL(files[0]);
            setImage({
                src: blob,
                type: files[0].type
            })
        }
        event.target.value = '';
    };

    const onCrop = async (cropper: FixedCropperRef) => {
        setLoading(true)
        if (cropper) {
            const result = cropper.getCanvas({
                height: 480,
                width: 480,
            })?.toDataURL()
            if (result) {
                const res = await postAvatar(result)
                if (isInstanceOf<ErrorMessage>(res, 'message')) {
                    handleResponseError(res, () => navigate(routes.login))
                } else {
                    if (mutateFirstSource) {
                        mutateFirstSource()
                        setUser(res)
                        close()
                    }
                }
            }
        }
        setLoading(false)
    };

    useEffect(() => {
        return () => {
            if (image && image.src) {
                URL.revokeObjectURL(image.src);
            }
        };
    }, [image]);

    const onDeletePhotoHandle = async () => {
        const res = await deleteAvatar()
        if (isInstanceOf<ErrorMessage>(res, 'message')) {
            handleResponseError(res, () => navigate(routes.login))
        } else {
            if (mutateFirstSource) {
                mutateFirstSource()
                setUser(res)
                close()
            }
        }
    }

    useEffect(() => {
        if (!isOpen) {
            setImage(null)
            setInputKey(Math.random().toString(36))
        }
    }, [isOpen]);

    return <div className={'flex flex-col w-full h-fit'}>

        <p className={'flex-none text-muted-foreground px-6 text-sm font-normal leading-tight'}>Image must be in jpg or png file format. File size must be less than 2MB.</p>

        <section className={'flex-1 h-fit w-full grid place-items-center pt-4 pb-6'}>
            {!image ? <div className={'h-[368px] w-[382px] md:h-[468px] md:w-[488px]'}>
                    {(user.avatar && user.avatar !== '')
                        ? <div className={'h-[368px] w-[382px] md:h-[468px] md:w-[488px]'}>
                            <img
                                className={'object-cover border rounded-xl h-full w-full'}
                                src={user.avatar}
                                alt={'img'}
                            />
                        </div>
                        : <div
                            className={'bg-[#E7E7E7] h-[368px] w-[382px] md:h-[468px] md:w-[488px] grid place-items-center border rounded-xl text-[1.25rem] leading-[1.625rem]'}>
                            {getString([
                                {element: user.firstName, tag: ElementTag.name},
                                {element: user.lastName, tag: ElementTag.name},
                            ])}
                        </div>}
                </div>

                : <div className={'h-[368px] w-[382px] md:h-[468px] md:w-[488px]'}>
                    <FixedCropper
                        ref={cropperRef} className={'w-full h-full rounded-xl'}
                        src={image && image.src}
                        stencilSize={{
                            width: 280,
                            height: 280
                        }}
                        stencilComponent={CircleStencil}
                        stencilProps={{
                            handlers: false,
                            lines: true,
                            movable: false,
                            resizable: false,
                            grid: true
                        }}
                        imageRestriction={ImageRestriction.none}/>
                </div>
            }
        </section>

        <section className={'flex-none border-t'}>
            <input className={"hidden"}
                   key={inputKey}
                   name={"files"}
                   multiple={false}
                   id={"avatarInput"}
                   ref={inputRef}
                   type="file"
                   accept="image/*"
                   onChange={onLoadImage}/>
            {
                !image
                    ? <div className={'flex justify-between px-6 py-4'}>
                        <Button variant={'ghost'} size={'lg'} className={'text-base font-medium leading-tight'}
                                onClick={onDeletePhotoHandle}>Delete</Button>
                        <Button onClick={onChangePhotoHandle} size={'lg'} className={'text-base font-medium leading-tight'}>Change
                            photo</Button>
                    </div>

                    : <div className={'flex justify-between px-6 py-4'}>
                        <Button variant={'ghost'} size={'lg'} onClick={close}
                                className={'text-base font-medium leading-tight'}>Cancel</Button>
                        <Button onClick={() => cropperRef.current && onCrop(cropperRef.current)} size={'lg'}
                                className={'text-base font-medium leading-tight relative'}>
                            <SpinnerIcon className={`${isLoading ? 'visible' : 'invisible'} fill-marcoWhite absolute`} size={20}/>
                            <p className={`${isLoading ? 'invisible' : 'visible'} px-5 text-center text-white text-[16px] leading-[20px] font-medium`}>Save</p>
                        </Button>
                    </div>
            }
        </section>
    </div>
}