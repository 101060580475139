import React from "react";
import {ScrollArea, ScrollBar} from "src/components/scrollArea";
import Tabs from "src/pages/dashboarSection/orderDetails/buttons/buttonLayout/tabs";
import {OrderDetailsTabSections} from "src/pages/dashboarSection/orderDetails/orderDetailsLayout";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import MoreActionsHandler from "../buttonMoreActionsHandle/moreActionsHandler";
import ButtonHandler from "../buttonStatusHandle/buttonHandler";
import {getStateAcronym} from "../../../../../utils/getStateAcronym";
import OrderFulfillmentStatusBadge from "../../../../../components/orderFulfillmentStatusBadge";
import dayjs from "dayjs";
import {appointmentTimeToDateTime} from "../../../../../utils/stringHandlers/appontmentTimeToDateTime";

interface Props {
    order: OrderDetails
    currentActiveTab: OrderDetailsTabSections
    setOrder: React.Dispatch<React.SetStateAction<OrderDetails | undefined>>
}

export default function DesktopDetailsHeader({order, currentActiveTab, setOrder}: Props) {
    return <section className={` flex-none px-padding pt-8  md:shadow-none pb-6 bg-background z-30`}>


        <section className={'flex flex-col gap-0'}>
            <div key={`header`} className={`flex flex-row items-center justify-between gap-4`}>
                <section className={'flex-1'}>
                    <div key={'order-directions'} className={'flex flex-col border-b pb-6 md:pb-0 md:border-none'}>
                        <section
                            className={'flex flex-row flex-wrap w-full pb-3 md:pb-1 gap-x-4 gap-y-2 items-center justify-between md:justify-normal'}>
                            <section className={'flex flex-row gap-2 items-center'}>
                                <p className={'text-[#222222] text-[20px] leading-[28px] lg:text-[24px] lg:leading-[32px] font-medium'}> {order.originAddress?.locality}, {getStateAcronym(order.originAddress?.state ?? '')}</p>

                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.1665 10H15.8332" stroke="#222222" stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"/>
                                    <path d="M10 4.16602L15.8333 9.99935L10 15.8327" stroke="#222222" stroke-width="1.5"
                                          stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>

                                <p className={'text-[#222222] text-[20px] leading-[28px] lg:text-[24px] lg:leading-[32px] font-medium'}> {order.destinationAddress?.locality}, {getStateAcronym(order.destinationAddress?.state ?? '')}</p>
                            </section>

                            <OrderFulfillmentStatusBadge order={order}/>
                        </section>
                    </div>
                </section>


                <section className={'flex-none flex flex-row gap-4'}>
                    <ButtonHandler currentActiveTab={currentActiveTab} order={order} setOrder={setOrder}/>
                    <MoreActionsHandler order={order}/>
                </section>
            </div>

            <section className={'flex flex-col gap-1 md:flex-row'}>
                <p className={'text-[#717171] text-[16px] leading-[24px] font-normal'}>Scheduled pickup:</p>
                <p className={'text-[#717171] text-[16px] leading-[24px] font-normal'}>{dayjs(order.pickupDate).format('dddd, MMMM MM')}, {appointmentTimeToDateTime(order.appointmentTime)}</p>
            </section>

        </section>


        <ScrollArea className={"w-[95vw] sm:w-full pt-4"}>
            <Tabs currentActiveTab={currentActiveTab} order={order}/>
            <ScrollBar orientation="horizontal" className={'hidden'}/>
        </ScrollArea>
    </section>
}