import React, {useEffect, useState} from "react";
import {BlobType} from "src/utils/enums/blobType";
import getExtensionType from "src/utils/stringHandlers/images/getExtensionType";
import useOrderAttachments, {
    ExtendedOrderImage
} from "src/utils/zustandStores/orderDetailsAttachments/orderAttachments";
import imageCover from '../../../../../../media/imageCover.png'
import {EnlargeIcon} from "../../../../../../media/icons/custom/enlargeIcon";

interface Props {
    orderImage: ExtendedOrderImage
}
export default function UneditableImageCover({orderImage}: Props) {
    const [isHovered, setHovered] = useState<boolean>(false);
    const {setImageViewerOpen} = useOrderAttachments()
    const [blobType, setBlobType] = useState<BlobType | null>(null)

    useEffect(() => {
        setBlobType(null)
        if (orderImage) {
            const extension = getExtensionType(orderImage)
            setBlobType(extension)
        }
    }, [orderImage]);

    return <div
        className={`relative w-full aspect-[4/4.4]`}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}>

        <div
            className={`absolute h-full w-full flex content-center}`}>
            {(blobType && blobType === BlobType.image) &&  <img
                className={`rounded-xl h-full w-full object-cover`}
                src={orderImage.image.url}
                alt={'img'}/>}

            {(blobType && blobType === BlobType.video) && <video
                className={`rounded-xl h-full w-full object-cover`}
                preload="metadata"
                src={`${orderImage.image.url}#t=0.1`}
            />}

            {(blobType && blobType === BlobType.pdf) && <img
                className={`rounded-xl h-full w-full object-cover`}
                src={imageCover}
                alt={'img'}/>}
        </div>

        <div
            data-state={isHovered}
            className={"data-[state=false]:hidden data-[state=true]:absolute cursor-pointer absolute w-full h-full grid grid-cols-1 shadow-md bg-black bg-opacity-50 rounded-xl"}>
            <div
                className={"absolute cursor-pointer place-self-center"}
                onClick={(e) => {
                    setImageViewerOpen(orderImage)
                    e.stopPropagation()
                }}>
                <EnlargeIcon size={24} className={'fill-marcoWhite'}/>
            </div>
        </div>
    </div>
}