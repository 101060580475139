import * as React from "react";
import InvetoryTableLongRow
    from "src/pages/dashboarSection/orderDetails/sections/inventory/components/invetoryTableLongRow";
import getNumberOffNull from "src/utils/stringHandlers/getNumberOffNull";
import getItemsCount from "src/utils/stringHandlers/inventory/getItemsCount";
import {OrderDetails} from "src/utils/types/structures/orderDetails";

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    order: OrderDetails
    setOrder: React.Dispatch<React.SetStateAction<OrderDetails | undefined>>
}

export default function InventoryContent({order, setOrder, ...props}: Props) {
    return <div {...props}>
        <section key={'sub-header flex flex-col p-0'}>
            <div className={'flex flex-row items-center justify-between'}>
                <h3 className={'flex-1 text-[#222222] text-[18px] leading-[24px] font-medium'}>Summary</h3>

                <ul className={'flex-none w-[200px] flex flex-row items-center gap-6 justify-between'}>
                    <li key={'items'} className={'flex-1 flex flex-col gap-1'}>
                        <p className={'text-[#717171] text-[14px] leading-[20px] font-medium'}>Quantity</p>
                        <p className={'text-[#222222] text-[16px] leading-[20px] font-medium'}>{getItemsCount()}</p>
                    </li>


                    <li key={'volume'} className={'flex-1 flex flex-col gap-1'}>
                        <p className={'text-[#717171] text-[14px] leading-[20px] font-medium'}>Volume</p>
                        <p className={'text-[#222222] text-[16px] leading-[20px] font-medium'}>{getNumberOffNull(order.volume)} cu.ft</p>
                    </li>
                </ul>
            </div>
        </section>

        <section key={'sub-table'}>
            <InvetoryTableLongRow order={order} setOrder={setOrder}/>
        </section>
    </div>
}