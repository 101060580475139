import {motion} from "framer-motion";
import * as React from "react"
import {useState} from "react"

import {cn} from "../utils/cn/cnHelper";
import {Button} from "./button";

export interface InputProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    isInvalid?: boolean
    label: string
    isInputOn: boolean
}

const PasswordInputOffLabel = React.forwardRef<HTMLInputElement, InputProps>(
    ({className, label, value, isInvalid, isInputOn, ...props}, ref) => {
        const [showPassword, setShowPassword] = useState(false)
        const disabled = value === "" || value === undefined || props.disabled

        return (
            <motion.div
                animate={isInvalid ? {outlineStyle: 'solid', outlineWidth: '1px', outlineColor: '#C6241D', backgroundColor: 'rgba(216,44,13,0.2)'} : {outlineStyle: 'solid', outlineWidth: '1px', outlineColor: '#dddddd'}}
                transition={{duration: 0.1}}
                className={'group w-full flex flex-col relative h-[56px] rounded-[6px] [z-3]'}>
                <input
                    autoComplete={'off'}
                    type={showPassword ? "text" : "password"}
                    className={cn(
                        `z-[2] flex w-full bg-transparent h-full ${isInvalid ? 'group-focus-within:outline group-focus-within:outline-2 group-focus-within:outline-[#C6241D]' : 'group-focus-within:outline group-focus-within:outline-2 group-focus-within:outline-[#222222]'} rounded-[6px] font-inter ${isInvalid ? '!text-[#C6241D]' : 'text-[#222222]'} text-[16px] leading-[20px] font-normal ${isInputOn && 'pb-2 pt-6'} px-4`,
                        className
                    )}
                    ref={ref}
                    {...props}
                />

                <Button
                    type="button"
                    variant="ghost"
                    size="sm"
                    className="absolute right-0 top-0 h-full px-3 z-[4] hover:bg-transparent"
                    onClick={() => setShowPassword((prev) => !prev)}
                    disabled={disabled}
                >
                    {showPassword && !disabled ? (
                        <motion.svg
                            animate={isInvalid ? {stroke: '#C6241D'} : {stroke: 'currentColor'}}
                            transition={{duration: 0.1}}
                            xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                            strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                             className="lucide lucide-eye">
                            <path
                                d="M2.062 12.348a1 1 0 0 1 0-.696 10.75 10.75 0 0 1 19.876 0 1 1 0 0 1 0 .696 10.75 10.75 0 0 1-19.876 0"/>
                            <circle cx="12" cy="12" r="3"/>
                        </motion.svg>

                    ) : (
                        <motion.svg
                            animate={isInvalid ? {stroke: '#C6241D'} : {stroke: 'currentColor'}}
                            transition={{duration: 0.1}}
                            xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                            fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                            strokeLinejoin="round" className="lucide lucide-eye-off">
                            <path
                                d="M10.733 5.076a10.744 10.744 0 0 1 11.205 6.575 1 1 0 0 1 0 .696 10.747 10.747 0 0 1-1.444 2.49"/>
                            <path d="M14.084 14.158a3 3 0 0 1-4.242-4.242"/>
                            <path
                                d="M17.479 17.499a10.75 10.75 0 0 1-15.417-5.151 1 1 0 0 1 0-.696 10.75 10.75 0 0 1 4.446-5.143"/>
                            <path d="m2 2 20 20"/>
                        </motion.svg>

                    )}
                    <span className="sr-only">
						{showPassword ? "Hide password" : "Show password"}
					</span>
                </Button>

                {/* hides browsers password toggles */}
                <style>{`
					.hide-password-toggle::-ms-reveal,
					.hide-password-toggle::-ms-clear {
						visibility: hidden;
						pointer-events: none;
						display: none;
					}
				`}</style>

                <motion.span
                    initial={{fontSize: '16px', lineHeight: '20px', fontWeight: 400}}
                    transition={{duration: 0.1}}
                    animate={isInputOn ? {
                        fontSize: '12px',
                        translateY: -10,
                        lineHeight: '16px',
                        fontWeight: 400
                    } : {fontSize: '16px', lineHeight: '20px', fontWeight: 400}}
                    className={`z-0 absolute ${isInvalid ? 'text-[#C6241D]' : 'text-[#6a6a6a]'} h-full content-center w-full px-4`}>{label}</motion.span>
            </motion.div>

        )
    }
)
PasswordInputOffLabel.displayName = "Input"

export {PasswordInputOffLabel}
