import {PopoverClose} from "@radix-ui/react-popover";
import React from "react";
import {Button} from "src/components/button";
import {Popover, PopoverContent, PopoverTrigger} from "src/components/popOver";
import {ShevronDown} from "src/media/icons/custom/shevron/shevronDown";
import SortFilterContent from "src/pages/dashboarSection/search/searchLayout/filters/sortFilterContent";
import {Colors} from "src/utils/defaults/colors";
import useSearch from "src/utils/zustandStores/useSearch";

export default function SortOrdersFilter() {
    const {response, setSortFilterValue} = useSearch();

    return <Popover>
        <PopoverTrigger asChild>
            <Button
                id="date"
                variant={"rounded"}
                size={"tab"}
                className={'w-fit px-3 rounded-full py-4 text-sm font-inter font-medium text-foreground'}
            >
                <div className={'flex justify-between w-full place-items-center gap-2'}>
                    <p className={'text-foreground text-[14px] leading-[20px] font-medium'}>Sort: {response.body.sortFilterValue[0] + response.body.sortFilterValue.slice(1).toLowerCase()}</p>
                    <ShevronDown width={10} height={6} stroke={Colors.foreground}/>
                </div>

            </Button>
        </PopoverTrigger>

        <PopoverContent className="p-0 w-fit" align="start">
            <PopoverClose className={'text-start p-[5px] cursor-default'}>
                <SortFilterContent/>
            </PopoverClose>
        </PopoverContent>
    </Popover>
}