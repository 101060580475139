import React from "react";
import {Avatar, AvatarFallback, AvatarImage} from "src/components/avatar";
import useDialog, {DialogElementType} from "src/pages/dashboarSection/layout/popUps/dialog/useDialog";
import {getFirstLetters} from "src/utils/stringHandlers/getFirstLetters";
import getString, {ElementTag} from "src/utils/stringHandlers/getString";
import {OrderDetails} from "src/utils/types/structures/orderDetails";

interface Props {
    order: OrderDetails
}
export default function CustomerInfoSection({order}: Props) {
    const {open} = useDialog()

    const openDialog = () => {
        open(DialogElementType.contactDetails, order, 'Contact the Customer')
    }


    return <section key={'customer-info'} className={'flex flex-col gap-4 border-b py-6 md:pt-0'}>
        <h2 className={'text-[#222222] text-[18px] leading-[24px] font-semibold'}>Customer info</h2>

        <div className={'flex flex-1 items-center gap-4'}>
            <div className={'flex-1 flex gap-2 items-center'}>
                <Avatar className={'flex-none'}>
                    <AvatarImage
                        src={undefined}/>
                    <AvatarFallback
                        className={'text-sm'}>{getFirstLetters(order.customerFirstName ?? '', order.customerLastName ?? '')}</AvatarFallback>
                </Avatar>

                <div className={'flex-1 text-base font-normal leading-6'}>
                    {getString([
                        {element: order.customerFirstName ?? '', tag: ElementTag.name},
                        {element: order.customerLastName ?? '', tag: ElementTag.name},
                    ])}
                </div>
            </div>

            <div className={'flex-none flex gap-4'}>
                <button onClick={openDialog} className={'w-10 h-10 bg-[#f7f7f7] rounded-full grid items-center place-content-center'}>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_17959_54428)">
                            <path
                                d="M16.5001 12.6901V14.9401C16.5009 15.1489 16.4581 15.3557 16.3745 15.5471C16.2908 15.7385 16.168 15.9103 16.0141 16.0515C15.8602 16.1927 15.6785 16.3002 15.4806 16.3671C15.2828 16.434 15.0731 16.4589 14.8651 16.4401C12.5572 16.1893 10.3403 15.4007 8.39257 14.1376C6.58044 12.9861 5.04407 11.4497 3.89257 9.63757C2.62506 7.68098 1.83625 5.45332 1.59007 3.13507C1.57133 2.92767 1.59598 2.71864 1.66245 2.52129C1.72892 2.32394 1.83575 2.14259 1.97615 1.98879C2.11654 1.83499 2.28743 1.7121 2.47792 1.62796C2.6684 1.54382 2.87433 1.50027 3.08257 1.50007H5.33257C5.69655 1.49649 6.04942 1.62538 6.32539 1.86272C6.60137 2.10006 6.78163 2.42966 6.83257 2.79007C6.92754 3.51012 7.10366 4.21712 7.35757 4.89757C7.45848 5.16602 7.48032 5.45776 7.4205 5.73823C7.36069 6.01871 7.22172 6.27616 7.02007 6.48007L6.06757 7.43257C7.13524 9.31023 8.68991 10.8649 10.5676 11.9326L11.5201 10.9801C11.724 10.7784 11.9814 10.6395 12.2619 10.5796C12.5424 10.5198 12.8341 10.5417 13.1026 10.6426C13.783 10.8965 14.49 11.0726 15.2101 11.1676C15.5744 11.219 15.9071 11.4025 16.145 11.6832C16.3828 11.9639 16.5092 12.3223 16.5001 12.6901Z"
                                stroke="#222222" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_17959_54428">
                                <rect width="18" height="18" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                </button>

                <button onClick={openDialog}
                        className={'w-10 h-10 bg-[#f7f7f7] rounded-full grid items-center place-content-center'}>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M15.75 11.25C15.75 11.6478 15.592 12.0294 15.3107 12.3107C15.0294 12.592 14.6478 12.75 14.25 12.75H5.25L2.25 15.75V3.75C2.25 3.35218 2.40804 2.97064 2.68934 2.68934C2.97064 2.40804 3.35218 2.25 3.75 2.25H14.25C14.6478 2.25 15.0294 2.40804 15.3107 2.68934C15.592 2.97064 15.75 3.35218 15.75 3.75V11.25Z"
                            stroke="#222222" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
            </div>
        </div>
    </section>
}