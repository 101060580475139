import React, {useState} from "react";
import {Tabs, TabsContent, TabsList} from "src/components/tabs";
import CustomerNotes
    from "src/pages/dashboarSection/layout/popUps/sheet/content/orderDetails/notes/notesContent/customerNotes/customerNotes";
import NotesTrigger from "src/pages/dashboarSection/layout/popUps/sheet/content/orderDetails/notes/notesTrigger";
import useSheet from "src/pages/dashboarSection/layout/popUps/sheet/useSheet";
import {OrderDetails} from "src/utils/types/structures/orderDetails";

interface Props {
    order: OrderDetails
}

export enum NotesSection {
    all = 'ALL',
    customer = 'CUSTOMER',
    crew = 'CREW',
    marco = 'MARCO'
}


export default function Notes({order}: Props) {
    const {close} = useSheet();
    const [currentActiveTab, setCurrentActiveTab] = useState<NotesSection>(NotesSection.customer);

    return <section className={'h-[calc(100dvh-4.25rem)] flex flex-col'}>

            <Tabs value={currentActiveTab} className="grow pt-[1.313rem] px-6">
                <TabsList>
                    <NotesTrigger value={NotesSection.customer} currentActive={currentActiveTab}
                                  setCurrentActive={setCurrentActiveTab} title={'Customer'}/>
                </TabsList>

                <TabsContent value={NotesSection.customer}><CustomerNotes order={order}/></TabsContent>
            </Tabs>


        <section key={'close-section'}
                 className={'sticky bottom-0 bg-background z-30 flex-none grid h-20 place-items-center border-t'}>
            <span onClick={close} className={'text-base font-medium underline leading-normal cursor-pointer'}>Close</span>
        </section>
    </section>
}