import * as React from "react";
import {AccordionContent, AccordionItem, AccordionTrigger} from "src/components/accordion";
import {ShevronDown} from "src/media/icons/custom/shevron/shevronDown";
import UneditableImageCover
    from "src/pages/dashboarSection/orderDetails/sections/attachments/components/uneditableImageCover";
import {Colors} from "src/utils/defaults/colors";
import {defaultDimensions} from "src/utils/defaults/defaultDimensions";
import {ExtendedOrderImage} from "src/utils/zustandStores/orderDetailsAttachments/orderAttachments";
import useViewport from "src/utils/zustandStores/primary/useViewport";

interface Props {
    title: string
    orderImages: ExtendedOrderImage[]
}

export default function UnEditableOrderImages({orderImages, title}: Props) {
    const {width} = useViewport()

    return <AccordionItem className={'pb-4'} value="customer">
        <AccordionTrigger className={'py-3 flex justify-between w-full'}>
            <div className={'flex flex-none gap-4 place-items-center'}>
                <h3 className={'text-lg text-foreground font-medium'}>{title}</h3>
                {width >= defaultDimensions.md && <p className={'text-sm text-muted-foreground'}>.png, .jpg, .pdf, .mp4, .mov, etc</p>}
            </div>

            <div className={'flex gap-2 items-center'}>
                <p className={'text-sm text-foreground font-medium'}>{orderImages.length} Uploaded</p>
                <ShevronDown width={10} height={6} stroke={Colors.foreground}/>
            </div>
        </AccordionTrigger>

        <AccordionContent className={'grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 gap-2 sm:gap-4'}>
            {orderImages.map(el => <div key={el.id}><UneditableImageCover orderImage={el}/></div>)}
        </AccordionContent>
    </AccordionItem>
}