export const appointmentTimeToDateTime = (time: 'MORNING' | 'AFTERNOON' | 'EVENING'): string => {
    switch (true) {
        case (time === 'MORNING'): {
            return '8:00 AM - 11:00 AM'
        }

        case (time === 'AFTERNOON'): {
            return '12:00 AM - 3:00 PM'
        }

        case (time === 'EVENING'): {
            return '4:00 PM - 6:00 PM'
        }

        default: {
            return ''
        }
    }
}