import React, {useEffect, useRef, useState} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {useNavigate, useParams} from "react-router-dom";
import {ScrollArea, ScrollBar} from "src/components/scrollArea";
import {Tabs, TabsList, TabsTrigger} from "src/components/tabs";
import EmptyResults from "src/pages/dashboarSection/myLoads/components/emptyResults";
import GridCardWithTablesMD from "src/pages/dashboarSection/myLoads/components/gridCardWithTablesMD";
import {getOrdersMyLoads} from "src/utils/apiCalls/getOrdersMyLoads";
import {defaultDimensions} from "src/utils/defaults/defaultDimensions";
import routes from "src/utils/defaults/routes";
import {CurrentTabStatus} from "src/utils/enums/CurrentTabStatus";
import {handleResponseError} from "src/utils/errorHandlers/handleResponseError";
import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import {ErrorMessage} from "src/utils/types/errorMessage";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import useViewport from "src/utils/zustandStores/primary/useViewport";

export default function MyLoads() {
    const navigate = useNavigate();
    const {section} = useParams()
    const [currentActive, setCurrentActive] = useState<CurrentTabStatus | null>(null);
    const [isLoading, setLoading] = useState<boolean>(true)
    const [totalOrders, setTotalOrders] = useState<number>(0);
    const [totalRendered, setTotalRendered] = useState<OrderDetails[]>([]);
    const page = useRef(0);
    const {width, isScrolled} = useViewport();

    useEffect(() => {
        if (section) {
            switch (section) {
                case CurrentTabStatus.booked.toLowerCase(): {
                    setCurrentActive(CurrentTabStatus.booked)
                    break
                }

                case CurrentTabStatus.all.toLowerCase(): {
                    setCurrentActive(CurrentTabStatus.all)
                    break
                }

                case CurrentTabStatus.completed.toLowerCase(): {
                    setCurrentActive(CurrentTabStatus.completed)
                    break
                }

                case 'in-progress': {
                    setCurrentActive(CurrentTabStatus.inProgress)
                    break
                }

                default: {
                    setCurrentActive(CurrentTabStatus.booked)
                    break
                }
            }
        } else {
            setCurrentActive(CurrentTabStatus.booked)
        }
    }, [section]);

    const setOrders = async () => {
        if (currentActive) {
            setLoading(true)
            page.current = 0
            const res = await getOrdersMyLoads(currentActive, page.current)
            if (isInstanceOf<ErrorMessage>(res, 'message')) {
                handleResponseError(res, () => navigate(routes.login));
            } else {
                setTotalRendered(res.content);
                setTotalOrders(res.totalElements)
            }
            setLoading(false)
        }
    }

    useEffect(() => {
        setOrders()
    }, [currentActive]);

    async function loadMoreOrders() {
        if (currentActive) {
            page.current = page.current + 1
            if (totalRendered.length < totalOrders) {
                const res = await getOrdersMyLoads(currentActive, page.current)
                if (isInstanceOf<ErrorMessage>(res, 'message')) {
                    handleResponseError(res, () => navigate(routes.login))
                } else {
                    setTotalRendered(prevState => [...prevState, ...res.content])
                }
            }
        }
    }

    return <Tabs defaultValue={undefined} className='w-full'>
        <div
            className={`px-padding ${width < defaultDimensions.md ? 'sticky top-0 pt-8' : 'pt-8'} ${!isScrolled && 'shadow-light'} md:shadow-none bg-background z-30 pb-6`}>
            <h1 className={"text-foreground text-[1.6rem] font-semibold font-inter pb-[23px]"}>My loads</h1>
            <ScrollArea className={"w-[95vw] sm:w-full"}>
                <TabsList className={`flex gap-2 justify-start`}>
                    <TabsTrigger value={CurrentTabStatus.booked}
                                 className={`
                             ${currentActive === CurrentTabStatus.booked && 'bg-[#F7F7F7] outline outline-[0.175rem] outline-offset-[-2px] outline-foreground'} h-10`}
                                 onClick={(e) => {
                                     e.preventDefault();
                                     e.stopPropagation();
                                     if (currentActive !== CurrentTabStatus.booked) {
                                         window.scrollTo(0, 0)
                                         navigate(`${routes.loads}/booked`)
                                     }
                                 }}>Booked</TabsTrigger>

                    <TabsTrigger value={CurrentTabStatus.inProgress}
                                 className={`
                             ${currentActive === CurrentTabStatus.inProgress && 'bg-[#F7F7F7] outline outline-[0.175rem] outline-offset-[-2px] outline-foreground'} h-10`}
                                 onClick={(e) => {
                                     e.preventDefault();
                                     e.stopPropagation();
                                     if (currentActive !== CurrentTabStatus.inProgress) {
                                         window.scrollTo(0, 0)
                                         navigate(`${routes.loads}/in-progress`)
                                     }
                                 }}>In progress</TabsTrigger>

                    <TabsTrigger value={CurrentTabStatus.completed}
                                 className={`
                             ${currentActive === CurrentTabStatus.completed && 'bg-[#F7F7F7] outline outline-[0.175rem] outline-offset-[-2px] outline-foreground'} h-10`}
                                 onClick={(e) => {
                                     e.preventDefault();
                                     e.stopPropagation();
                                     if (currentActive !== CurrentTabStatus.completed) {
                                         window.scrollTo(0, 0)
                                         navigate(`${routes.loads}/completed`)
                                     }
                                 }}>Completed</TabsTrigger>

                    <TabsTrigger value={CurrentTabStatus.all}
                                 className={`
                             ${currentActive === CurrentTabStatus.all && 'bg-[#F7F7F7] outline outline-[0.175rem] outline-offset-[-2px] outline-foreground'} h-10`}
                                 onClick={(e) => {
                                     e.preventDefault();
                                     e.stopPropagation();
                                     if (currentActive !== CurrentTabStatus.all) {
                                         window.scrollTo(0, 0)
                                         navigate(`${routes.loads}/all`)
                                     }
                                 }}>All</TabsTrigger>


                </TabsList>
                <ScrollBar orientation="horizontal" className={'hidden'}/>
            </ScrollArea>
        </div>


        {(totalOrders > 0) && <InfiniteScroll
                next={loadMoreOrders}
                hasMore={totalRendered.length < totalOrders}
                loader={<div>Loading</div>}
                dataLength={totalRendered.length}
            >
                <GridCardWithTablesMD orderList={totalRendered}/>
            </InfiniteScroll>}

        {(!totalOrders && !isLoading) && <EmptyResults currentActive={currentActive} className={'pt-20 px-padding w-full h-full'}/>}
    </Tabs>
}