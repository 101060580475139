import {motion} from "framer-motion";
import * as React from "react"

import {cn} from "../utils/cn/cnHelper";

export interface InputProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    isInvalid?: boolean
    label: string
    isInputOn: boolean
}

const InputOffLabel = React.forwardRef<HTMLInputElement, InputProps>(
    ({className, type, value, label, isInvalid, isInputOn, ...props}, ref) => {
        return (
            <motion.div animate={isInvalid ? {outlineStyle: 'solid', outlineWidth: '1px', outlineColor: '#C6241D', backgroundColor: 'rgba(216,44,13,0.2)'} : {outlineStyle: 'solid', outlineWidth: '1px', outlineColor: '#dddddd'}}
                        transition={{duration: 0.1}}
                        className={'group w-full flex flex-col relative h-[56px] rounded-[6px] [z-3]'}>
                <input
                    type={type}
                    className={cn(
                        `z-[2] flex w-full ${isInvalid ? 'group-focus-within:outline group-focus-within:outline-2 group-focus-within:outline-[#C6241D]' : 'group-focus-within:outline group-focus-within:outline-2 group-focus-within:outline-[#222222]'} rounded-[6px] bg-transparent h-full font-inter ${isInvalid ? 'text-[#C6241D]' : 'text-[#222222]'} text-[16px] leading-[20px] font-normal ${isInputOn && 'pb-2 pt-6'} px-4`,
                        className
                    )}
                    ref={ref}
                    {...props}
                />
                <motion.span
                    initial={{fontSize: '16px', lineHeight: '20px', fontWeight: 400}}
                    transition={{duration: 0.1}}
                    animate={isInputOn ? {fontSize: '12px', translateY: -10, lineHeight: '16px', fontWeight: 400} : {fontSize: '16px', lineHeight: '20px', fontWeight: 400}}
                    className={`z-0 absolute ${isInvalid ? 'text-[#C6241D]' : 'text-[#6a6a6a]'} h-full content-center w-full px-4`}>{label}</motion.span>
            </motion.div>
        )
    }
)

InputOffLabel.displayName = "Input"

export {InputOffLabel}
