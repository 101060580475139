import {zodResolver} from "@hookform/resolvers/zod";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {Button} from "src/components/button";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "src/components/form";
import {Input} from "src/components/input";
import {PhoneInput} from "src/components/phoneInput";
import {Separator} from "src/components/separator";
import routes from "src/utils/defaults/routes";
import {handleResponseError} from "src/utils/errorHandlers/handleResponseError";
import getString, {ElementTag} from "src/utils/stringHandlers/getString";
import {Company} from "src/utils/types/structures/company";
import companyDetailsSchema from "src/utils/zodSchemas/companyDetailsSchema";
import * as z from "zod";
import {CompanyData, getCompany, updateCompanyData} from "../../../../../utils/apiCalls/account/updateCompanyData";
import {SpinnerIcon} from "../../../../../media/icons/custom/spinnerIcon";

export enum CompanDetailsSection {
    personOfContact = 'PERSON_OF_CONTACT',
    emailAddress = 'EMAIL_ADDRESS',
    phoneNumber = 'PHONE_NUMBER',
    companyOverview = 'COMPANY_OVERVIEW',
    address = 'ADDRESS',
}

type IsDisabled = {
    personOfContact: boolean,
    emailAddress: boolean,
    phoneNumber: boolean,
    companyOverview: boolean,
    address: boolean,
    currentSection: CompanDetailsSection | null
}

const defaultIsDisabled: IsDisabled = {
    personOfContact: false,
    emailAddress: false,
    phoneNumber: false,
    companyOverview: false,
    address: false,
    currentSection: null
}

export default function CompanyDetails() {
    const navigate = useNavigate();
    const [isDisabled, setIsDisabled] = useState<IsDisabled>(defaultIsDisabled);
    const [company, setCompany] = useState<Company | null>(null);
    const [isLoading, setLoading] = useState<boolean>(false)

    const getCompanyInfo = async () => {
    const {data, error} = await getCompany()
        if (data) setCompany(data)
        if (error) handleResponseError(error, () => navigate(routes.login))
    }

    useEffect(() => {
        getCompanyInfo()
    }, []);

    useEffect(() => {
        if (company) {
            form.setValue('firstName', company.firstName)
            form.setValue('lastName', company.lastName)
            form.setValue('email', company.email)
            form.setValue('phoneNumber', company.phone)
            form.setValue('companyName', company.legalName)
            form.setValue('MC', company.fmsc)
            form.setValue('DOT', company.dot)
            form.setValue('steetAddress', company.address1)
            form.setValue('aptSuiteEtc', company.address2)
            form.setValue('city', company.city)
            form.setValue('state', company.state)
            form.setValue('ZIP', company.zipcode)
            form.setValue('country', company.country)
        }
    }, [company]);

    const form = useForm<z.infer<typeof companyDetailsSchema>>({
        resolver: zodResolver(companyDetailsSchema),
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            companyName: '',
            MC: '',
            DOT: '',
            steetAddress: '',
            aptSuiteEtc: '',
            city: '',
            state: '',
            ZIP: '',
            country: '',
        }
    });

    async function onSubmit(values: z.infer<typeof companyDetailsSchema>) {
        setLoading(true)
        const companyData: CompanyData = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: values.phoneNumber,
            address1: values.steetAddress,
            address2: values.aptSuiteEtc ?? '',
            city: values.city,
            state: values.state,
            country: values.country,

            addressPhone: company?.addressPhone ?? '',
            addressFirstName: company?.addressFirstName ?? '',
            addressLastName: company?.addressLastName ?? '',

            zipcode: values.ZIP,
        }

        const {data, error} = await updateCompanyData(companyData)

        if (data) {
            setCompany(data)
            setIsDisabled({
                address: false,
                companyOverview: false,
                personOfContact: false,
                emailAddress: false,
                phoneNumber: false,
                currentSection: null
            })
        }
        if (error) handleResponseError(error, () => navigate(routes.login))

        setLoading(false)
    }

    if (company) {
        return <div className={`w-full`}>
            <h1 className={` pb-4 text-[#222222] text-[24px] leading-[32px] font-medium`}>Company details</h1>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <div className={'flex flex-col pt-6 gap-6'}>

                        <section key={'company-overview'} className={'flex flex-col'}>
                            <div className={'flex justify-between items-center h-5 mb-2'}>
                                <h2 className={`${isDisabled.companyOverview ? 'text-[#ebebeb]' : 'text-[#222222]'} text-[16px] leading-[20px] font-medium`}>Company
                                    overview</h2>
                                <Button
                                    disabled={isDisabled.companyOverview}
                                    onClick={() => {
                                        if (isDisabled.currentSection === CompanDetailsSection.companyOverview) {
                                            setIsDisabled({
                                                address: false,
                                                companyOverview: false,
                                                personOfContact: false,
                                                emailAddress: false,
                                                phoneNumber: false,
                                                currentSection: null
                                            })
                                            form.setValue('companyName', company.legalName)
                                            form.setValue('MC', company.fmsc)
                                            form.setValue('DOT', company.dot)
                                            window.scrollTo(0, 700)
                                        } else {
                                            setIsDisabled({
                                                address: true,
                                                companyOverview: false,
                                                personOfContact: true,
                                                emailAddress: true,
                                                phoneNumber: true,
                                                currentSection: CompanDetailsSection.companyOverview
                                            })
                                            window.scrollTo(0, 700)
                                        }
                                    }} type={'button'} size={'textForm'} variant={'textGhost'}
                                    className={`hidden font-semibold ${isDisabled.companyOverview ? 'text-[#ebebeb]' : 'text-[#222222]'} text-[14px] leading-[20px] font-medium`}>{isDisabled.currentSection === CompanDetailsSection.companyOverview ? 'Cancel' : 'Edit'}</Button>
                            </div>

                            <p className={`${isDisabled.currentSection === CompanDetailsSection.companyOverview && 'hidden'} ${isDisabled.companyOverview ? 'text-[#ebebeb]' : 'text-foreground'} text-[16px] leading-[24px] font-normal`}>
                                {company.companyName}{company.fmsc && `, MC#${company.fmsc}`}{company.dot && `, DOT#${company.dot}`}
                            </p>
                            <p className={`text-sm ${isDisabled.companyOverview ? 'text-[#ebebeb]' : 'text-[#717171]'} pt-1 text-[14px] leading-[20px] font-normal`}>
                                This information will appear in contracts, forms, and communications. If you need to update any details, please contact support.
                            </p>

                            {isDisabled.currentSection === CompanDetailsSection.companyOverview &&
                                <div className={'pt-6 flex flex-col gap-6'}>

                                    <div className={'flex flex-col gap-4'}>
                                        <FormField
                                            control={form.control}
                                            name="companyName"
                                            render={({field}) => (
                                                <FormItem className={"flex-1"}>

                                                    <div className={'grid grid-cols-2'}>
                                                        <FormLabel className={'text-sm leading-3'}>Legal business
                                                            name</FormLabel>
                                                        {form.getFieldState('companyName').invalid && <FormMessage
                                                            className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium place-self-end'}/>}
                                                    </div>

                                                    <FormControl>
                                                        <Input
                                                            {...field}
                                                            className={`${form.getFieldState('companyName').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`}
                                                            placeholder={"Legal name"}
                                                        />
                                                    </FormControl>
                                                </FormItem>
                                            )}
                                        />

                                        <div className={"flex gap-4 items-end"}>
                                            <FormField
                                                control={form.control}
                                                name="MC"
                                                render={({field}) => (
                                                    <FormItem className={"flex-1"}>

                                                        <div className={'grid grid-cols-2'}>
                                                            <FormLabel className={'text-sm leading-3'}>MC# (if
                                                                applicable)</FormLabel>
                                                            {form.getFieldState('MC').invalid && <FormMessage
                                                                className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium place-self-end'}/>}
                                                        </div>

                                                        <FormControl>
                                                            <Input
                                                                {...field}
                                                                className={`${form.getFieldState('MC').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`}
                                                                placeholder={"MC#"}
                                                            />
                                                        </FormControl>
                                                    </FormItem>
                                                )}
                                            />

                                            <FormField
                                                control={form.control}
                                                name="DOT"
                                                render={({field}) => (
                                                    <FormItem className={"flex-1"}>

                                                        <div className={'grid grid-cols-2'}>
                                                            <FormLabel className={'text-sm leading-3'}>DOT# (if
                                                                applicable)</FormLabel>
                                                            {form.getFieldState('DOT').invalid && <FormMessage
                                                                className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium place-self-end'}/>}
                                                        </div>

                                                        <FormControl>
                                                            <Input
                                                                placeholder={"DOT#"}
                                                                className={`${form.getFieldState('DOT').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`}
                                                                {...field}/>
                                                        </FormControl>
                                                    </FormItem>
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <Button type={'submit'} size={'lg'}
                                            className={'w-fit text-white text-[16px] leading-[20px] font-medium px-[20px] relative'}>
                                        <SpinnerIcon
                                            className={`${isLoading ? 'visible' : 'invisible'} fill-marcoWhite absolute`}
                                            size={20}/>
                                        <p className={`${isLoading ? 'invisible' : 'visible'} px-5 text-center text-white text-[16px] leading-[20px] font-medium`}>Save</p>
                                    </Button>
                                </div>}
                        </section>

                        <Separator className={'mb-4'}/>

                        <section key={'person-of-Contact'} className={'flex flex-col'}>
                            <div className={'flex justify-between items-center h-5 mb-2'}>
                                <h2 className={`${isDisabled.personOfContact ? 'text-[#ebebeb]' : 'text-[#222222]'} text-[16px] leading-[20px] font-medium`}>Person
                                    of contact</h2>
                                <Button
                                    disabled={isDisabled.personOfContact}
                                    onClick={() => {
                                        if (isDisabled.currentSection === CompanDetailsSection.personOfContact) {
                                            setIsDisabled({
                                                address: false,
                                                companyOverview: false,
                                                personOfContact: false,
                                                emailAddress: false,
                                                phoneNumber: false,
                                                currentSection: null
                                            })
                                            form.setValue('firstName', company.firstName)
                                            form.setValue('lastName', company.lastName)
                                            window.scrollTo(0, 0)
                                        } else {
                                            setIsDisabled({
                                                address: true,
                                                companyOverview: true,
                                                personOfContact: false,
                                                emailAddress: true,
                                                phoneNumber: true,
                                                currentSection: CompanDetailsSection.personOfContact
                                            })
                                        }
                                    }} type={'button'} size={'textForm'} variant={'textGhost'}
                                    className={`font-semibold ${isDisabled.personOfContact ? 'text-[#ebebeb]' : 'text-[#222222]'} text-[14px] leading-[20px] font-medium`}>{isDisabled.currentSection === CompanDetailsSection.personOfContact ? 'Cancel' : 'Edit'}</Button>
                            </div>

                            <p className={`${isDisabled.currentSection === CompanDetailsSection.personOfContact && 'hidden'} ${isDisabled.personOfContact ? 'text-[#ebebeb]' : 'text-[#222222]'} text-[16px] leading-[24px] font-normal`}>
                                {
                                    getString([
                                        {element: company.firstName, tag: ElementTag.name},
                                        {element: company.lastName, tag: ElementTag.name},
                                    ])
                                }
                            </p>

                            <p className={`${isDisabled.personOfContact ? 'text-[#ebebeb]' : 'text-[#717171]'} pt-1 text-[14px] leading-[20px] font-normal`}>
                                The company's contact for customers or Marco must be the owner or a legal
                                representative.
                            </p>

                            {isDisabled.currentSection === CompanDetailsSection.personOfContact &&
                                <div className={'pt-6 flex flex-col gap-6'}>
                                    <div className={"flex gap-4"}>
                                        <FormField
                                            control={form.control}
                                            name="firstName"
                                            render={({field}) => (
                                                <FormItem className={"flex-1"}>

                                                    <div className={'grid grid-cols-2'}>
                                                        <FormLabel className={'text-sm leading-3'}>First
                                                            name</FormLabel>
                                                        {form.getFieldState('firstName').invalid && <FormMessage
                                                            className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium place-self-end'}/>}
                                                    </div>

                                                    <FormControl>
                                                        <Input
                                                            {...field}
                                                            className={`${form.getFieldState('firstName').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`}
                                                            placeholder={"First name"}
                                                        />
                                                    </FormControl>
                                                </FormItem>
                                            )}
                                        />

                                        <FormField
                                            control={form.control}
                                            name="lastName"
                                            render={({field}) => (
                                                <FormItem className={"flex-1"}>

                                                    <div className={'grid grid-cols-2'}>
                                                        <FormLabel className={'text-sm leading-3'}>Last name</FormLabel>
                                                        {form.getFieldState('lastName').invalid && <FormMessage
                                                            className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium place-self-end'}/>}
                                                    </div>

                                                    <FormControl>
                                                        <Input
                                                            placeholder={"Last name"}
                                                            className={`${form.getFieldState('lastName').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`}
                                                            {...field}/>
                                                    </FormControl>
                                                </FormItem>
                                            )}
                                        />
                                    </div>
                                    <Button type={'submit'} size={'lg'}
                                            className={'w-fit text-white text-[16px] leading-[20px] font-medium px-[20px] relative'}>
                                        <SpinnerIcon
                                            className={`${isLoading ? 'visible' : 'invisible'} fill-marcoWhite absolute`}
                                            size={20}/>
                                        <p className={`${isLoading ? 'invisible' : 'visible'} px-5 text-center text-white text-[16px] leading-[20px] font-medium`}>Save</p>
                                    </Button>
                                </div>}
                        </section>

                        <Separator className={'mb-4'}/>

                        <section key={'email-address'} className={'flex flex-col'}>
                            <div className={'flex justify-between items-center h-5 mb-2'}>
                                <h2 className={`${isDisabled.emailAddress ? 'text-[#ebebeb]' : 'text-[#222222]'} text-[16px] leading-[20px] font-medium`}>
                                    Email address
                                </h2>
                                <Button
                                    disabled={isDisabled.emailAddress}
                                    onClick={() => {
                                        if (isDisabled.currentSection === CompanDetailsSection.emailAddress) {
                                            setIsDisabled({
                                                address: false,
                                                companyOverview: false,
                                                personOfContact: false,
                                                emailAddress: false,
                                                phoneNumber: false,
                                                currentSection: null
                                            })
                                            form.setValue('email', company.email)
                                            window.scrollTo(0, 0)
                                        } else {
                                            setIsDisabled({
                                                address: true,
                                                companyOverview: true,
                                                personOfContact: true,
                                                emailAddress: false,
                                                phoneNumber: true,
                                                currentSection: CompanDetailsSection.emailAddress
                                            })
                                        }
                                    }} type={'button'} size={'textForm'} variant={'textGhost'}
                                    className={`${isDisabled.emailAddress ? 'text-[#ebebeb]' : 'text-[#222222]'} text-[14px] leading-[20px] font-medium`}>{isDisabled.currentSection === CompanDetailsSection.emailAddress ? 'Cancel' : 'Edit'}</Button>
                            </div>

                            <p className={`${isDisabled.currentSection === CompanDetailsSection.emailAddress && 'hidden'} ${isDisabled.emailAddress ? 'text-[#ebebeb]' : 'text-[#222222]'} text-[16px] leading-[24px] font-normal`}>
                                {company.email}
                            </p>

                            <p className={`text-sm pt-1 ${isDisabled.emailAddress ? 'text-[#ebebeb]' : 'text-[#717171]'} text-[14px] leading-[20px] font-normal`}>
                                The primary email for communication with confirmed customers or Marco.
                            </p>

                            {isDisabled.currentSection === CompanDetailsSection.emailAddress &&
                                <div className={'pt-6 flex flex-col gap-6'}>
                                    <FormField
                                        control={form.control}
                                        name="email"
                                        render={({field}) => (
                                            <FormItem className={"flex-1"}>

                                                <div className={'grid grid-cols-2'}>
                                                    <FormLabel className={'text-sm leading-3'}>Email</FormLabel>
                                                    {form.getFieldState('email').invalid && <FormMessage
                                                        className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium place-self-end'}/>}
                                                </div>

                                                <FormControl>
                                                    <Input
                                                        placeholder={"Email"}
                                                        className={`${form.getFieldState('email').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`}
                                                        {...field}/>
                                                </FormControl>
                                            </FormItem>
                                        )}
                                    />
                                    <Button type={'submit'} size={'lg'}
                                            className={'w-fit text-white text-[16px] leading-[20px] font-medium px-[20px] relative'}>
                                        <SpinnerIcon
                                            className={`${isLoading ? 'visible' : 'invisible'} fill-marcoWhite absolute`}
                                            size={20}/>
                                        <p className={`${isLoading ? 'invisible' : 'visible'} px-5 text-center text-white text-[16px] leading-[20px] font-medium`}>Save</p>
                                    </Button>
                                </div>}
                        </section>

                        <Separator className={'mb-4'}/>

                        <section key={'phone-number'} className={'flex flex-col'}>
                            <div className={'flex justify-between items-center h-5 mb-2'}>
                                <h2 className={`${isDisabled.phoneNumber ? 'text-[#ebebeb]' : 'text-[#222222]'} text-[16px] leading-[20px] font-medium`}>Phone
                                    number</h2>
                                <Button
                                    disabled={isDisabled.phoneNumber}
                                    onClick={() => {
                                        if (isDisabled.currentSection === CompanDetailsSection.phoneNumber) {
                                            setIsDisabled({
                                                address: false,
                                                companyOverview: false,
                                                personOfContact: false,
                                                emailAddress: false,
                                                phoneNumber: false,
                                                currentSection: null
                                            })
                                            form.setValue('phoneNumber', company.phone)
                                            window.scrollTo(0, 0)
                                        } else {
                                            setIsDisabled({
                                                address: true,
                                                companyOverview: true,
                                                personOfContact: true,
                                                emailAddress: true,
                                                phoneNumber: false,
                                                currentSection: CompanDetailsSection.phoneNumber
                                            })
                                        }
                                    }} type={'button'} size={'textForm'} variant={'textGhost'}
                                    className={`${isDisabled.phoneNumber ? 'text-[#ebebeb]' : 'text-[#222222]'} text-[14px] leading-[20px] font-medium`}>{isDisabled.currentSection === CompanDetailsSection.phoneNumber ? 'Cancel' : 'Edit'}</Button>
                            </div>

                            <p className={`${isDisabled.currentSection === CompanDetailsSection.phoneNumber && 'hidden'} ${isDisabled.phoneNumber ? 'text-[#ebebeb]' : 'text-[#222222]'} text-[16px] leading-[24px] font-normal`}>{company.phone}</p>

                            <p className={`text-sm ${isDisabled.phoneNumber ? 'text-[#ebebeb]' : 'text-[#717171]'} pt-1 text-[14px] leading-[20px] font-normal`}>
                                The primary phone number for communication with confirmed customers or Marco.
                            </p>

                            {isDisabled.currentSection === CompanDetailsSection.phoneNumber &&
                                <div className={'pt-6 flex flex-col gap-6'}>
                                    <FormField
                                        control={form.control}
                                        name="phoneNumber"
                                        render={({field}) => (
                                            <FormItem className={"flex-1"}>

                                                <div className={'grid grid-cols-2'}>
                                                    <FormLabel htmlFor={'phone-number'} className={'text-sm leading-3'}>Phone
                                                        number</FormLabel>
                                                    {form.getFieldState('phoneNumber').invalid && <FormMessage
                                                        className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium place-self-end'}/>}
                                                </div>

                                                <FormControl>
                                                    <PhoneInput
                                                        {...field}
                                                        id={'phone-number'}
                                                        isvalid={form.getFieldState('phoneNumber').invalid.toString()}
                                                        placeholder={'Phone'}
                                                    />
                                                </FormControl>

                                                <p className={'text-sm font-normal leading-5'}>A verification code will
                                                    be sent to this number</p>
                                            </FormItem>
                                        )}
                                    />

                                    <Button type={'submit'} size={'lg'}
                                            className={'w-fit text-white text-[16px] leading-[20px] font-medium px-[20px] relative'}>
                                        <SpinnerIcon
                                            className={`${isLoading ? 'visible' : 'invisible'} fill-marcoWhite absolute`}
                                            size={20}/>
                                        <p className={`${isLoading ? 'invisible' : 'visible'} px-5 text-center text-white text-[16px] leading-[20px] font-medium`}>Save</p>
                                    </Button>
                                </div>}
                        </section>

                        <Separator className={'mb-4'}/>

                        <section key={'address'} className={'flex flex-col'}>
                            <div className={'flex justify-between items-center h-5 mb-2'}>
                                <h2 className={`${isDisabled.address ? 'text-[#ebebeb]' : 'text-[#222222]'} text-[16px] leading-[20px] font-medium`}>Address</h2>
                                <Button
                                    disabled={isDisabled.address}
                                    onClick={() => {
                                        if (isDisabled.currentSection === CompanDetailsSection.address) {
                                            setIsDisabled({
                                                address: false,
                                                companyOverview: false,
                                                personOfContact: false,
                                                emailAddress: false,
                                                phoneNumber: false,
                                                currentSection: null
                                            })
                                            form.setValue('steetAddress', company?.address1)
                                            form.setValue('aptSuiteEtc', company?.address2)
                                            form.setValue('city', company.city)
                                            form.setValue('state', company.state)
                                            form.setValue('ZIP', company.zipcode)
                                            form.setValue('country', company.country)
                                            window.scrollTo(0, 700)
                                        } else {
                                            setIsDisabled({
                                                address: false,
                                                companyOverview: true,
                                                personOfContact: true,
                                                emailAddress: true,
                                                phoneNumber: true,
                                                currentSection: CompanDetailsSection.address
                                            })
                                            window.scrollTo(0, 700)
                                        }
                                    }} type={'button'} size={'textForm'} variant={'textGhost'}
                                    className={`font-semibold ${isDisabled.address ? 'text-[#ebebeb]' : 'text-[#222222]'} text-[14px] leading-[20px] font-medium`}>{isDisabled.currentSection === CompanDetailsSection.address ? 'Cancel' : 'Edit'}</Button>
                            </div>

                            <p className={`${isDisabled.currentSection === CompanDetailsSection.address && 'hidden'} ${isDisabled.address ? 'text-[#ebebeb]' : 'text-[#222222]'} text-[16px] leading-[24px] font-normal`}>
                                {company.address1 ?? ''} {company.address2 + ', ' ?? ''}{company.city + ', ' ?? ''}{company.state + ', ' ?? ''}{company.country}
                            </p>

                            <p className={`text-sm ${isDisabled.address ? 'text-[#ebebeb]' : 'text-[#717171]'} pt-1 text-[14px] leading-[20px] font-normal`}>
                                Your company's default address is suitable for mail correspondence.
                            </p>

                            {isDisabled.currentSection === CompanDetailsSection.address &&
                                <div className={'pt-6 flex flex-col gap-6'}>

                                    <div className={'flex flex-col gap-4'}>
                                        <FormField
                                            control={form.control}
                                            name="steetAddress"
                                            render={({field}) => (
                                                <FormItem className={"flex-1"}>

                                                    <div className={'grid grid-cols-2'}>
                                                        <FormLabel className={'text-sm leading-3'}>Street
                                                            address</FormLabel>
                                                        {form.getFieldState('steetAddress').invalid && <FormMessage
                                                            className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium place-self-end'}/>}
                                                    </div>

                                                    <FormControl>
                                                        <Input
                                                            {...field}
                                                            className={`${form.getFieldState('steetAddress').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`}
                                                            placeholder={"Street address"}
                                                        />
                                                    </FormControl>
                                                </FormItem>
                                            )}
                                        />

                                        <FormField
                                            control={form.control}
                                            name="aptSuiteEtc"
                                            render={({field}) => (
                                                <FormItem className={"flex-1"}>

                                                    <div className={'grid grid-cols-2'}>
                                                        <FormLabel className={'text-sm leading-3'}>Apt, suite,
                                                            etc</FormLabel>
                                                        {form.getFieldState('aptSuiteEtc').invalid && <FormMessage
                                                            className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium place-self-end'}/>}
                                                    </div>

                                                    <FormControl>
                                                        <Input
                                                            {...field}
                                                            className={`${form.getFieldState('aptSuiteEtc').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`}
                                                            placeholder={"Apt, suite, etc"}
                                                        />
                                                    </FormControl>
                                                </FormItem>
                                            )}
                                        />

                                        <div className={"flex gap-4 items-end"}>
                                            <FormField
                                                control={form.control}
                                                name="city"
                                                render={({field}) => (
                                                    <FormItem className={"flex-1"}>

                                                        <div className={'grid grid-cols-2'}>
                                                            <FormLabel className={'text-sm leading-3'}>City</FormLabel>
                                                            {form.getFieldState('city').invalid && <FormMessage
                                                                className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium place-self-end'}/>}
                                                        </div>

                                                        <FormControl>
                                                            <Input
                                                                {...field}
                                                                className={`${form.getFieldState('city').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`}
                                                                placeholder={"City"}
                                                            />
                                                        </FormControl>
                                                    </FormItem>
                                                )}
                                            />

                                            <FormField
                                                control={form.control}
                                                name="state"
                                                render={({field}) => (
                                                    <FormItem className={"flex-1"}>

                                                        <div className={'grid grid-cols-2'}>
                                                            <FormLabel className={'text-sm leading-3'}>State</FormLabel>
                                                            {form.getFieldState('state').invalid && <FormMessage
                                                                className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium place-self-end'}/>}
                                                        </div>

                                                        <FormControl>
                                                            <Input
                                                                placeholder={"State"}
                                                                className={`${form.getFieldState('state').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`}
                                                                {...field}/>
                                                        </FormControl>
                                                    </FormItem>
                                                )}
                                            />
                                        </div>

                                        <div className={"flex gap-4 items-end"}>
                                            <FormField
                                                control={form.control}
                                                name="ZIP"
                                                render={({field}) => (
                                                    <FormItem className={"flex-1"}>

                                                        <div className={'grid grid-cols-2'}>
                                                            <FormLabel className={'text-sm leading-3'}>Zip</FormLabel>
                                                            {form.getFieldState('ZIP').invalid && <FormMessage
                                                                className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium place-self-end'}/>}
                                                        </div>

                                                        <FormControl>
                                                            <Input
                                                                {...field}
                                                                className={`${form.getFieldState('ZIP').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`}
                                                                placeholder={"Zip"}
                                                            />
                                                        </FormControl>
                                                    </FormItem>
                                                )}
                                            />

                                            <FormField
                                                control={form.control}
                                                name="country"
                                                render={({field}) => (
                                                    <FormItem className={"flex-1"}>

                                                        <div className={'grid grid-cols-2'}>
                                                            <FormLabel
                                                                className={'text-sm leading-3'}>Country</FormLabel>
                                                            {form.getFieldState('country').invalid && <FormMessage
                                                                className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium place-self-end'}/>}
                                                        </div>

                                                        <FormControl>
                                                            <Input
                                                                placeholder={"Country"}
                                                                className={`${form.getFieldState('country').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`}
                                                                {...field}/>
                                                        </FormControl>
                                                    </FormItem>
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <Button type={'submit'} size={'lg'}
                                            className={'w-fit text-white text-[16px] leading-[20px] font-medium px-[20px] relative'}>
                                        <SpinnerIcon
                                            className={`${isLoading ? 'visible' : 'invisible'} fill-marcoWhite absolute`}
                                            size={20}/>
                                        <p className={`${isLoading ? 'invisible' : 'visible'} px-5 text-center text-white text-[16px] leading-[20px] font-medium`}>Save</p>
                                    </Button>
                                </div>}
                        </section>
                    </div>
                </form>
            </Form>
        </div>
    } else return null
}