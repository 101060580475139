import React, {useState} from "react";
import {Tabs, TabsContent, TabsList} from "src/components/tabs";
import AddCustomItem
    from "src/pages/dashboarSection/layout/popUps/sheet/content/orderDetails/updateItems/updateItemsContent/addCustomItem";
import SearchInventory
    from "src/pages/dashboarSection/layout/popUps/sheet/content/orderDetails/updateItems/updateItemsContent/searchInventory";
import UpdateItemsTrigger
    from "src/pages/dashboarSection/layout/popUps/sheet/content/orderDetails/updateItems/updateItemsTrigger";
import {OrderDetails} from "src/utils/types/structures/orderDetails";

interface Props {
    order: OrderDetails
}

export enum InentorySection {
    searchInventory = 'SEARCH_INVENTORY',
    addCustomItem = 'ADD_CUSTOM_ITEM'
}

export default function UpdateItems({order}: Props) {
    const [currentActiveTab, setCurrentActiveTab] = useState<InentorySection>(InentorySection.searchInventory);
    return <Tabs value={currentActiveTab} className="pt-[1.313rem] flex flex-col">

        <div className={'px-6'}>
            <TabsList>
                <UpdateItemsTrigger value={InentorySection.searchInventory} currentActive={currentActiveTab} setCurrentActive={setCurrentActiveTab} title={'Search inventory'}/>
                <UpdateItemsTrigger value={InentorySection.addCustomItem} currentActive={currentActiveTab} setCurrentActive={setCurrentActiveTab} title={'Add custom item'}/>
            </TabsList>
        </div>

        <TabsContent value={InentorySection.searchInventory}><SearchInventory order={order}/></TabsContent>
        <TabsContent value={InentorySection.addCustomItem}><AddCustomItem setCurrentActiveTab={setCurrentActiveTab}/></TabsContent>
    </Tabs>
}