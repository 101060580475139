import {PopoverClose} from "@radix-ui/react-popover";
import * as React from "react";
import {useNavigate} from "react-router-dom";
import {Avatar, AvatarFallback, AvatarImage} from "src/components/avatar";
import {Popover, PopoverContent, PopoverTrigger} from "src/components/popOver";
import {Separator} from "src/components/separator";
import routes from "src/utils/defaults/routes";
import {getFirstLetters} from "src/utils/stringHandlers/getFirstLetters";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";
import useUserHeader from "src/utils/zustandStores/userHeader/useUserHeader";

export default function UserNamePopOver() {
    const navigate = useNavigate()
    const {user} = useUserHeader()
    const {user: tokenUser} = useTokenStore()

    return <div>
        <Popover>
            <PopoverTrigger asChild>
                <Avatar className={'w-10 h-10 rounded-full cursor-pointer'}>
                    <AvatarImage src={(user && user.avatar && user.avatar !== '') ? user.avatar: undefined}/>
                    <AvatarFallback className={'text-sm'}>{user ? getFirstLetters(user.firstName, user.lastName) : ''}</AvatarFallback>
                </Avatar>
            </PopoverTrigger>
            <PopoverContent className="flex flex-col w-fit h-fit p-0 mt-[8px] shadow-md" align="end">

                <div className={'px-2'}>
                    <section className={'flex flex-col py-2 px-[12px] gap-0'}>
                        <p className={'text-[#222222] text-[14px] leading-[20px] font-medium'}>{user ? (user.firstName + ' ' + user.lastName) : ''}</p>
                        <p className={'text-[#717171] text-[12px] leading-[16px] font-normal'}>{user ? (user.email) : ''}</p>
                    </section>
                </div>

                <Separator/>

                <section className={'grow w-full h-full flex flex-col gap-2 pt-2 px-2'}>
                    <PopoverClose>
                        <div
                            className={'text-start py-[10px] px-[12px] hover:bg-marcoOnHover rounded-lg text-[#222222] text-[14px] leading-[20px] font-normal'}
                            onClick={() => {
                                navigate(routes.userPersonalDetails)
                            }}>Account details
                        </div>
                    </PopoverClose>

                    {tokenUser?.roles === 'OWNER' && <PopoverClose>
                        <div
                            className={'text-start py-[10px] px-[12px] hover:bg-marcoOnHover rounded-lg text-[#222222] text-[14px] leading-[20px] font-normal'}
                            onClick={() => {
                                navigate(routes.companyDetails)
                            }}>Company details
                        </div>
                    </PopoverClose>}
                </section>

                <PopoverClose className={'pt-2 px-2 pb-2'}>
                    <div
                        className={'text-start  py-[10px] px-[12px] hover:bg-marcoOnHover rounded-lg text-[#222222] text-[14px] leading-[20px] font-normal'}
                        onClick={() => {
                            useTokenStore.getState().resetToken();
                            navigate('/')
                        }}>Log out
                    </div>
                </PopoverClose>
            </PopoverContent>
        </Popover>
    </div>
}