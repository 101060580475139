import React, {ReactNode, useEffect, useState} from "react";
import {Badge} from "src/components/badge";
import {OrderFulfillmentStatus} from "src/utils/enums/orderFulfillmentStatus";
import {OrderDetails} from "src/utils/types/structures/orderDetails";

interface Props {
    order: OrderDetails
}

export default function ({order}: Props) {
    const [badge, setBadge] = useState<ReactNode | null>(null)

    useEffect(() => {
        switch (order.fulfillmentStatus) {

            case OrderFulfillmentStatus.notAccepted: {
                setBadge(
                    <Badge className={'flex gap-2 px-[0.625rem] py-2 h-8 bg-[#f2f2f2]'}>
                        <span className={'h-2 w-2 bg-transparent border-2 border-[#717171] rounded-full'}/>
                        <p className={'text-center text-[#717171] text-[12px] leading-[16px] font-medium'}>Not accepted</p>
                    </Badge>
                )
                break
            }

            case OrderFulfillmentStatus.accepted: {
                setBadge(
                    <Badge className={'flex gap-2 px-[0.625rem] py-2 h-8 bg-[#affebf]'}>
                        <span className={'h-2 w-2 bg-transparent border-2 border-[#014b40] rounded-full'}/>
                        <p className={'text-center text-[#014b40] text-[12px] leading-[16px] font-medium'}>Accepted</p>
                    </Badge>
                )
                break
            }

            case OrderFulfillmentStatus.dispatched: {
                setBadge(
                    <Badge className={'flex gap-2 px-[0.625rem] py-2 h-8 bg-[#affebf]'}>
                        <span className={'h-2 w-2 bg-transparent border-2 border-[#014b40] rounded-full'}/>
                        <p className={'text-center text-[#014b40] text-[12px] leading-[16px] font-medium'}>Dispatched</p>
                    </Badge>
                )
                break
            }

            case OrderFulfillmentStatus.arrivedAtPickup: {
                setBadge(
                    <Badge className={'flex gap-2 px-[0.625rem] py-2 h-8 bg-[#affebf]'}>
                        <span className={'h-2 w-2 bg-transparent border-2 border-[#014b40] rounded-full'}/>
                        <p className={'text-center text-[#014b40] text-[12px] leading-[16px] font-medium'}>At pickup</p>
                    </Badge>
                )
                break
            }

            case OrderFulfillmentStatus.atPickup: {
                setBadge(
                    <Badge className={'flex gap-2 px-[0.625rem] py-2 h-8 bg-[#affebf]'}>
                        <span className={'h-2 w-2 bg-transparent border-2 border-[#014b40] rounded-full'}/>
                        <p className={'text-center text-[#014b40] text-[12px] leading-[16px] font-medium'}>Pickup started</p>
                    </Badge>
                )
                break
            }

            case OrderFulfillmentStatus.waitingSignature: {
                setBadge(
                    <Badge className={'flex gap-2 px-[0.625rem] py-2 h-8 bg-[#ffeb78]'}>
                        <span className={'h-2 w-2 bg-transparent border-2 border-[#4f4700] rounded-full'}/>
                        <p className={'text-center text-[#4f4700] text-[12px] leading-[16px] font-medium'}>Waiting signature</p>
                    </Badge>
                )
                break
            }

            // waiting payment
            // case OrderFulfillmentStatus.waitingSignature: {
            //     setBadge(
            //         <Badge variant={'waitingSignature'} className={'flex gap-2 px-[0.625rem] py-2 h-8'}>
            //             <EmptyPipIcon size={8} stroke={'#916A00'}/>
            //             <p className={'text-center text-neutral-800 text-xs font-medium font-inter leading-[16px]'}>Waiting signature</p>
            //         </Badge>
            //     )
            //     break
            // }

            case OrderFulfillmentStatus.pickupStarted: {
                setBadge(
                    <Badge className={'flex gap-2 px-[0.625rem] py-2 h-8 bg-[#affebf]'}>
                        <span className={'h-2 w-2 bg-transparent border-2 border-[#014b40] rounded-full'}/>
                        <p className={'text-center text-[#014b40] text-[12px] leading-[16px] font-medium'}>Pickup started</p>
                    </Badge>
                )
                break
            }

            case OrderFulfillmentStatus.inTransit: {
                setBadge(
                    <Badge className={'flex gap-2 px-[0.625rem] py-2 h-8 bg-[#ffeb78]'}>
                        <span className={'h-2 w-2 bg-transparent border-2 border-[#4f4700] rounded-full'}/>
                        <p className={'text-center text-[#4f4700] text-[12px] leading-[16px] font-medium'}>In transit</p>
                    </Badge>
                )
                break
            }

            case OrderFulfillmentStatus.completePickup: {
                setBadge(
                    <Badge className={'flex gap-2 px-[0.625rem] py-2 h-8 bg-[#ffeb78]'}>
                        <span className={'h-2 w-2 bg-transparent border-2 border-[#4f4700] rounded-full'}/>
                        <p className={'text-center text-[#4f4700] text-[12px] leading-[16px] font-medium'}>In transit</p>
                    </Badge>
                )
                break
            }

            case OrderFulfillmentStatus.startDelivery: {
                setBadge(
                    <Badge className={'flex gap-2 px-[0.625rem] py-2 h-8 bg-[#ffeb78]'}>
                        <span className={'h-2 w-2 bg-transparent border-2 border-[#4f4700] rounded-full'}/>
                        <p className={'text-center text-[#4f4700] text-[12px] leading-[16px] font-medium'}>In transit</p>
                    </Badge>
                )
                break
            }

            case OrderFulfillmentStatus.completeDelivery: {
                setBadge(
                    <Badge className={'flex gap-2 px-[0.625rem] py-2 h-8 bg-[#ffeb78]'}>
                        <span className={'h-2 w-2 bg-transparent border-2 border-[#4f4700] rounded-full'}/>
                        <p className={'text-center text-[#4f4700] text-[12px] leading-[16px] font-medium'}>Completed</p>
                    </Badge>
                )
                break
            }
        }

    }, [order]);

    return <>{badge}</>
}