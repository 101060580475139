import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "src/components/button";
import useSheet from "src/pages/dashboarSection/layout/popUps/sheet/useSheet";
import {getDocument} from "src/utils/apiCalls/getDocument";
import {getDocumentsList} from "src/utils/apiCalls/orderDetails/documents/getDocumentsList";
import routes from "src/utils/defaults/routes";
import {DocumentStatus} from "src/utils/enums/documentStatus";
import {handleResponseError} from "src/utils/errorHandlers/handleResponseError";
import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import {getDocumentTypeString} from "src/utils/stringHandlers/orderDetailsStrings/getDocumentTypeString";
import {ErrorMessage} from "src/utils/types/errorMessage";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import {OrderDocument} from "src/utils/types/structures/orderDocument";

interface Props {
    order: OrderDetails
}

export default function OrderDocuments({order}: Props) {

    const [orderDocuments, setOrderDocuments] = useState<OrderDocument[]>([]);
    const navigate = useNavigate()
    const {close} = useSheet()

    async function setDocuments() {
        const documents = await getDocumentsList(order.id)
        if (isInstanceOf<ErrorMessage>(documents, 'message')) {
            handleResponseError(documents, () => navigate(routes.login))
        } else {
            setOrderDocuments(documents)
        }
    }

    async function openDocument(el: OrderDocument) {
        const res = await getDocument(el.id)
        if (isInstanceOf<ErrorMessage>(res, 'message')) {
            handleResponseError(res, () => navigate(routes.login))
        } else {

            const imageURL = URL.createObjectURL(res)
            const link = document.createElement('a')
            link.href = imageURL
            //link.download = `${el.id}.pdf`
            //document.body.appendChild(link)
            link.click()
        }
    }

    useEffect(() => {
        setDocuments()
    }, [order]);

    return <div className={'flex flex-col h-[calc(100dvh-4.25rem)]'}>
        <section key={'document-list'} className={'grow'}>
            {orderDocuments.length > 0
                ? <ul className={'pt-5 px-6 flex flex-col gap-6'}>
                    {orderDocuments.map(el => <li key={el.id} className={'flex justify-between items-center'}>
                        <div className={'flex flex-col gap-1'}>
                        <span
                            className={'text-base font-medium text-foreground'}>{getDocumentTypeString(el.documentType)}</span>
                            <span
                                className={'text-sm text-muted-foreground'}>Meta data</span>
                        </div>

                        {el.documentStatus === DocumentStatus.created && <div className={'text-red-700 text-sm font-medium underline leading-tight'}>Waiting for signature</div>}

                        {el.documentStatus === DocumentStatus.downloaded
                            && <Button onClick={() => openDocument(el)}
                                                                                    variant={'ghost'}
                                                                                    disabled={el.documentStatus !== DocumentStatus.downloaded}
                                                                                    className={'text-sm font-medium flex items-center cursor-pointer'}>View
                        </Button>}

                    </li>)}
                </ul>
                : <div className={'pt-5 px-6 flex flex-col'}>
                    <h2 className={'text-foreground text-[1.375rem] eading-[1.625rem] font-semibold'}>No documents</h2>
                    <p className={'text-foreground text-base pt-3 pb-6'}>The order has no documents assigned.</p>
                </div>}
        </section>

        <section key={'close-section'}
                 className={'sticky bottom-0 bg-background z-30 flex-none grid h-20 place-items-center border-t'}>
            <span onClick={close} className={'text-base font-medium underline leading-normal cursor-pointer'}>Close</span>
        </section>
    </div>
}