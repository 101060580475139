import * as React from "react";
import {Button} from "src/components/button";
import {Popover, PopoverContent, PopoverTrigger} from "src/components/popOver";
import {ShevronDown} from "src/media/icons/custom/shevron/shevronDown";
import VolumePopOverContent from "src/pages/dashboarSection/search/searchLayout/filters/volumePopOverContent";

import {Colors} from "src/utils/defaults/colors";
import {defaultFilterValues} from "src/utils/defaults/defaultFilterValues";
import useSearch from "src/utils/zustandStores/useSearch";


export default function VolumePopOver() {
    const {response} = useSearch();

    return <Popover>
        <PopoverTrigger asChild>
            <Button
                id="date"
                variant={"rounded"}
                size={"tab"}
                className={`
                        ${response.body.startVolume !== defaultFilterValues.startVolume && 'outline outline-foreground outline-2 outline-offset-[-2px]'} 
                        ${response.body.endVolume !== defaultFilterValues.endVolume && 'outline outline-foreground outline-2 outline-offset-[-2px]'}
                        rounded-full flex gap-2`}
            >
                <span className={'text-foreground text-[14px] leading-[20px] font-medium'}>Volume</span>
                <ShevronDown width={10} height={6} stroke={Colors.foreground}/>
            </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[22.5rem] p-0 rounded-md" align="start" sticky={'always'}>
            <VolumePopOverContent/>
        </PopoverContent>
    </Popover>

}