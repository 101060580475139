import React from "react";
import {Button} from "src/components/button";
import {Separator} from "src/components/separator";
import {ChatIcon} from "src/media/icons/custom/chatIcon";
import {PhoneIcon} from "src/media/icons/custom/phoneIcon";
import {TextChat} from "src/media/icons/custom/textChat";
import useDialog from "src/pages/dashboarSection/layout/popUps/dialog/useDialog";
import {OrderDetails} from "src/utils/types/structures/orderDetails";

interface Props {
    order: OrderDetails
}

const ContactDetails = ({order}: Props) => {
    const {close} = useDialog()

    return <div className={'flex flex-col h-full'}>

        <div className={'flex-1 px-6 pb-12'}>
            <p className={'text-[#717171] text-[14px] leading-[20px] font-normal'}>Need to coordinate with a customer
                about a project? Choose a contact option below to connect directly and ensure a smooth service
                experience.</p>

            <div className={'flex flex-col gap pt-4'}>

                <section className={'flex gap-4 items-center py-4'}>
                    <div className={'p-1'}>
                        <TextChat size={24}/>
                    </div>

                    <div className={'flex flex-col gap-1'}>
                        <p className={'text-[#222222] text-[16px] leading-[20px] font-medium'}>Chat customer</p>
                        <p className={'text-[#717171] text-[16px] leading-[24px] font-normal'}>Coming soon</p>
                    </div>
                </section>

                <Separator/>

                <a href={`tel:${order.customerPhone}`} className={'flex gap-4 items-center py-4 cursor-pointer'}>
                    <div className={'p-1'}>
                        <PhoneIcon size={24}/>
                    </div>

                    <div className={'flex flex-col gap-1'}>
                        <p className={'text-[#222222] text-[16px] leading-[20px] font-medium'}>Call customer</p>
                        <p className={'text-[#222222] text-[16px] leading-[24px] font-normal'}>{order.customerPhone}</p>
                    </div>
                </a>

                <Separator/>

                <a href={`sms:${order.customerPhone}`} className={'flex gap-4 items-center py-4 cursor-pointer'}>
                    <div className={'p-1'}>
                        <ChatIcon size={24}/>
                    </div>

                    <div className={'flex flex-col gap-1'}>
                        <p className={'text-[#222222] text-[16px] leading-[20px] font-medium'}>Text customer</p>
                        <p className={'text-[#222222] text-[16px] leading-[24px] font-normal'}>{order.customerPhone}</p>
                    </div>
                </a>
            </div>
        </div>

        <Separator/>

        <div className={'flex-none px-6 flex justify-end items-center py-4'}>
            <Button variant={'default'} size={'lg'} type={'button'} onClick={close}
                    className={'w-fit text-white text-base font-medium leading-5'}>Close</Button>
        </div>
    </div>
}

export default ContactDetails