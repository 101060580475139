import {Button} from "../../../components/button";
import {useNavigate} from "react-router-dom";
import routes from "../../../utils/defaults/routes";

export default function Inbox() {

    const navigate = useNavigate();

    return <div className={'grid items-center place-content-center w-full h-full'}>
        <section className={'w-fit h-fit flex flex-col items-center'}>
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M42 30C42 31.0609 41.5786 32.0783 40.8284 32.8284C40.0783 33.5786 39.0609 34 38 34H14L6 42V10C6 8.93913 6.42143 7.92172 7.17157 7.17157C7.92172 6.42143 8.93913 6 10 6H38C39.0609 6 40.0783 6.42143 40.8284 7.17157C41.5786 7.92172 42 8.93913 42 10V30Z"
                    stroke="#CCCCCC" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>

            <p className={'text-[#222222] text-[18px] md:text-[20px] leading-[24px] md:leading-[28px] font-medium pt-4'}>You don’t have any messages yet.</p>
            <p className={'text-[#222222] text-[16px] leading-[24px] font-normal pt-4'}>When you do, you can find them here.</p>
            <Button onClick={() => navigate(routes.search)} variant={'outline'} className={'mt-4 text-[#222222] text-[14px] leading-[16px] font-medium'}>Search for new loads</Button>
        </section>
    </div>
}