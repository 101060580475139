import * as React from "react";
import {useState} from "react";
import {Button} from "src/components/button";
import {Popover, PopoverContent, PopoverTrigger} from "src/components/popOver";
import {TableCell} from "src/components/table";
import {ThreeDotsVertical} from "src/media/icons/custom/threeDotsVertical";

import PopoverMembersContent from "src/pages/dashboarSection/fleetManagement/popoverMembersContent";
import {Colors} from "src/utils/defaults/colors";
import {Member} from "src/utils/types/structures/member";

interface Props {
    member: Member
    mutateMembers: () => void
}

export default function TableDots({member, mutateMembers}: Props) {
    const [isOpen, setOpen] = useState<boolean>(false)

    return <TableCell className={'p-0 w-12 min-w-12'}>
        <Popover open={isOpen}>
            <PopoverTrigger asChild>
                <section className={'grid w-full'}>
                    <Button variant={'ghost'} size={'iconFit'} className={'place-self-center p-[0.375rem]'} onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation()
                        setOpen(true)
                    }}><ThreeDotsVertical size={20} stroke={Colors.foreground}/></Button>
                </section>
            </PopoverTrigger>
            <PopoverContent className="w-[124px] h-fit p-0" align="end">
                <PopoverMembersContent member={member} mutateMembers={() => mutateMembers()} setOpen={setOpen} isOpen={isOpen}/>
            </PopoverContent>
        </Popover>
    </TableCell>
}