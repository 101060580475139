import type {UseFormReturn} from "react-hook-form";
import React from "react";
import {FormControl, FormField, FormItem} from "src/components/form";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "src/components/select";

interface Props {
  form: UseFormReturn<{
    access: string;
    floorNumber: string;
    googleMapAddress: string;
    manualApt?: string;
    manualCity: string;
    manualCountry: string;
    manualState: string;
    manualStreetAddress: string;
    manualZip: string;

    numberStories: string;
    propertySize: string;
    propertyType: string;
    squareFootage: string;
    unitNumber: string;
    unitSize: string;
  }>;
}

export default function AppartmentAccess({ form }: Props) {
  return (

      <section className={'flex flex-col gap-2'}>

        <p className={`text-[14px] leading-[16px] font-medium ${
            form.getFieldState("access").invalid
                ? "text-[#C6241D] outline-[#C6241D] outline-2"
                : ""
        }`}>Stairs/Elevator</p>

        <FormField
            control={form.control}
            name="access"
            render={({field}) => (
                <FormItem className="flex-1">
                  <Select
                      onValueChange={(e) => {
                        form.setValue("access", e);
                        form.clearErrors("access");
                      }}
                      value={field.value}
                  >
                    <FormControl>
                      <SelectTrigger
                          className={`${
                              form.getFieldState("access").invalid
                                  ? "text-[#C6241D] outline-[#C6241D] outline-2"
                                  : ""
                          }`}
                      >
                        <div
                            className={`${
                                form.getValues("access") === ""
                                    ? "text-[#717171] pl-1"
                                    : "pl-1 text-marco_default_foreground"
                            }
                  ${form.getFieldState("access").invalid ? "!text-[#C6241D]" : ""}
                  text-[16px] leading-[24px] font-normal`}
                        >
                          <SelectValue placeholder="Stairs/Elevator" />
                        </div>
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem key={"ELEVATOR"} value={"ELEVATOR"}>
                        Elevator
                      </SelectItem>

                      <SelectItem key={"NO_STAIRS"} value={"NO_STAIRS"}>
                        No Stairs
                      </SelectItem>

                      <SelectItem
                          key={"ONE_FLIGHT_OF_STAIRS"}
                          value={"ONE_FLIGHT_OF_STAIRS"}
                      >
                        1 Flight of Stairs
                      </SelectItem>

                      <SelectItem
                          key={"TWO_FLIGHT_OF_STAIRS"}
                          value={"TWO_FLIGHT_OF_STAIRS"}
                      >
                        2 Flights of Stairs
                      </SelectItem>

                      <SelectItem
                          key={"THREE_FLIGHT_OF_STAIRS"}
                          value={"THREE_FLIGHT_OF_STAIRS"}
                      >
                        3 Flights of Stairs
                      </SelectItem>

                      <SelectItem
                          key={"FOUR_FLIGHT_OF_STAIRS"}
                          value={"FOUR_FLIGHT_OF_STAIRS"}
                      >
                        4 Flights of Stairs
                      </SelectItem>

                      <SelectItem
                          key={"FIVE_FLIGHT_OF_STAIRS"}
                          value={"FIVE_FLIGHT_OF_STAIRS"}
                      >
                        5 Flights of Stairs
                      </SelectItem>

                      <SelectItem
                          key={"SIX_FLIGHT_OF_STAIRS"}
                          value={"SIX_FLIGHT_OF_STAIRS"}
                      >
                        6 Flights of Stairs
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </FormItem>
            )}
        />
      </section>
  );
}
