import {Company} from "src/utils/types/structures/company";
import {Member} from "src/utils/types/structures/member";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import {create} from 'zustand'

export enum DialogElementType {
    default = 'DEFAULT',
    personalSettingsShowAvatar = 'PERSONAL_SETTINGS_SHOW_AVATAR',
    PINForm = 'PIN_FORM',
    completeUnboarding = 'COMPLETE_UNBOARDING',
    contactDetails = 'CONTACT_DETAILS',
    contactSupport = 'CONTACT_SUPPORT',
}

export type DialogDefinition = null | undefined | Member | OrderDetails | Company

interface State {
    isOpen: boolean
    type: DialogElementType
    value: DialogDefinition
    title: string
    mutateFirstSource?: () => void
    mutateSecondSource?: () => void
    resetDialog: () => void
}

interface Actions {
    open: (elementType: DialogElementType, value: DialogDefinition, title: string, mutateFirstSource?: () => void, mutateSecondSource?: () => void) => void
    close: () => void
}

const useDialog = create<State & Actions>(
    (set, get) => ({
        isOpen: false,
        type: DialogElementType.default,
        value: null,
        title: '',
        mutateFirstSource: () => {},
        mutateSecondSource: () => {},

        resetDialog: () => {
            set(
                (state) => (
                    {
                        isOpen: false,
                        type: DialogElementType.default,
                        value: null,
                        title: '',
                        mutateFirstSource: () => {},
                        mutateSecondSource: () => {},
                    }
                )
            )
        },

        open: (elementType: DialogElementType, value: DialogDefinition, title: string, mutateFirstSource?: () => void, mutateSecondSource?: () => void) => {
            set(
                (state) => (
                    {
                        isOpen: true,
                        type: elementType,
                        value: value,
                        title: title,
                        mutateFirstSource: mutateFirstSource,
                        mutateSecondSource: mutateSecondSource
                    }
                )
            )
        },

        close: () => {
            set(
                (state) => (
                    {
                        isOpen: false,
                        type: DialogElementType.default,
                        value: null,
                        title: '',
                        mutateFirstSource: () => {},
                        mutateSecondSource: () => {},
                    }
                )
            )
        },
    })
)

export default useDialog;
