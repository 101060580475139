import {zodResolver} from "@hookform/resolvers/zod";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {Button} from "src/components/button";
import {Form, FormControl, FormField, FormItem, FormMessage} from "src/components/form";
import {Input} from "src/components/input";
import {PhoneInput} from "src/components/phoneInput";
import {useToast} from "src/components/useToast";
import {RegistrationForm, sendApplication} from "src/utils/apiCalls/sendApplication";
import routes from "src/utils/defaults/routes";
import {handleResponseError} from "src/utils/errorHandlers/handleResponseError";
import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import {ErrorMessage} from "src/utils/types/errorMessage";
import registrationSchema from "src/utils/zodSchemas/registrationSchema";
import * as z from "zod";
import {isValidPhoneNumber} from "react-phone-number-input";


export default function Register() {
    const navigate = useNavigate();
    const {toast} = useToast();

    const form = useForm<z.infer<typeof registrationSchema>>({
        resolver: zodResolver(registrationSchema),
        defaultValues: {
            firstName: "",
            lastName: "",
            companyName: "",
            email: "",
            phoneNumber: "",
            DOT: "",
            MC: "",
            currentService: '',
            companySize: '',
            serviceArea: ''
        }
    });


    const [applicationStep, setApplicationStep] = useState<"partA" | "partB">("partA");

    const onNextHandle = () => {
        const firstName = form.getValues('firstName')
        const lastName = form.getValues('lastName')
        const companyName = form.getValues('companyName')
        const email = form.getValues('email')
        const phoneNumber = form.getValues('phoneNumber')

        const isValidFirstName = firstName.length > 0
        const isValidLastName = lastName.length > 0
        const isValidCompanyName = companyName.length > 0
        const isValidEmailName = email.length > 0 && email.includes('@') && email.includes('.');
        const isValidPhone  = isValidPhoneNumber(phoneNumber)

        if (isValidFirstName && isValidLastName && isValidCompanyName && isValidEmailName && isValidPhone) {
            setApplicationStep("partB");
        } else {
         if (!isValidFirstName) form.setError('firstName', {message: ''})
         if (!isValidLastName) form.setError('lastName', {message: ''})
         if (!isValidCompanyName) form.setError('companyName', {message: ''})
         if (!isValidEmailName) form.setError('email', {message: ''})
         if (!isValidPhone) form.setError('phoneNumber', {message: ''})
        }
    }

    async function onSubmit(values: z.infer<typeof registrationSchema>) {

        const companySize = values.companySize
        const serviceOffer = values.currentService
        const serviceArea = values.serviceArea

        if (companySize.length <= 0 || serviceOffer.length <= 0 || serviceArea.length <= 0) {
            if (companySize.length <= 0) form.setError('companySize', {message: ''})
            if (serviceOffer.length <= 0) form.setError('currentService', {message: ''})
            if (serviceArea.length <= 0) form.setError('serviceArea', {message: ''})
        } else {
            const applicationData: RegistrationForm = {
                currentService: values.currentService,
                companyName: values.companyName,
                companySize: values.companySize,
                DOT: values.DOT,
                email: values.email,
                firstName: values.firstName,
                serviceArea: values.serviceArea,
                lastName: values.lastName,
                phoneNumber: values.phoneNumber,
                MC: values.MC
            }

            const data = await sendApplication(applicationData)
            if (isInstanceOf<ErrorMessage>(data, 'message')) {
                setApplicationStep("partA");
                handleResponseError(data, () => navigate(routes.login))
            } else {
                toast({
                    description: 'Application sent'
                })
                navigate(routes.thankYouScreen)
            }
        }




    }

    return <div className={"flex flex-col gap-0"}>

        <section className={'flex flex-col gap-2 pb-8'}>
            <h1 className={'font-inter text-[#222222] text-[24px] leading-[32px] font-medium'}>{applicationStep === 'partA' ? 'Tell us about yourself' : 'Tell us about your business'}</h1>
            <p className={'font-inter text-[#717171] text-[14px] leading-[20px] font-normal'}>{applicationStep === 'partA' ? 'Please share a bit about yourself, and we will reach out to you soon.' : 'Please share some information about your business with us.'}</p>
        </section>

        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>

                <div id={'partA step'} className={`${applicationStep === 'partB' && 'hidden'} flex flex-col gap-0`}>

                    <section className={'flex flex-row gap-4 pb-6'}>
                        <div className={'flex flex-col gap-2'}>
                            <p className={'font-inter text-[#222222] text-[14px] leading-[16px] font-medium'}>First
                                name</p>

                            <FormField
                                control={form.control}
                                name="firstName"
                                render={({field}) => (
                                    <FormItem className={"flex-1"}>
                                        {form.getFieldState('firstName').invalid && <FormMessage
                                            className={'text-marcoFormErrorTextColor text-sm h-3 pb-5 grid place-items-end'}/>}
                                        <FormControl>
                                            <Input
                                                {...field}
                                                autoComplete={'off'}
                                                className={`h-[48px] ${form.getFieldState('firstName').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`}
                                                placeholder={"First name"}
                                                onInput={() => form.clearErrors()}
                                            />
                                        </FormControl>
                                    </FormItem>
                                )}
                            />
                        </div>

                        <div className={'flex flex-col gap-2'}>
                            <p className={'font-inter text-[#222222] text-[14px] leading-[16px] font-medium'}>Last
                                name</p>

                            <FormField
                                control={form.control}
                                name="lastName"
                                render={({field}) => (
                                    <FormItem className={"flex-1"}>
                                        {form.getFieldState('lastName').invalid && <FormMessage
                                            className={'text-marcoFormErrorTextColor text-sm h-3 pb-5 grid place-items-end'}/>}
                                        <FormControl>
                                            <Input
                                                {...field}
                                                autoComplete={'off'}
                                                placeholder={"Last name"}
                                                onInput={() => form.clearErrors()}
                                                className={`h-[48px] ${form.getFieldState('lastName').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`}
                                            />
                                        </FormControl>
                                    </FormItem>
                                )}
                            />
                        </div>
                    </section>

                    <div className={'flex flex-col gap-2 pb-6'}>
                        <p className={'font-inter text-[#222222] text-[14px] leading-[16px] font-medium'}>Company
                            name</p>

                        <FormField
                            control={form.control}
                            name="companyName"
                            render={({field}) => (
                                <FormItem>
                                    {form.getFieldState('companyName').invalid && <FormMessage
                                        className={'text-marcoFormErrorTextColor text-sm h-3 pb-5 grid place-items-end'}/>}
                                    <FormControl>
                                        <Input
                                            {...field}
                                            autoComplete={'off'}
                                            onInput={() => form.clearErrors()}
                                            placeholder={"Company name"}
                                            className={`h-[48px] ${form.getFieldState('companyName').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`}
                                        />
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                    </div>

                    <div className={'flex flex-col gap-2 pb-6'}>
                        <p className={'font-inter text-[#222222] text-[14px] leading-[16px] font-medium'}>Email</p>

                        <FormField
                            control={form.control}
                            name="email"
                            render={({field}) => (
                                <FormItem>
                                    {form.getFieldState('email').invalid && <FormMessage
                                        className={'text-marcoFormErrorTextColor text-sm h-3 pb-5 grid place-items-end'}/>}
                                    <FormControl>
                                        <Input
                                            {...field}
                                            autoComplete={'off'}
                                            onInput={() => form.clearErrors()}
                                            placeholder={"Email address"}
                                            className={`h-[48px] ${form.getFieldState('email').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'} w-full`}
                                        />
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                    </div>

                    <div className={'flex flex-col gap-2 pb-6'}>
                        <p className={'font-inter text-[#222222] text-[14px] leading-[16px] font-medium'}>Phone number</p>

                        <FormField
                            control={form.control}
                            name="phoneNumber"
                            render={({field}) => (
                                <FormItem>

                                    {form.getFieldState('phoneNumber').invalid && <FormMessage
                                        className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium text-end'}/>}

                                    <FormControl>
                                        <PhoneInput
                                            {...field}
                                            autoComplete={'off'}
                                            className={'text-sm'}
                                            onInput={() => form.clearErrors()}
                                            isvalid={form.getFieldState('phoneNumber').invalid.toString()}
                                            placeholder={'Phone'}
                                        />
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                    </div>

                    <Button variant={'lightGreen'} className={'mb-6 h-[48px] text-[16px] leading-[20px]'} type={'button'} onClick={onNextHandle}>Continue</Button>
                </div>

                <div id={'partB step'} className={`${applicationStep === 'partA' && 'hidden'} flex flex-col gap-0`}>

                    <div className={'flex flex-col gap-2 pb-6'}>
                        <p className={'font-inter text-[#222222] text-[14px] leading-[16px] font-medium'}>Company
                            size</p>
                        <FormField
                            control={form.control}
                            name="companySize"
                            render={({field}) => (
                                <FormItem>
                                    {form.getFieldState('companySize').invalid && <FormMessage
                                        className={'text-marcoFormErrorTextColor text-sm h-3 pb-5 grid place-items-end'}/>}
                                    <FormControl>
                                        <Input
                                            {...field}
                                            autoComplete={'off'}
                                            placeholder={"e.g., 10 employees"}
                                            onInput={() => form.clearErrors()}
                                            className={`h-[48px] ${form.getFieldState('companySize').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'} w-full`}
                                        />
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                    </div>

                    <div className={'flex flex-col gap-2 pb-6'}>
                        <p className={'font-inter text-[#222222] text-[14px] leading-[16px] font-medium'}>Service you
                            offer</p>
                        <FormField
                            control={form.control}
                            name="currentService"
                            render={({field}) => (
                                <FormItem>
                                    {form.getFieldState('currentService').invalid && <FormMessage
                                        className={'text-marcoFormErrorTextColor text-sm h-3 pb-5 grid place-items-end'}/>}
                                    <FormControl>
                                        <Input
                                            {...field}
                                            autoComplete={'off'}
                                            placeholder={"e.g., Local Moving, Long-Distance"}
                                            onInput={() => form.clearErrors()}
                                            className={`h-[48px] ${form.getFieldState('currentService').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'} w-full`}
                                        />
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                    </div>

                    <div className={'flex flex-col gap-2 pb-6'}>
                        <p className={'font-inter text-[#222222] text-[14px] leading-[16px] font-medium'}>Service area</p>
                        <FormField
                            control={form.control}
                            name="serviceArea"
                            render={({field}) => (
                                <FormItem>
                                    {form.getFieldState('serviceArea').invalid && <FormMessage
                                        className={'text-marcoFormErrorTextColor text-sm h-3 pb-5 grid place-items-end'}/>}
                                    <FormControl>
                                        <Input
                                            {...field}
                                            autoComplete={'off'}
                                            placeholder={"e.g., San Francisco, Nationwide"}
                                            onInput={() => form.clearErrors()}
                                            className={`h-[48px] ${form.getFieldState('serviceArea').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'} w-full`}
                                        />
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                    </div>

                    <div className={'flex flex-row gap-4 pb-6'}>
                        <div className={'flex flex-col gap-2'}>
                            <p className={'font-inter text-[#222222] text-[14px] leading-[16px] font-medium'}>DOT# (if
                                applicable)</p>
                            <FormField
                                control={form.control}
                                name="DOT"
                                render={({field}) => (
                                    <FormItem className={"flex-1"}>
                                        {form.getFieldState('DOT').invalid && <FormMessage
                                            className={'text-marcoFormErrorTextColor text-sm h-3 pb-5 grid place-items-end'}/>}
                                        <FormControl>
                                            <Input
                                                {...field}
                                                autoComplete={'off'}
                                                placeholder={"Enter DOT number"}
                                                onInput={() => form.clearErrors()}
                                                className={`h-[48px] ${form.getFieldState('DOT').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`}
                                            />
                                        </FormControl>
                                    </FormItem>
                                )}
                            />
                        </div>

                        <div className={'flex flex-col gap-2'}>
                            <p className={'font-inter text-[#222222] text-[14px] leading-[16px] font-medium'}>MC# (if
                                applicable)</p>
                            <FormField
                                control={form.control}
                                name="MC"
                                render={({field}) => (
                                    <FormItem className={"flex-1"}>
                                        {form.getFieldState('MC').invalid && <FormMessage
                                            className={'text-marcoFormErrorTextColor text-sm h-3 pb-5 grid place-items-end'}/>}
                                        <FormControl>
                                            <Input
                                                {...field}
                                                autoComplete={'off'}
                                                placeholder={"Enter MC number"}
                                                onInput={() => form.clearErrors()}
                                                className={`h-[48px] ${form.getFieldState('MC').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`}
                                            />
                                        </FormControl>
                                    </FormItem>
                                )}
                            />
                        </div>
                    </div>

                    <Button variant={'lightGreen'} className={'mb-6 h-[48px] text-[16px] leading-[20px]'}
                            type={"submit"}>
                        Submit
                    </Button>
                </div>
            </form>
        </Form>

        <div className={'flex flex-row gap-1 items-center'}>
            <span
                className={'font-inter text-[14px] leading-[20px] text-[#222222] font-normal'}>Already registered?</span>
            <span
                className={"font-inter text-[14px] leading-[20px] text-[#222222] font-medium underline cursor-pointer"}
                onClick={() => navigate(routes.main)}>
                            Sign In
                        </span>
        </div>
    </div>
}