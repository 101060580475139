import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import routes from "../../../utils/defaults/routes";

export default function OnboardingSuccess() {
    const navigate = useNavigate()

    useEffect(() => {
        navigate(routes.search)
    }, []);

    return null
}