import {zodResolver} from "@hookform/resolvers/zod";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import * as RPNInput from "react-phone-number-input";
import {useNavigate} from "react-router-dom";
import {Avatar, AvatarFallback, AvatarImage} from "src/components/avatar";
import {Button} from "src/components/button";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "src/components/form";
import {Input} from "src/components/input";
import {PhoneInput} from "src/components/phoneInput";
import {Separator} from "src/components/separator";
import useDialog, {DialogElementType} from "src/pages/dashboarSection/layout/popUps/dialog/useDialog";
import {AccountUserCredentials, editAccountUserCredentials} from "src/utils/apiCalls/editAccoountUserCredentials";
import fetcher from "src/utils/apiCalls/fetcher";
import {ApiHost} from "src/utils/defaults/apiHost";
import routes from "src/utils/defaults/routes";
import {handleResponseError} from "src/utils/errorHandlers/handleResponseError";
import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import {getFirstLetters} from "src/utils/stringHandlers/getFirstLetters";
import getString, {ElementTag} from "src/utils/stringHandlers/getString";
import {ErrorMessage} from "src/utils/types/errorMessage";
import {Member} from "src/utils/types/structures/member";
import personalDetails from "src/utils/zodSchemas/personalDetails";
import useUserHeader from "src/utils/zustandStores/userHeader/useUserHeader";
import useSWR from "swr";
import * as z from "zod";
import {updatePassword} from "../../../../../utils/apiCalls/updatePassword";
import {PasswordInput} from "../../../../../components/passwordInput";
import checkValues, {FormCheck} from "../../../../../utils/stringHandlers/checkValues";
import {passwordStrength} from "check-password-strength";
import customOptions from "../../../../../utils/defaults/passwordRequirements";
import {SpinnerIcon} from "../../../../../media/icons/custom/spinnerIcon";

export enum CompanDetailsSection {
    name = 'NAME',
    emailAddress = 'EMAIL_ADDRESS',
    phoneNumber = 'PHONE_NUMBER',
    avatar = 'AVATAR',
    password = 'PASSWORD'
}

type IsDisabled = {
    name: boolean,
    emailAddress: boolean,
    phoneNumber: boolean,
    avatar: boolean,
    password: boolean,
    currentSection: CompanDetailsSection | null
}

type ResError = {
    error: string
}

type ResAccepted = {
    status: string
}

const defaultIsDisabled: IsDisabled = {
    name: false,
    emailAddress: false,
    phoneNumber: false,
    avatar: false,
    password: false,
    currentSection: null
}

export default function PersonalDetails() {
    const [isDisabled, setIsDisabled] = useState<IsDisabled>(defaultIsDisabled);
    const [isLoading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const {setUser} = useUserHeader()
    const {open} = useDialog();
    const [formChecker, setFormChecker] = useState<FormCheck | null>(null)

    const form = useForm<z.infer<typeof personalDetails>>({
        resolver: zodResolver(personalDetails),
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            currentPassword: '',
            newPassword: '',
            repeatPassword: ''
        }
    });

    const {data: user, error, mutate: mutateUser} = useSWR<Member>(ApiHost + encodeURI(`api/v1/auth/profile`), fetcher);

    useEffect(() => {
        if (error) {
            handleResponseError(error, () => navigate(routes.login))
        }
    }, [error]);

    useEffect(() => {
        if (user) {
            form.setValue('firstName', user.firstName)
            form.setValue('lastName', user.lastName)
            form.setValue('email', user.email)
            form.setValue('phoneNumber', user.phone)
            form.setValue('currentPassword', '')
            form.setValue('repeatPassword', '')
            form.setValue('newPassword', '')
        }
    }, [user]);

    async function onSubmit(values: z.infer<typeof personalDetails>) {
        if (user) {
            setLoading(true)

            if (isDisabled.currentSection === 'PASSWORD') {

                if (values.currentPassword.length <= 0) {
                    form.setError('currentPassword', {message: ''})
                    setLoading(false)
                }

                if (values.newPassword.length <= 0 || values.repeatPassword.length <= 0) {
                    form.setError('newPassword', {message: ''})
                    form.setError('repeatPassword', {message: ''})
                    setLoading(false)
                }

                if (values.newPassword.length > 0 && values.repeatPassword.length > 0 && values.newPassword !== values.repeatPassword) {
                    form.setError('repeatPassword', {message: 'passwords do not match'})
                } else {
                    if (values.currentPassword.length > 0) {
                        const passStrengthResult = passwordStrength(values.newPassword, customOptions).value
                        if (passStrengthResult === 'Too weak' || passStrengthResult === 'Weak') {
                            form.setError('newPassword', {message: 'weak password'})
                            setLoading(false)
                        } else {
                            const res = await updatePassword(values.currentPassword, values.newPassword)

                            switch (true) {
                                case (isInstanceOf<ErrorMessage>(res, 'message')): {
                                    handleResponseError(res, () => navigate('/'))
                                    setLoading(false)
                                    break
                                }

                                case (isInstanceOf<ResError>(res, 'error')): {
                                    form.setError('currentPassword', {message: res.error})
                                    setLoading(false)
                                    break
                                }

                                case (isInstanceOf<ResAccepted>(res, 'status')): {
                                    setIsDisabled({
                                        name: false,
                                        emailAddress: false,
                                        phoneNumber: false,
                                        avatar: false,
                                        password: false,
                                        currentSection: null
                                    })
                                    window.scrollTo(0, 0)
                                    setLoading(false)
                                    break
                                }
                            }
                        }
                    }
                }
            } else {
                const updatedMember: AccountUserCredentials = {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    phone: values.phoneNumber,
                }

                const res = await editAccountUserCredentials(updatedMember)
                if (isInstanceOf<ErrorMessage>(res, 'message')) {
                    handleResponseError(res, () => navigate(routes.login))
                } else {
                    await mutateUser()
                    setUser(res)
                    setIsDisabled({
                        name: false,
                        emailAddress: false,
                        phoneNumber: false,
                        avatar: false,
                        password: false,
                        currentSection: null
                    })
                    window.scrollTo(0, 0)
                }
                setLoading(false)
            }
        }
    }

    if (user) {
        return <div className={`w-full flex flex-col gap-0 px-5 md:px-6 lg:px-0 pt-8 container max-auto max-w-[560px]`}>

            <svg className={'md:hidden cursor-pointer mb-6'} onClick={() => navigate('/settings')} width="40" height="40"
                 viewBox="0 0 40 40" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <rect width="40" height="40" rx="20" fill="#F7F7F7"/>
                <path d="M25.8332 20H14.1665" stroke="#222222" strokeWidth="1.5" strokeLinecap="round"
                      strokeLinejoin="round"/>
                <path d="M19.9998 25.8333L14.1665 20L19.9998 14.1666" stroke="#222222" strokeWidth="1.5"
                      strokeLinecap="round" strokeLinejoin="round"/>
            </svg>

            <h1 className={`text-[#222222] text-[24px] leading-[32px] font-medium`}>Account details</h1>

            <p className={'pt-3 text-[#717171] text-[16px] leading-[24px] font-normal'}>Provide personal details and how we can reach you.</p>

            <div key={'avatar'} className={'pt-4 lg:pt-8 w-fit flex flex-col items-center relative'}>
                <Avatar className={'w-[96px] h-[96px]'}>
                    <AvatarImage src={(user.avatar && user.avatar !== '') ? `${user.avatar}` : undefined}/>
                    <AvatarFallback>{getFirstLetters(user.firstName, user.lastName)}</AvatarFallback>
                </Avatar>

                <div className={'absolute bottom-[-10px] '}>
                    <Button
                        type={'button'}
                        disabled={isDisabled.avatar}
                        onClick={() => {
                            window.scrollTo(0, 0)
                            open(DialogElementType.personalSettingsShowAvatar, user, 'Edit profile picture', () => mutateUser())
                        }} variant={'ghost'} size={'sm'}
                        className={`disabled:opacity-100 disabled:text-opacity-100 disabled:text-[#222222] bg-white rounded-[1.875rem] shadow-light ${isDisabled.avatar ? 'text-[#ebebeb]' : 'text-[#222222]'} text-[14px] leading-[16px] font-mediums`}>Edit</Button>
                </div>
            </div>

            <h2 className={'pt-[26px] lg:pt-[34px] text-[#222222] text-[20px] leading-[28px] font-medium'}>Basic info</h2>

            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <div className={'flex flex-col pt-4 lg:pt-6 gap-6'}>

                        <section key={'name'} className={'flex flex-col'}>
                            <div className={'flex justify-between items-center mt-6 h-5'}>
                                <h2 className={`${isDisabled.name ? 'text-[#ebebeb]' : 'text-[#222222]'} text-[16px] leading-[20px] font-medium`}>Name</h2>
                                <Button
                                    disabled={isDisabled.name}
                                    onClick={() => {
                                        if (isDisabled.currentSection === CompanDetailsSection.name) {
                                            setIsDisabled({
                                                name: false,
                                                emailAddress: false,
                                                phoneNumber: false,
                                                avatar: false,
                                                password: false,
                                                currentSection: null
                                            })
                                            form.setValue('firstName', user.firstName)
                                            form.setValue('lastName', user?.lastName)
                                            window.scrollTo(0, 0)
                                        } else {
                                            setIsDisabled({
                                                name: false,
                                                emailAddress: true,
                                                phoneNumber: true,
                                                avatar: true,
                                                password: true,
                                                currentSection: CompanDetailsSection.name
                                            })
                                        }
                                    }} type={'button'} variant={'textGhost'} size={'textForm'}
                                    className={`${isDisabled.name ? 'text-[#ebebeb]' : 'text-[#222222]'} text-[14px] leading-[20px] font-medium`}>{isDisabled.currentSection === CompanDetailsSection.name ? 'Cancel' : 'Edit'}</Button>
                            </div>

                            <p className={`${(isDisabled.currentSection === CompanDetailsSection.name) && 'hidden'} ${isDisabled.name ? 'text-[#ebebeb]' : 'text-[#717171]'} mt-2 text-[16px] leading-[24px] font-normal`}>
                                {getString([{element: user.firstName, tag: ElementTag.name}, {
                                    element: user.lastName,
                                    tag: ElementTag.name
                                },])}
                            </p>

                            {(isDisabled.currentSection === CompanDetailsSection.name) &&
                                <div className={'pt-1 flex flex-col gap-6'}>
                                    <p className={`pt-1 text-[#717171] text-[14px] leading-[20px] font-normal`}>The
                                        designated company contact person for communication with customers or the Marco
                                        platform is either the company owner or a legal representative.</p>

                                    <div className={"flex gap-4"}>
                                        <FormField
                                            control={form.control}
                                            name="firstName"
                                            render={({field}) => (
                                                <FormItem className={"flex-1"}>

                                                    <div className={'grid grid-cols-2'}>
                                                        <FormLabel className={'text-sm leading-3'}>First
                                                            name</FormLabel>
                                                        {form.getFieldState('firstName').invalid && <FormMessage
                                                            className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium place-self-end'}/>}
                                                    </div>

                                                    <FormControl>
                                                        <Input
                                                            {...field}
                                                            className={`${form.getFieldState('firstName').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-[#222222]'}`}
                                                            placeholder={"First name"}
                                                        />
                                                    </FormControl>
                                                </FormItem>
                                            )}
                                        />

                                        <FormField
                                            control={form.control}
                                            name="lastName"
                                            render={({field}) => (
                                                <FormItem className={"flex-1"}>

                                                    <div className={'grid grid-cols-2'}>
                                                        <FormLabel className={'text-sm leading-3'}>Last
                                                            name</FormLabel>
                                                        {form.getFieldState('lastName').invalid && <FormMessage
                                                            className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium place-self-end'}/>}
                                                    </div>

                                                    <FormControl>
                                                        <Input
                                                            {...field}
                                                            placeholder={"Last name"}
                                                            className={`${form.getFieldState('lastName').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-[#222222]'}`}
                                                        />
                                                    </FormControl>
                                                </FormItem>
                                            )}
                                        />
                                    </div>
                                    <Button type={'submit'} size={'lg'} className={'w-fit text-white text-[16px] leading-[20px] font-medium px-[20px] relative'}>
                                        <SpinnerIcon className={`${isLoading ? 'visible' : 'invisible'} fill-marcoWhite absolute`} size={20}/>
                                        <p className={`${isLoading ? 'invisible' : 'visible'} px-5 text-center text-white text-[16px] leading-[20px] font-medium`}>Save</p>
                                    </Button>
                                </div>}
                        </section>

                        <Separator className={'mb-4'}/>

                        <section key={'email-address'} className={'flex flex-col'}>
                            <div className={'flex justify-between items-center h-5'}>
                                <h2 className={`${isDisabled.emailAddress ? 'text-[#ebebeb]' : 'text-[#222222]'} text-[16px] leading-[20px] font-medium`}>Email
                                    address</h2>
                                <Button
                                    disabled={isDisabled.emailAddress}
                                    onClick={() => {
                                        if (isDisabled.currentSection === CompanDetailsSection.emailAddress) {
                                            setIsDisabled({
                                                name: false,
                                                emailAddress: false,
                                                phoneNumber: false,
                                                avatar: false,
                                                password: false,
                                                currentSection: null
                                            })
                                            form.setValue('email', user?.email)
                                            window.scrollTo(0, 0)
                                        } else {
                                            setIsDisabled({
                                                name: true,
                                                emailAddress: false,
                                                phoneNumber: true,
                                                avatar: true,
                                                password: true,
                                                currentSection: CompanDetailsSection.emailAddress
                                            })
                                        }
                                    }} type={'button'} size={'textForm'} variant={'textGhost'}
                                    className={`${isDisabled.emailAddress ? 'text-[#ebebeb]' : 'text-[#222222]'} text-[14px] leading-[20px] font-medium`}>{isDisabled.currentSection === CompanDetailsSection.emailAddress ? 'Cancel' : 'Edit'}</Button>
                            </div>

                            <p className={`mt-2 ${(isDisabled.currentSection === CompanDetailsSection.emailAddress) && 'hidden'} ${isDisabled.emailAddress ? 'text-[#ebebeb]' : 'text-[#717171]'} text-[16px] leading-[24px] font-normal`}>{user.email}</p>

                            {(isDisabled.currentSection === CompanDetailsSection.emailAddress) &&
                                <div className={'pt-1 flex flex-col gap-6'}>
                                    <p className={`pt-1 text-[#717171] text-[14px] leading-[20px] font-normal`}>The
                                        primary email address for communication with confirmed customers or the Marco
                                        platform.</p>

                                    <FormField
                                        control={form.control}
                                        name="email"
                                        render={({field}) => (
                                            <FormItem className={"flex-1"}>

                                                <div className={'grid grid-cols-2'}>
                                                    <FormLabel className={'text-sm leading-3'}>Email</FormLabel>
                                                    {form.getFieldState('email').invalid && <FormMessage
                                                        className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium place-self-end'}/>}
                                                </div>

                                                <FormControl>
                                                    <Input
                                                        {...field}
                                                        placeholder={"Email"}
                                                        className={`${form.getFieldState('email').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-[#222222]'}`}
                                                    />
                                                </FormControl>
                                            </FormItem>
                                        )}
                                    />
                                    <Button type={'submit'} size={'lg'} className={'w-fit text-white text-[16px] leading-[20px] font-medium px-[20px] relative'}>
                                        <SpinnerIcon className={`${isLoading ? 'visible' : 'invisible'} fill-marcoWhite absolute`} size={20}/>
                                        <p className={`${isLoading ? 'invisible' : 'visible'} px-5 text-center text-white text-[16px] leading-[20px] font-medium`}>Save</p>
                                    </Button>
                                </div>}
                        </section>

                        <Separator className={'mb-4'}/>

                        <section key={'phone-number'} className={'flex flex-col'}>
                            <div className={'flex justify-between items-center h-5'}>
                            <h2 className={`${isDisabled.phoneNumber ? 'text-[#ebebeb]' : 'text-[#222222]'} text-[16px] leading-[20px] font-medium`}>Phone
                                    number</h2>
                                <Button
                                    disabled={isDisabled.phoneNumber}
                                    onClick={() => {
                                        if (isDisabled.currentSection === CompanDetailsSection.phoneNumber) {
                                            setIsDisabled({
                                                name: false,
                                                emailAddress: false,
                                                phoneNumber: false,
                                                avatar: false,
                                                password: false,
                                                currentSection: null
                                            })
                                            form.setValue('phoneNumber', user?.phone)
                                            window.scrollTo(0, 500)
                                        } else {
                                            setIsDisabled({
                                                name: true,
                                                emailAddress: true,
                                                phoneNumber: false,
                                                avatar: true,
                                                password: true,
                                                currentSection: CompanDetailsSection.phoneNumber
                                            })
                                            window.scrollTo(0, 500)
                                        }
                                    }} type={'button'} size={'textForm'} variant={'textGhost'}
                                    className={`${isDisabled.phoneNumber ? 'text-[#ebebeb]' : 'text-[#222222]'} text-[14px] leading-[20px] font-medium`}>{isDisabled.currentSection === CompanDetailsSection.phoneNumber ? 'Cancel' : 'Edit'}</Button>
                            </div>

                            <p className={`pt-2 ${isDisabled.currentSection === CompanDetailsSection.phoneNumber && 'hidden'} ${isDisabled.phoneNumber ? 'text-[#ebebeb]' : 'text-[#717171]'} text-[16px] leading-[24px] font-normal`}>{RPNInput.formatPhoneNumber(user.phone)}</p>

                            {isDisabled.currentSection === CompanDetailsSection.phoneNumber &&
                                <div className={'pt-1 flex flex-col gap-6'}>
                                    <p className={`pt-1 text-[#717171] text-[14px] leading-[20px] font-normal`}>Contact
                                        number for confirmed customers and Marco to get in touch. Additional numbers can
                                        be
                                        added and their usage specified.</p>

                                    <FormField
                                        control={form.control}
                                        name="phoneNumber"
                                        render={({field}) => (
                                            <FormItem className={"flex-1"}>

                                                <div className={'grid grid-cols-2'}>
                                                    <FormLabel className={'text-sm leading-3'}>Phone
                                                        number</FormLabel>
                                                    {form.getFieldState('phoneNumber').invalid && <FormMessage
                                                        className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium place-self-end'}/>}
                                                </div>

                                                <FormControl>
                                                    <PhoneInput
                                                        {...field}
                                                        isvalid={form.getFieldState('phoneNumber').invalid.toString()}
                                                        placeholder={'Phone'}
                                                    />
                                                </FormControl>
                                            </FormItem>
                                        )}
                                    />

                                    <Button type={'submit'} size={'lg'} className={'w-fit text-white text-[16px] leading-[20px] font-medium px-[20px] relative'}>
                                        <SpinnerIcon className={`${isLoading ? 'visible' : 'invisible'} fill-marcoWhite absolute`} size={20}/>
                                        <p className={`${isLoading ? 'invisible' : 'visible'} px-5 text-center text-white text-[16px] leading-[20px] font-medium`}>Save</p>
                                    </Button>
                                </div>}
                        </section>

                        <Separator className={'mb-4'}/>

                        <section key={'password'} className={'flex flex-col'}>
                            <div className={'flex justify-between items-center h-5'}>
                                <h2 className={`${isDisabled.password ? 'text-[#ebebeb]' : 'text-[#222222]'} text-[16px] leading-[20px] font-medium`}>Password</h2>
                                <Button
                                    disabled={isDisabled.password}
                                    onClick={() => {
                                        if (isDisabled.currentSection === CompanDetailsSection.password) {
                                            setIsDisabled({
                                                name: false,
                                                emailAddress: false,
                                                phoneNumber: false,
                                                avatar: false,
                                                password: false,
                                                currentSection: null
                                            })
                                            window.scrollTo(0, 500)
                                        } else {
                                            setIsDisabled({
                                                name: true,
                                                emailAddress: true,
                                                phoneNumber: true,
                                                avatar: true,
                                                password: false,
                                                currentSection: CompanDetailsSection.password
                                            })
                                            window.scrollTo(0, 500)
                                        }
                                        form.clearErrors()
                                        form.setValue('currentPassword', '')
                                        form.setValue('newPassword', '')
                                        form.setValue('repeatPassword', '')
                                    }} type={'button'} size={'textForm'} variant={'textGhost'}
                                    className={`${isDisabled.password ? 'text-[#ebebeb]' : 'text-[#222222]'} text-[14px] leading-[20px] font-medium`}>{isDisabled.currentSection === CompanDetailsSection.password ? 'Cancel' : 'Edit'}</Button>
                            </div>

                            <p className={`mt-2 ${(isDisabled.currentSection === CompanDetailsSection.password) && 'hidden'} ${isDisabled.password ? 'text-[#ebebeb]' : 'text-[#717171]'} text-[16px] leading-[24px] font-normal`}>•••••••</p>

                            {(isDisabled.currentSection === CompanDetailsSection.password) &&
                                <div className={'pt-1 flex flex-col gap-6'}>
                                    <p className={`pt-1 text-[#717171] text-[14px] leading-[20px] font-normal`}>Your
                                        password must be at least 8 characters long and include at least one digit and
                                        one
                                        letter.</p>

                                    <div className={"flex flex-col gap-4"}>
                                        <FormField
                                            control={form.control}
                                            name="currentPassword"
                                            render={({field}) => (
                                                <FormItem className={"flex-1"}>

                                                    <div className={'flex justify-between'}>
                                                        <FormLabel className={'text-sm leading-3'}>Current
                                                            password</FormLabel>
                                                        {form.getFieldState('currentPassword').invalid &&
                                                            <FormMessage
                                                                className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium place-self-end'}/>}
                                                    </div>

                                                    <FormControl>
                                                        <PasswordInput
                                                            {...field}
                                                            autoComplete={'off'}
                                                            className={`${form.getFieldState('currentPassword').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-[#222222]'}`}
                                                            placeholder={"Current password"}
                                                        />
                                                    </FormControl>
                                                </FormItem>
                                            )}
                                        />

                                        <FormField
                                            control={form.control}
                                            name="newPassword"
                                            render={({field}) => (
                                                <FormItem className={"flex-1"}>
                                                    <div className={'flex justify-between'}>
                                                        <FormLabel className={'text-sm leading-3'}>New
                                                            password</FormLabel>
                                                        {form.getFieldState('newPassword').invalid &&
                                                            <FormMessage
                                                                className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium place-self-end'}/>}
                                                    </div>

                                                    <FormControl>
                                                        <PasswordInput
                                                            {...field}
                                                            placeholder={"New password"}
                                                            autoComplete={'off'}
                                                            className={`${form.getFieldState('newPassword').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-[#222222]'}`}
                                                            onInput={event => setFormChecker(checkValues(event.currentTarget.value))}
                                                        />
                                                    </FormControl>
                                                </FormItem>
                                            )}
                                        />

                                        <FormField
                                            control={form.control}
                                            name="repeatPassword"
                                            render={({field}) => (
                                                <FormItem className={"flex-1"}>

                                                    <div className={'flex justify-between'}>
                                                        <FormLabel className={'text-sm leading-3'}>Confirm
                                                            password</FormLabel>
                                                        {form.getFieldState('repeatPassword').invalid &&
                                                            <FormMessage
                                                                className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium place-self-end'}/>}
                                                    </div>

                                                    <FormControl>
                                                        <PasswordInput
                                                            {...field}
                                                            placeholder={"Confirm password"}
                                                            autoComplete={'off'}
                                                            className={`${form.getFieldState('repeatPassword').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-[#222222]'}`}
                                                        />
                                                    </FormControl>
                                                </FormItem>
                                            )}
                                        />

                                        <ul className={'pt-[8px] grid grid-cols-2 gap-y-3'}>
                                            <li className={`flex flex-row gap-2 items-center ${!formChecker && '!text-[#717171] !stroke-[#717171]'} ${formChecker?.isLengthCorrect ? 'text-[#29845a] stroke-[#29845a]' : 'text-[#e51c00] stroke-[#e51c00]'} font-inter text-[12px] leading-[16px] font-normal`}>
                                                <svg width="16" height="16" viewBox="0 0 17 17" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="0.5" y="0.5" width="15" height="15" rx="7.5"/>
                                                    <path
                                                        d="M6.94109 9.58847L11.5291 5L12.2353 5.70576L6.94109 11L3.76465 7.82356L4.47041 7.11779L6.94109 9.58847Z"/>
                                                </svg>
                                                At least 8 characters

                                            </li>
                                            <li className={`flex flex-row gap-2 items-center ${!formChecker && '!text-[#717171] !stroke-[#717171]'} ${formChecker?.isUpperPlusLowerLettersPresent ? 'text-[#29845a] stroke-[#29845a]' : 'text-[#e51c00] stroke-[#e51c00]'} font-inter text-[12px] leading-[16px] font-normal`}>
                                                <svg width="16" height="16" viewBox="0 0 17 17" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="0.5" y="0.5" width="15" height="15" rx="7.5"/>
                                                    <path
                                                        d="M6.94109 9.58847L11.5291 5L12.2353 5.70576L6.94109 11L3.76465 7.82356L4.47041 7.11779L6.94109 9.58847Z"/>
                                                </svg>

                                                Upper + lowercase letters
                                            </li>
                                            <li className={`flex flex-row gap-2 items-center ${!formChecker && '!text-[#717171] !stroke-[#717171]'} ${formChecker?.isAtLeastOneNumber ? 'text-[#29845a] stroke-[#29845a]' : 'text-[#e51c00] stroke-[#e51c00]'} font-inter text-[12px] leading-[16px] font-normal`}>
                                                <svg width="16" height="16" viewBox="0 0 17 17" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="0.5" y="0.5" width="15" height="15" rx="7.5"/>
                                                    <path
                                                        d="M6.94109 9.58847L11.5291 5L12.2353 5.70576L6.94109 11L3.76465 7.82356L4.47041 7.11779L6.94109 9.58847Z"/>
                                                </svg>

                                                At least 1 number
                                            </li>
                                            <li className={`flex flex-row gap-2 items-center ${!formChecker && '!text-[#717171] !stroke-[#717171]'} ${formChecker?.isAtLeastOneSpecialCharacter ? 'text-[#29845a] stroke-[#29845a]' : 'text-[#e51c00] stroke-[#e51c00]'} font-inter text-[12px] leading-[16px] font-normal`}>
                                                <svg width="16" height="16" viewBox="0 0 17 17" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="0.5" y="0.5" width="15" height="15" rx="7.5"/>
                                                    <path
                                                        d="M6.94109 9.58847L11.5291 5L12.2353 5.70576L6.94109 11L3.76465 7.82356L4.47041 7.11779L6.94109 9.58847Z"/>
                                                </svg>

                                                At least 1 special character
                                            </li>
                                        </ul>
                                    </div>

                                    <Button type={'submit'} size={'lg'} className={'w-fit text-white text-[16px] leading-[20px] font-medium px-[20px] relative'}>
                                        <SpinnerIcon className={`${isLoading ? 'visible' : 'invisible'} fill-marcoWhite absolute`} size={20}/>
                                        <p className={`${isLoading ? 'invisible' : 'visible'} px-5 text-center text-white text-[16px] leading-[20px] font-medium`}>Save</p>
                                    </Button>
                                </div>}
                        </section>
                    </div>
                </form>
            </Form>
        </div>
    } else return null
}