import {ApiHost} from "src/utils/defaults/apiHost";
import getDefinedError from "src/utils/errorHandlers/getDefinedError";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";
import {Company} from "../types/structures/company";

export async function getCurrentCompany() {
    const res = await fetch(ApiHost + encodeURI(`api/v1/company`), {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${useTokenStore.getState().token}`
        }
    })

    if (res.ok) {
        const data: Company = await res.json();
        return {data: data, error: null}
    } else {
        const err = await getDefinedError(res)
        return {data: null, error: err}
    }
}