import {Slot} from "@radix-ui/react-slot"
import {cva, type VariantProps} from "class-variance-authority"
import * as React from "react"

import {cn} from "../utils/cn/cnHelper";

const buttonVariants = cva(
    "flex items-center justify-center whitespace-nowrap rounded-[6px] text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none",
    {
        variants: {
            variant: {
                default:
                    "bg-primary tracking-[.5px] text-primary-foreground hover:bg-[#333333] font-inter disabled:opacity-50",
                green:
                    "bg-marcoDarkGreen text-primary-foreground hover:bg-marcoLightGreen font-inter disabled:opacity-50",
                lightGreen:
                    "bg-[#e1f56d] text-[#222222] hover:bg-[#eefa9c] disabled:bg-[#f7fdca] disabled:text-[#717171] font-inter",
                red:
                    "bg-[#BC2200] text-primary-foreground hover:bg-[#D82C0D] font-inter disabled:opacity-50",
                destructive:
                    "bg-destructive text-destructive-foreground hover:bg-destructive/90 font-inter disabled:opacity-50",
                outline:
                    "border border-input bg-background hover:bg-accent hover:text-accent-foreground font-inter disabled:opacity-50",
                rounded:
                    'border bg-background hover:border-foreground text-foreground font-inter text-sm font-medium disabled:opacity-50',
                secondary:
                    "bg-secondary text-secondary-foreground hover:bg-secondary/80 font-inter disabled:opacity-50",
                ghost: "hover:bg-accent hover:text-accent-foreground font-inter disabled:opacity-50",
                textGhost: "hover:bg-none hover:text-accent-foreground font-inter underline text-sm disabled:opacity-50",
                link: "text-primary underline-offset-4 hover:underline font-inter disabled:opacity-50",

                icon: "text-primary font-inter",
            },
            size: {
                defaultTextSM: "h-10 px-4 py-4 w-fit flex gap-1 text-sm font-normal",
                textForm: "h-10 w-fit flex gap-1 text-sm font-normal",
                tab: "h-10 px-4 py-4 w-fit flex gap-1",
                default: "h-10 px-4 py-2 text-base rounded-[6px] font-medium font-inter",
                sm: "h-8 px-4 py-2  text-base rounded-[6px] font-medium font-inter",
                lg: "h-12 px-4 py-2 tracking-[.5px] text-base rounded-[6px] font-medium font-inter",
                xl: "h-12 px-4 py-2  text-base rounded-[6px] font-medium font-inter",
                icon: "h-9 w-9",

                iconFit: 'p-0 text-base rounded-[6px] font-medium font-inter',
            },
            text: {
                default: 'text-sm'
            }
        },
        defaultVariants: {
            variant: "default",
            size: "default",
            text: 'default'
        },
    }
)

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof buttonVariants> {
    asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({className, variant, size, asChild = false, ...props}, ref) => {
        const Comp = asChild ? Slot : "button"
        return (
            <Comp
                className={cn(buttonVariants({variant, size, className}))}
                ref={ref}
                {...props}
            />
        )
    }
)
Button.displayName = "Button"

export {Button, buttonVariants}
