import {zodResolver} from "@hookform/resolvers/zod";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {Button} from "src/components/button";
import {Form, FormControl, FormField, FormItem} from "src/components/form";
import {InputOTP, InputOTPGroup, InputOTPSlot} from "src/components/inputOTP";
import {Separator} from "src/components/separator";
import useDialog from "src/pages/dashboarSection/layout/popUps/dialog/useDialog";
import {pickupOrder} from "src/utils/apiCalls/pickupOrder";
import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import {ErrorMessage} from "src/utils/types/errorMessage";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import PINCodeSchema from "src/utils/zodSchemas/PINCodeSchema";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";
import * as z from "zod";
import {SpinnerIcon} from "../../../../../../media/icons/custom/spinnerIcon";

interface Props {
    order: OrderDetails
}

export default function PINform({order}: Props) {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState<boolean>(false)
    const {isOpen, close, mutateFirstSource} = useDialog()
    const form = useForm<z.infer<typeof PINCodeSchema>>({
        resolver: zodResolver(PINCodeSchema),
        defaultValues: {
            PIN: "",
        }
    });

    useEffect(() => {
        form.reset()
    }, [isOpen]);

    const onSubmit = async (values: z.infer<typeof PINCodeSchema>) => {
        setLoading(true)
        const res = await pickupOrder(order.id, Number(values.PIN))
        if (isInstanceOf<ErrorMessage>(res, 'message')) {
            if (res.status === '400') {
                if (res.message === 'Access Denied') {
                    useTokenStore.getState().resetToken()
                    navigate('/login')
                }
            }

            if (res.status === '401') {
                useTokenStore.getState().resetToken();
                navigate('/login')
            }

            form.setError('PIN', {message: res.message[0] + res.message.slice(1).toLowerCase()})
        } else {
            if (mutateFirstSource) {
                mutateFirstSource()
                close()
            }
        }
        setLoading(false)
    }

    return <div className={'flex flex-col  h-full'}>

        <p className={'flex-none px-6 text-sm font-normal text-muted-foreground leading-5'}>To begin the survey,
            kindly ask the customer for the 4-digit code in their order details.</p>

        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="h-full flex flex-col pt-4">
                <div className={'flex-1 flex-col pb-[5.5rem]'}>
                    <FormField
                        control={form.control}
                        name="PIN"
                        render={({field}) => (
                            <FormItem>
                                <FormControl>
                                    <InputOTP maxLength={4} {...field}>
                                        <InputOTPGroup className={'relative flex-1 flex h-fit gap-4 px-6'}>
                                            <InputOTPSlot
                                                className={`text-base font-medium leading-5 ${form.getFieldState('PIN').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`}
                                                index={0}
                                                isValid={form.getFieldState('PIN').invalid}/>
                                            <InputOTPSlot
                                                className={`text-base font-medium leading-5 ${form.getFieldState('PIN').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`}
                                                index={1}
                                                isValid={form.getFieldState('PIN').invalid}/>
                                            <InputOTPSlot
                                                className={`text-base font-medium leading-5 ${form.getFieldState('PIN').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`}
                                                index={2}
                                                isValid={form.getFieldState('PIN').invalid}/>
                                            <InputOTPSlot
                                                className={`text-base font-medium leading-5 ${form.getFieldState('PIN').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`}
                                                index={3}
                                                isValid={form.getFieldState('PIN').invalid}/>
                                        </InputOTPGroup>
                                    </InputOTP>
                                </FormControl>
                            </FormItem>
                        )}
                    />

                    <div className={'h-5 flex items-center my-2'}>
                        {form.getFieldState('PIN').invalid &&
                            <span
                                className={'px-6 text-marcoFormErrorTextColor text-sm font-normal leading-5'}>{form.formState.errors.PIN?.message}</span>}
                    </div>

                    <section className={'flex-none flex flex-col gap-4 items-start pt-4 px-6'}>
                        <Button variant={'ghost'} type="submit"
                                className={'rounded-[1.875rem] bg-accent text-foreground text-sm font-normal leading-tight'}>Send
                            code via text message</Button>
                        <Button variant={'ghost'} type="submit"
                                className={'rounded-[1.875rem] bg-accent text-foreground text-sm font-normal leading-tight'}>Send
                            code via email</Button>
                    </section>
                </div>

                <Separator/>

                <section className={'flex-none py-4 px-6 flex flex-row justify-between'}>
                    <Button variant={'ghost'} size={'lg'} type={'button'} onClick={close}
                            className={'text-base font-medium leading-5'}>Cancel</Button>
                    <Button size={'lg'} className={'text-base font-medium leading-5 relative'}>
                        <SpinnerIcon className={`${isLoading ? 'visible' : 'invisible'} fill-marcoWhite absolute`} size={20}/>
                        <p className={`${isLoading ? 'invisible' : 'visible'} px-5 text-center text-white text-[16px] leading-[20px] font-medium`}>Submit</p>
                    </Button>
                </section>
            </form>
        </Form>
    </div>
}
