import React from "react";
import {useNavigate} from "react-router-dom";
import routes from "src/utils/defaults/routes";

export default function CheckEmail() {
    const navigate = useNavigate();

    return <div className={"flex flex-col"}>
        <div className={"flex flex-col gap-2 pb-8"}>
            <h1 className={"font-inter text-[#222222] text-[24px] leading-[32px] font-medium"}>Check your email</h1>
            <p className={"font-inter text-[#717171] text-[14px] leading-[20px] font-normal"}>If your email matches an account, we’ll email you instructions to reset your password.</p>
        </div>

        <span
            className={"font-inter text-[#222222] text-[14px] leading-[20px] font-medium underline cursor-pointer"}
            onClick={() => navigate(routes.login)}>
                            Back to sign in
        </span>
    </div>
}