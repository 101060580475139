import {zodResolver} from "@hookform/resolvers/zod";
import jwt from "jwt-decode";
import React from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {Button} from "src/components/button";
import {Form, FormControl, FormField, FormItem, FormMessage} from "src/components/form";
import {useToast} from "src/components/useToast";
import {getCompany} from "src/utils/apiCalls/getCompany";
import {postLogin} from "src/utils/apiCalls/postLogin";
import routes from "src/utils/defaults/routes";
import {AccountStatus} from "src/utils/enums/accountStatus";
import {Role} from "src/utils/enums/role";
import {LoginRequest} from "src/utils/types/loginRequest";
import loginAndPasswordSchema from "src/utils/zodSchemas/loginAndPasswordSchema";
import useTokenStore, {TokenUser} from "src/utils/zustandStores/primary/useTokenStore";
import * as z from "zod";
import {InputOffLabel} from "../../../components/inputOffLabel";
import {PasswordInputOffLabel} from "../../../components/passwordInputOffLabel";

export default function Login() {
    const navigate = useNavigate();
    const {toast} = useToast();
    const {setToken} = useTokenStore()

    const form = useForm<z.infer<typeof loginAndPasswordSchema>>({
        resolver: zodResolver(loginAndPasswordSchema),
        defaultValues: {
            email: "",
            password: ""
        }
    });

    async function onSubmit(values: z.infer<typeof loginAndPasswordSchema>) {
        const request: LoginRequest = {
            email: values.email,
            password: values.password
        }

        const res = await postLogin(request);
        if (typeof res === 'string') {
            setToken(res);
            const user: TokenUser = jwt(res);
            switch (user.roles) {
                case Role.driver: {
                    navigate(`${routes.loads}/booked`)
                    break
                }
                case Role.manager:
                case Role.owner: {
                    const company = await getCompany();
                    if (company.status === AccountStatus.active) {
                        navigate(`${routes.search}`)
                    } else {
                        navigate(routes.companyPaymentsAndPayouts)
                    }
                    break
                }
            }

        } else {
            form.setError('email', {message: 'invalid username or password'})
            form.setError('password', {message: 'invalid username or password'})
            toast({
                variant: "destructive",
                description: "Username or password is incorrect.",
            })
        }
    }

    return <div className={"flex-1 flex flex-col"}>
        <h1 className={"font-inter text-[24px] leading-[32px] font-medium text-[#222222] pb-[32px]"}>Sign in to your Marco Pro account</h1>

        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <div className={"flex flex-col gap-4"}>
                    <FormField
                        control={form.control}
                        name="email"
                        render={({field}) => (
                            <FormItem>
                                <FormControl>
                                    <InputOffLabel
                                        {...field}
                                        autoComplete={'off'}
                                        label={'Email address'}
                                        type={'text'}
                                        value={form.getValues('email')}
                                        onInputCapture={() => form.clearErrors()}
                                        isInvalid={form.getFieldState('email').invalid}
                                        isInputOn={form.getValues('email').length !== 0} />
                                </FormControl>

                                {form.getFieldState('email').invalid && <FormMessage
                                    className={'text-marcoFormErrorTextColor text-sm h-3 pb-5 grid place-items-start'}/>}
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="password"
                        render={({field}) => (
                            <FormItem>
                                <FormControl>
                                    <PasswordInputOffLabel
                                        {...field}
                                        autoComplete={'current-password'}
                                        label={'Password'}
                                        value={form.getValues('password')}
                                        onInputCapture={() => form.clearErrors()}
                                        isInvalid={form.getFieldState('password').invalid}
                                        onChange={(e) => form.setValue('password', e.target.value)}
                                        isInputOn={form.getValues('password').length !== 0} />
                                </FormControl>
                                {form.getFieldState('password').invalid && <FormMessage
                                    className={'text-marcoFormErrorTextColor text-sm h-3 pb-5 grid place-items-start'}/>}
                            </FormItem>
                        )}
                    />

                    <section className={'flex flex-col gap-6'}>
                        <span
                            className={"font-inter text-[14px] leading-[20px] font-medium text-[#222222] underline cursor-pointer"}
                            onClick={() => navigate(routes.forgotPassword)}>
                            Trouble logging in?
                        </span>

                        <Button className={"w-full font-inter text-[16px] leading-[20px] font-medium"}
                                size={'lg'}
                                variant={"lightGreen"}
                                type={'submit'}>Sign in</Button>

                        <div className={'flex flex-row items-center'}>
                            <span className={'font-inter text-[#222222] text-[14px] leading-[20px] font-normal'}>New to Marco Pro?</span>
                            <span
                                className={"ml-1 underline cursor-pointer font-inter text-[#222222] text-[14px] leading-[20px] font-medium"}
                                onClick={() => navigate(routes.signUp)}>
                            Get started
                        </span>
                        </div>
                    </section>
                </div>
            </form>
        </Form>
    </div>
}