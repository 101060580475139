import getAccess from "src/utils/stringHandlers/enumToStringHandlers/getAccess";
import getNumberStories from "src/utils/stringHandlers/enumToStringHandlers/getNumberStories";
import getPropertySize from "src/utils/stringHandlers/enumToStringHandlers/getPropertySize";
import getPropertyType from "src/utils/stringHandlers/enumToStringHandlers/getPropertyType";
import getSquareFootage from "src/utils/stringHandlers/enumToStringHandlers/getSquareFootage";
import getUnitSize from "src/utils/stringHandlers/enumToStringHandlers/getUnitSize";
import {Address} from "src/utils/types/structures/Address";
import {getFloorNumber} from "../getFloorNumber";

export default function getAddressPropertyProps(address: Address | null | undefined) {

    let result = ''

    if (address) {

        if (address.propertyType) {
            const propertyType = getPropertyType(address.propertyType)
            result = result + propertyType
        }

        if (address.propertySize) {
            const propertySize = getPropertySize(address.propertySize)
            result = result + ` • ` + propertySize
        }

        if (address.unitSize) {
            const unitSize = getUnitSize(address.unitSize)
            result = result + ' • ' + unitSize
        }

        if (address.squareFootage) {
            const squareFootage = getSquareFootage(address.squareFootage)
            result = result + ' • ' + squareFootage
        }

        if (address.floorNumber) {
            result = result + ' • ' + `${getFloorNumber(address.floorNumber)}`
        }

        if (address.numberStories) {
            const numberStories = getNumberStories(address.numberStories)
            result = result + ' • ' + numberStories
        }

        if (address.access) {
            const access = getAccess(address.access)
            result = result + ' • ' + access
        }
    }

    return result
}