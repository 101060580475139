import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button} from "src/components/button";
import useSheet, {ElementType} from "src/pages/dashboarSection/layout/popUps/sheet/useSheet";
import {getOrder} from "src/utils/apiCalls/orderDetails/getOrder";
import {defaultDimensions} from "src/utils/defaults/defaultDimensions";
import routes from "src/utils/defaults/routes";
import {InventoryStatus} from "src/utils/enums/inventoryStatus";
import {handleResponseError} from "src/utils/errorHandlers/handleResponseError";
import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import {ErrorMessage} from "src/utils/types/errorMessage";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import useOrderInventory from "src/utils/zustandStores/orderDetailsInventory/orderInventory";
import useViewport from "src/utils/zustandStores/primary/useViewport";

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    order: OrderDetails
    setOrder: React.Dispatch<React.SetStateAction<OrderDetails | undefined>>
    isLoading: boolean
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}
export default function InventoryButtonSet({order, setOrder, isLoading, setLoading,}: Props) {
    const {id} = useParams();
    const {open} = useSheet();
    const navigate = useNavigate();
    const {setCurrentExtendedItems, previousExtendedItems} = useOrderInventory();
    const {width} = useViewport();
    async function initiateOrder() {
        if (id) {
            const res = await getOrder(id)
            if (isInstanceOf<ErrorMessage>(res, 'message')) {
                handleResponseError(res, () => navigate(routes.login))
            } else {
                setOrder(res)
            }
        }
    }

    if (order?.inventoryStatus === InventoryStatus.readOnly) {
        return null
    }

    return <Button
        onClick={() => {
            setCurrentExtendedItems(previousExtendedItems)
            open(ElementType.orderDetailsUpdateItems, order, 'Manage inventory', () => initiateOrder())
        }}
        className={`${width < defaultDimensions.md ? 'h-[48px] text-white text-base font-medium  leading-[20px] w-full' : 'h-[40px] text-white text-sm font-medium leading-[20px]'}`}
    >Manage inventory</Button>
}