import React from "react";
import getPacking from "src/utils/stringHandlers/enumToStringHandlers/getPacking";
import getFixedToZeroDecimalNumber from "src/utils/stringHandlers/getFixedToZeroDecimalNumber";
import getOrderWeight from "src/utils/stringHandlers/getWeight";
import getItemsCount from "src/utils/stringHandlers/inventory/getItemsCount";
import stringToFormatDateYear from "src/utils/stringHandlers/stringToFormatDateYear";
import {OrderDetails} from "src/utils/types/structures/orderDetails";

interface Props {
    order: OrderDetails
}

export default function DetailsSection({order}: Props) {

    return <section key={'order-details'} className={'flex flex-col gap-4 border-b py-6'}>
        <h2 className={'text-[#222222] text-[18px] leading-[24px] font-semibold'}>Details</h2>

        <div className={'flex justify-items-start gap-4'}>
            <div className={'flex-1 flex flex-col gap-1'}>
                <h3 className={'text-[#717171] text-[12px] leading-[16px] font-medium'}>Order ID</h3>
                <p className={'text-[#222222] text-[16px] leading-[20px] font-medium'}>{order.sid}</p>
            </div>

            <div className={'flex-1 flex flex-col gap-1'}>
                <h3 className={'text-[#717171] text-[12px] leading-[16px] font-medium'}>Pickup date</h3>
                <p className={'text-[#222222] text-[16px] leading-[20px] font-medium'}>{stringToFormatDateYear(order.pickupDate)}</p>
            </div>
        </div>

        <div className={'flex justify-items-start gap-4'}>
            <div className={'flex-1 flex flex-col gap-1'}>
                <h3 className={'text-[#717171] text-[12px] leading-[16px] font-medium'}>Distance</h3>
                <p className={'text-[#222222] text-[16px] leading-[20px] font-medium'}>{getFixedToZeroDecimalNumber(order.distance)} mi</p>
            </div>

            <div className={'flex-1 flex flex-col gap-1'}>
                <h3 className={'text-[#717171] text-[12px] leading-[16px] font-medium'}>Volume</h3>
                <p className={'text-[#222222] text-[16px] leading-[20px] font-medium'}>{getFixedToZeroDecimalNumber(order.volume)} cu.ft</p>
            </div>
        </div>

        <div className={'flex justify-items-start gap-4'}>
            <div className={'flex-1 flex flex-col gap-1'}>
                <h3 className={'text-[#717171] text-[12px] leading-[16px] font-medium'}>Weight</h3>
                <p className={'text-[#222222] text-[16px] leading-[20px] font-medium'}>{getOrderWeight()} lb</p>
            </div>

            <div className={'flex-1 flex flex-col gap-1'}>
                <h3 className={'text-[#717171] text-[12px] leading-[16px] font-medium'}>Service package</h3>
                <p className={'text-[#222222] text-[16px] leading-[20px] font-medium'}>{getPacking(order.packing)}</p>
            </div>
        </div>

        <div className={'flex justify-items-start gap-4'}>
            <div className={'flex-1 flex flex-col gap-1'}>
                <h3 className={'text-[#717171] text-[12px] leading-[16px] font-medium'}>Inventory</h3>
                <p className={'text-[#222222] text-[16px] leading-[20px] font-medium'}>{getItemsCount()} items</p>
            </div>

            <div className={'flex-1 flex flex-col gap-1'}>
                <h3 className={'text-[#717171] text-[12px] leading-[16px] font-medium'}>Estimate</h3>
                <p className={'text-[#222222] text-[16px] leading-[20px] font-medium'}>${getFixedToZeroDecimalNumber(order.rate)}</p>
            </div>
        </div>

        <div className={'flex justify-items-start gap-4'}>
            <div className={'flex-1 flex flex-col gap-1'}>
                <h3 className={'text-[#717171] text-[12px] leading-[16px] font-medium'}>Est. toll costs</h3>
                <p className={'text-[#222222] text-[16px] leading-[20px] font-medium'}>${getFixedToZeroDecimalNumber(order.tolls)}</p>
            </div>

            <div className={'flex-1 flex flex-col gap-1'}>
                <h3 className={'text-[#717171] text-[12px] leading-[16px] font-medium'}>Est. fuel costs</h3>
                <p className={'text-[#222222] text-[16px] leading-[20px] font-medium'}>${getFixedToZeroDecimalNumber(order.fuel)}</p>
            </div>
        </div>
    </section>
}