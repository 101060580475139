import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "src/components/button";
import LoadingStatus from "src/components/loadingStatus";
import {toast} from "src/components/useToast";
import useDialog, {DialogElementType} from "src/pages/dashboarSection/layout/popUps/dialog/useDialog";
import {getOrder} from "src/utils/apiCalls/orderDetails/getOrder";
import {acceptOrder} from "src/utils/apiCalls/orderFulfillmentFlow/acceptOrder";
import {arrivedDelivery} from "src/utils/apiCalls/orderFulfillmentFlow/arrivedDelivery";
import {arrivedOrder} from "src/utils/apiCalls/orderFulfillmentFlow/arrivedOrder";
import {completeDelivery} from "src/utils/apiCalls/orderFulfillmentFlow/completeDelivery";
import {completePickup} from "src/utils/apiCalls/orderFulfillmentFlow/completePickup";
import {completeSurveyOrder} from "src/utils/apiCalls/orderFulfillmentFlow/completeSurveyOrder";
import {dispatchOrder} from "src/utils/apiCalls/orderFulfillmentFlow/dispatchOrder";
import {defaultDimensions} from "src/utils/defaults/defaultDimensions";
import routes from "src/utils/defaults/routes";
import {OrderFulfillmentStatus} from "src/utils/enums/orderFulfillmentStatus";
import {handleResponseError} from "src/utils/errorHandlers/handleResponseError";
import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import {ErrorMessage} from "src/utils/types/errorMessage";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import useViewport from "src/utils/zustandStores/primary/useViewport";

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    order: OrderDetails | undefined
    setOrder: React.Dispatch<React.SetStateAction<OrderDetails | undefined>>
    isLoading: boolean
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export default function DetailsButtonSet({order, setOrder, isLoading, setLoading, ...props}: Props) {
    const navigate = useNavigate();
    const {open} = useDialog()
    const {width} = useViewport();
    const [button, setButton] = useState<React.ReactNode | null>(null)

    useEffect(() => {
        if (order) {
            switch (order.fulfillmentStatus) {
                case OrderFulfillmentStatus.notAccepted: {
                    setButton(<Button disabled={isLoading}
                                      className={`${width < defaultDimensions.md ? 'h-[48px] text-white text-base font-medium  leading-[20px] w-full' : 'h-[40px] text-white text-sm font-medium leading-[20px]'}`}
                                      onClick={onAccept}>{isLoading ? <LoadingStatus/> : 'Accept'}</Button>)
                    break
                }

                case OrderFulfillmentStatus.accepted: {
                    setButton(<Button disabled={isLoading}
                                      className={`${width < defaultDimensions.md ? 'h-[48px] text-white text-base font-medium  leading-[20px] w-full' : 'h-[40px] text-white text-sm font-medium leading-[20px]'}`}
                                      onClick={onDispatch}>{isLoading ?
                        <LoadingStatus/> : 'Mark as dispatched'}</Button>)
                    break
                }

                case OrderFulfillmentStatus.dispatched: {
                    setButton(<Button disabled={isLoading}
                                      className={`${width < defaultDimensions.md ? 'h-[48px] text-white text-base font-medium  leading-[20px] w-full' : 'h-[40px] text-white text-sm font-medium leading-[20px]'}`}
                                      onClick={onArrived}>{isLoading ? <LoadingStatus/> : 'Arrived at pickup'}</Button>)
                    break
                }

                case OrderFulfillmentStatus.arrivedAtPickup: {
                    setButton(<Button disabled={isLoading}
                                      className={`${width < defaultDimensions.md ? 'h-[48px] text-white text-base font-medium  leading-[20px] w-full' : 'h-[40px] text-white text-sm font-medium leading-[20px]'}`}
                                      onClick={onPickup}>{isLoading ? <LoadingStatus/> : 'Start Survey'}</Button>)
                    break
                }

                case OrderFulfillmentStatus.atPickup: {
                    setButton(<Button disabled={isLoading}
                                      className={`${width < defaultDimensions.md ? 'h-[48px] text-white text-base font-medium  leading-[20px] w-full' : 'h-[40px] text-white text-sm font-medium leading-[20px]'}`}
                                      onClick={onCompleteSurveyAndStartDelivery}>{isLoading ? <LoadingStatus/> : 'Complete Survey'}</Button>)
                    break
                }

                // TBD
                case OrderFulfillmentStatus.waitingSignature: {
                    setButton(<Button disabled={isLoading}
                                      className={`${width < defaultDimensions.md ? 'h-[48px] text-white text-base font-medium  leading-[20px] w-full' : 'h-[40px] text-white text-sm font-medium leading-[20px]'}`}
                                      onClick={() => console.log('onWaitingSignature')}>{isLoading ? <LoadingStatus/> : 'Request signature'}</Button>)
                    break
                }

                // TBD
                case OrderFulfillmentStatus.awaitingPayment: {
                    setButton(<Button disabled={isLoading}
                                      className={`${width < defaultDimensions.md ? 'h-[48px] text-white text-base font-medium  leading-[20px] w-full' : 'h-[40px] text-white text-sm font-medium leading-[20px]'}`}
                                      onClick={() => console.log('onAwaitingPayment')}>{isLoading ? <LoadingStatus/> : 'Request payment'}</Button>)
                    break
                }

                // Here we are
                case OrderFulfillmentStatus.pickupStarted: {
                    setButton(<Button disabled={isLoading}
                                      className={`${width < defaultDimensions.md ? 'h-[48px] text-white text-base font-medium  leading-[20px] w-full' : 'h-[40px] text-white text-sm font-medium leading-[20px]'}`}
                                      onClick={onCompletePickup}>{isLoading ? <LoadingStatus/> : 'Complete pickup'}</Button>)
                    break
                }

                case OrderFulfillmentStatus.completePickup: {
                    setButton(<Button disabled={isLoading}
                                      className={`${width < defaultDimensions.md ? 'h-[48px] text-white text-base font-medium  leading-[20px] w-full' : 'h-[40px] text-white text-sm font-medium leading-[20px]'}`}
                                      onClick={() => {}}>{isLoading ? <LoadingStatus/> : '...'}</Button>)
                    break
                }

                case OrderFulfillmentStatus.inTransit: {
                    setButton(<Button disabled={isLoading}
                                      className={`${width < defaultDimensions.md ? 'h-[48px] text-white text-base font-medium  leading-[20px] w-full' : 'h-[40px] text-white text-sm font-medium leading-[20px]'}`}
                                      onClick={onArrivedAtDelivery}>{isLoading ? <LoadingStatus/> : 'Arrived at Delivery'}</Button>)
                    break
                }

                case OrderFulfillmentStatus.startDelivery: {
                    setButton(<Button disabled={isLoading}
                                      className={`${width < defaultDimensions.md ? 'h-[48px] text-white text-base font-medium  leading-[20px] w-full' : 'h-[40px] text-white text-sm font-medium leading-[20px]'}`}
                                      onClick={onCompleteDelivery}>{isLoading ? <LoadingStatus/> : 'Complete delivery'}</Button>)
                    break
                }

                case OrderFulfillmentStatus.completeDelivery: {
                    setButton(null)
                    break
                }

                default: {
                    setButton(null)
                }
            }
        }
    }, [order, isLoading]);
    const updateOrder = async () => {
        if (order) {
            const res = await getOrder(order.id)
            if (isInstanceOf<ErrorMessage>(res, 'message')) {
                handleResponseError(res, () => navigate(routes.login))
            } else {
                setOrder(res)
            }
        }
    }
    const onAccept = async () => {
        setLoading(true)
        if (order) {
            const res = await acceptOrder(order.id)
            if (isInstanceOf<ErrorMessage>(res, 'message')) {
                handleResponseError(res, () => navigate(routes.login))
            } else {
                setOrder(res)
            }
        }
        setLoading(false)
    }

    const onDispatch = async () => {
        setLoading(true)
        if (order) {
            if (!order.driver){
                toast({variant: 'destructive', description: 'Driver not assigned'})
            } else {
                const res = await dispatchOrder(order.id)
                if (isInstanceOf<ErrorMessage>(res, 'message')) {
                    handleResponseError(res, () => navigate(routes.login))
                } else {
                    setOrder(res)
                }
            }
        }
        setLoading(false)
    }

    const onArrived = async () => {
        setLoading(true)
        if (order) {
            const res = await arrivedOrder(order.id)
            if (isInstanceOf<ErrorMessage>(res, 'message')) {
                handleResponseError(res, () => navigate(routes.login))
            } else {
                setOrder(res)
            }
        }
        setLoading(false)
    }

    const onPickup = async() => {
        if (order) {
            open(DialogElementType.PINForm, order, 'Let’s get work started', () => updateOrder())
        }
    }

    const onCompleteSurveyAndStartDelivery = async () => {
        setLoading(true)
        if (order) {
            const res = await completeSurveyOrder(order.id)
            if (isInstanceOf<ErrorMessage>(res, 'message')) {
                handleResponseError(res, () => navigate(routes.login))
            } else {
                setOrder(res)
            }
        }
        setLoading(false)
    }

    const onCompletePickup = async () => {
        setLoading(true)
        if (order) {
            const res = await completePickup(order.id)
            if (isInstanceOf<ErrorMessage>(res, 'message')) {
                handleResponseError(res, () => navigate(routes.login))
            } else {
                setOrder(res)
            }
        }
        setLoading(false)
    }

    const onArrivedAtDelivery = async () => {
        setLoading(true)
        if (order) {
            const res = await arrivedDelivery(order.id)
            if (isInstanceOf<ErrorMessage>(res, 'message')) {
                handleResponseError(res, () => navigate(routes.login))
            } else {
                setOrder(res)
            }
        }
        setLoading(false)
    }

    const onCompleteDelivery = async () => {
        setLoading(true)
        if (order) {
            const res = await completeDelivery(order.id)
            if (isInstanceOf<ErrorMessage>(res, 'message')) {
                handleResponseError(res, () => navigate(routes.login))
            } else {
                setOrder(res)
            }
        }
        setLoading(false)
    }

    return <div {...props}>
        {button}
    </div>
}