import {useNavigate} from "react-router-dom";
import routes from "src/utils/defaults/routes";
import useDialog, {DialogElementType} from "../../layout/popUps/dialog/useDialog";
import {getCurrentCompany} from "../../../../utils/apiCalls/getCurrentCompany";
import {handleResponseError} from "../../../../utils/errorHandlers/handleResponseError";
import {useEffect} from "react";

interface Props {
    children: React.ReactNode,
}

export default function CompanyAccountLayout({children}: Props) {
    const {open} = useDialog()


    const checkActiveCompany = async () => {
        const {data, error} = await getCurrentCompany()
        if (error) handleResponseError(error, () => navigate(routes.login))
        if (data && data.status !== 'ACTIVE') open(DialogElementType.completeUnboarding, data, 'Complete your account')
    }

    useEffect(() => {
        checkActiveCompany()
    }, []);

    const navigate = useNavigate()

    return <div className={`px-padding pt-8 flex flex-col pb-6`}>

        <h1 className={'text-2xl hidden md:block font-semibold leading-[1.875rem]'}>Company settings</h1>

        <section className={'flex md:pt-8 gap-[9%]'}>
            <ul className={'flex-col gap-2 w-[18rem] hidden md:flex absolute'}>
                <li className={'text-base leading-6 cursor-pointer py-2'}
                    onClick={() => navigate(routes.companyDetails)}>Company Details
                </li>
                <li className={'text-base leading-6 cursor-pointer py-2'}
                    onClick={() => navigate(routes.companyPaymentsAndPayouts)}>Payment Methods
                </li>
            </ul>

            <section className={'w-full flex flex-col gap-6 md:max-w-[400px] lg:max-w-[560px] container mx-auto'}>

                <svg className={'cursor-pointer md:hidden'} onClick={() => navigate(routes.mobileAccountSettings)}
                     width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="40" height="40" rx="20" fill="#F7F7F7"/>
                    <path d="M25.8332 20H14.1665" stroke="#222222" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M19.9998 25.8332L14.1665 19.9998L19.9998 14.1665" stroke="#222222" strokeWidth="1.5"
                          strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                {children}
            </section>
        </section>
    </div>
}