import {AddressToValidateRequest} from "./addressToValidateRequest";
import {AddressToValidateResponse200} from "./addressToValidateResponse200";
import {AddressToValidateError} from "./addressToValidateError";

export default async function validateAddress(
  address: AddressToValidateRequest,
) {
  const res = await fetch(
    `https://addressvalidation.googleapis.com/v1:validateAddress?key=AIzaSyC-BSwkr80ziYNRaqy_I3h9PhTo0XO6l7E`,
    {
      body: JSON.stringify(address),
      headers: {
        "content-type": "application/json",
      },
      method: "POST",
    },
  );
  if (res.ok) {
    const data: AddressToValidateResponse200 = await res.json();
    return data;
  } else {
    const err: AddressToValidateError = await res.json();
    return err;
  }
}
