import {zodResolver} from "@hookform/resolvers/zod";
import React from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {Button} from "src/components/button";
import {Form, FormControl, FormField, FormItem, FormMessage} from "src/components/form";
import {useToast} from "src/components/useToast";
import {resetPassword} from "src/utils/apiCalls/resetPassword";
import routes from "src/utils/defaults/routes";
import forgotPasswordSchema from "src/utils/zodSchemas/forgotPasswordSchema";
import * as z from "zod";
import {InputOffLabel} from "../../../components/inputOffLabel";

export default function ForgotPassword() {
    const navigate = useNavigate();
    const {toast} = useToast()

    const form = useForm<z.infer<typeof forgotPasswordSchema>>({
        resolver: zodResolver(forgotPasswordSchema),
        defaultValues: {
            email: ""
        }
    });

    async function onSubmit(values: z.infer<typeof forgotPasswordSchema>) {
        const res = await resetPassword(values.email);
        if (res === 200) {
            toast({
                description: "Registration link sent to email",
            })
            navigate(routes.checkEmailRoute)
        } else {
            form.setError('email', {message: res.message})
            toast({
                variant: "destructive",
                description: `${res.message}`,
            })
        }
    }

    return <div className={"flex flex-col"}>
        <div className={"flex flex-col gap-2 pb-8"}>
            <h1 className={"font-inter text-[#222222] text-[24px] leading-[32px] font-medium"}>Reset password</h1>
            <p className={"font-inter text-[#717171] text-[14px] leading-[20px] font-normal"}>Enter your email to receive instructions.</p>
        </div>

        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <div className={"flex flex-col gap-6"}>
                    <FormField
                        control={form.control}
                        name="email"
                        render={({field}) => (
                            <FormItem>
                                <FormControl>
                                    <InputOffLabel
                                        {...field}
                                        autoComplete={'off'}
                                        label={'Email address'}
                                        type={'text'}
                                        value={form.getValues('email')}
                                        onInputCapture={() => form.clearErrors()}
                                        isInvalid={form.getFieldState('email').invalid}
                                        isInputOn={form.getValues('email').length !== 0}/>
                                </FormControl>
                                {form.getFieldState('email').invalid && <FormMessage
                                    className={'text-marcoFormErrorTextColor text-sm h-3 pb-5 grid place-items-start'}/>}
                            </FormItem>
                        )}
                    />

                    <Button
                        className={"w-full font-inter text-[#222222] text-[16px] leading-[20px] font-medium"}
                        size={'lg'}
                        variant={"lightGreen"}>
                        Submit
                    </Button>

                    <span
                        className={"font-inter text-[#222222] text-[14px] leading-[20px] font-medium underline cursor-pointer"}
                        onClick={() => navigate(routes.login)}>
                            Back to sign in
                        </span>
                </div>
            </form>
        </Form>
    </div>
}